import { useState } from 'react';
import dayjs from "dayjs/dayjs.min"
import { menuItem } from '../types';


const DateRangeSelectorModel = () => {

  const GSC_OFFSET_START_DAYS = 1;
  const GSC_OFFSET_END_DAYS = 2;

  const dateMenuItems = [
    {value: 7, unit: 'day'},
    {value: 28, unit: 'day'},
    {value: 3, unit: 'month'},
    {value: 6, unit: 'month'},
    {value: 12, unit: 'month'},
    {value: 16, unit: 'month'}
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [tempSelectedOption, setTempSelectedOption] = useState<menuItem>(dateMenuItems[1]);
  const [selectedOption, setSelectedOption] = useState<menuItem>(tempSelectedOption)

  const [tempCustomStartDate, setTempCustomStartDate] = useState<dayjs.Dayjs>()
  const [customStartDate, setCustomStartDate] = useState<dayjs.Dayjs>(dayjs().subtract(dateMenuItems[1].value+1, dateMenuItems[1].unit))

  const [tempCustomEndDate, setTempCustomEndDate] = useState<dayjs.Dayjs>()
  const [customEndDate, setCustomEndDate] = useState<dayjs.Dayjs>(dayjs().subtract(2, "day"))

  return {
    GSC_OFFSET_START_DAYS, GSC_OFFSET_END_DAYS,
    anchorEl, setAnchorEl,
    open,
    dateMenuItems,
    tempSelectedOption, setTempSelectedOption,
    selectedOption, setSelectedOption,
    tempCustomStartDate, setTempCustomStartDate, customStartDate, setCustomStartDate,
    tempCustomEndDate, setTempCustomEndDate, customEndDate, setCustomEndDate
  }
}

export default DateRangeSelectorModel