import './styles.css';
import React from 'react';

import { classNames } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import { styled } from '@mui/material/styles';
import { Box, Menu as MenuMui, Button, MenuProps, Typography, ButtonGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props: MenuProps) => (
  <MenuMui
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    MenuListProps={{
      disabledItemsFocusable: true,
      autoFocus: false
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    }
  }
}));

interface Props extends React.PropsWithChildren {
  open: boolean;
  anchorEl: any;
  label?: string;
  isSelected?: boolean;
  defaultValue?: string;
  onClose: () => void;
  onClear: () => void;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Menu: React.FC<Props> = ({
  open,
  label,
  children,
  anchorEl,
  isSelected,
  defaultValue,
  onClose,
  onClear,
  onClick
}) => {
  const { mode } = useColorModeContext();

  return (
    <Box className="mui-selector relative flex-1" sx={{ minWidth: 120 }}>
      {label && <Typography>{label}</Typography>}
      <ButtonGroup
        disableElevation
        variant="contained"
        className="w-full"
        sx={{ boxShadow: 'none', border: 'none' }}>
        <Button
          className={classNames('btn-selector', mode, isSelected ? 'has-selected' : '')}
          disableElevation
          onClick={onClick}
          variant="contained"
          aria-haspopup="true"
          id="demo-customized-button"
          aria-expanded={open && !isSelected ? 'true' : undefined}
          aria-controls={open && !isSelected ? 'demo-customized-menu' : undefined}
          endIcon={isSelected ? undefined : <KeyboardArrowDownIcon />}>
          {defaultValue}
        </Button>
        {isSelected && (
          <Button className="btn-clear" onClick={onClear}>
            <CloseIcon fontSize="small" color="inherit" />
          </Button>
        )}
      </ButtonGroup>
      <StyledMenu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}>
        {children}
      </StyledMenu>
    </Box>
  );
};

export default Menu;
