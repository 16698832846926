import React from 'react';
import OverviewRedirectLink from '../OverviewRedirectLink/KeywordRedirectLink';

interface Props {
  url: string;
  domain: string;
}

const SerpDomain: React.FC<Props> = ({ code, url, domain }) => {
  return (
    <OverviewRedirectLink location={code} domain={domain} url={url} />
  );
};

export default SerpDomain;
