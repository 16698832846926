import './styles.css';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsType } from '../../types/common.type';
import { classNames, generateIcon } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import {
  Box,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  Typography,
  FormControl,
  ListItemText,
  ListItemIcon
} from '@mui/material';

interface Props extends React.PropsWithChildren {
  label?: string;
  selected: string[];
  defaultValue?: string;
  suggestion: OptionsType[];
  onChange: (selected: any) => void;
}

const ModeSelectorWithCheck: React.FC<Props> = ({
  label,
  selected,
  onChange,
  suggestion,
  defaultValue
}) => {
  const { t } = useTranslation();
  const { mode } = useColorModeContext();

  const isAnySelected = selected.length > 0;

  return (
    <Box className="mui-selector flex-1 relative" sx={{ minWidth: 120 }}>
      {label && <Typography>{label}</Typography>}
      <FormControl fullWidth size="small" sx={{ borderRadius: 2, opacity: 1 }}>
        {selected.length === 0 && defaultValue && (
          <InputLabel
            disableAnimation
            shrink={false}
            focused={false}
            size="small"
            className={classNames('default-value', mode)}
            id="mutiple-select-label">
            {defaultValue}
          </InputLabel>
        )}
        <Select
          multiple
          size="small"
          value={selected}
          label={defaultValue}
          onChange={onChange}
          labelId="mutiple-select-label"
          renderValue={(selected) => selected.join(', ')}
          sx={{ borderRadius: 2, bgcolor: 'transparent' }}
          className={classNames(mode, isAnySelected ? 'has-selected checked' : '')}
          IconComponent={() =>
            generateIcon('KeyboardArrowDown', { fontSize: 'small', sx: { mr: 1 } })
          }>
          {suggestion.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option.label as any) > -1} />
              </ListItemIcon>
              <ListItemText primary={t(`intent.${option.label}`)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ModeSelectorWithCheck;
