import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import TableComponent from '../TableComponent';

const extractFilename = (url: string): string => (url ? url.substring(url.lastIndexOf('/') + 1) : '');

const formatDateString = (dateString: string): string => {
    if (!dateString) {
        return '';
    }
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

const unifySitemaps = (sitemaps: Array<any>): Array<any> =>
  sitemaps.map((sitemap, index) => ({
      ...sitemap,
      id: index,
      path: extractFilename(sitemap.path),
      contents: sitemap.contents || [],
  }));

const getURLsCount = (contents: Array<any>): number => {
    return contents.reduce((total, content) => total + content.submitted, 0);
};

const getTooltipText = (row: any): string => {
    if (row.errors) {
        return 'El Sitemap contiene errores, revísalo en Search Console';
    } else if (row.warnings) {
        return 'El Sitemap contiene advertencias, revísalo en Search Console';
    } else {
        return 'Correcto';
    }
};

const getIcon = (row: any) => {
    if (row.errors) {
        return <ErrorIcon sx={{ color: 'red', mr: 1 }} />;
    } else if (row.warnings) {
        return <WarningIcon sx={{ color: 'orange', mr: 1 }} />;
    } else {
        return <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />;
    }
};

const SitemapsComponent = ({ sitemaps }: { sitemaps: Array<any> }) => {
    const columns = [
        {
            field: 'path',
            headerName: 'Sitemap',
            flex: 1,
            minWidth: 300,
            renderCell: (params: any) => (
              <Box display="flex" alignItems="center">
                  <Tooltip title={getTooltipText(params.row)} placement="top">
                      {getIcon(params.row)}
                  </Tooltip>
                  <Typography variant="body1">{params.value}</Typography>
              </Box>
            ),
        },
        {
            field: 'urls',
            headerName: 'URLs',
            flex: 1,
            minWidth: 100,
            renderCell: (params: any) => <Typography variant="body1">{params.value}</Typography>,
        },
        {
            field: 'date',
            headerName: 'Fecha',
            flex: 1,
            minWidth: 100,
            renderCell: (params: any) => (
              <Tooltip title="Fecha: Última comprobación" placement="top">
                  <Typography variant="body2">{params.value}</Typography>
              </Tooltip>
            ),
        },
        {
            field: 'web',
            headerName: 'Web',
            flex: 1,
            minWidth: 100,
            renderCell: (params: any) => (params.row.contents.find((content: any) => content.type === 'web') || {}).submitted || 0,
        },
        {
            field: 'image',
            headerName: 'Imagen',
            flex: 1,
            minWidth: 100,
            renderCell: (params: any) => (params.row.contents.find((content: any) => content.type === 'image') || {}).submitted || 0,
        },
        {
            field: 'warnings',
            headerName: 'Advertencias/Errores',
            flex: 1,
            minWidth: 100,
            renderCell: (params: any) => (
              params.row.errors || params.row.warnings ? (
                <Tooltip title={getTooltipText(params.row)} placement="top">
                    <Typography variant="body2" sx={{ color: params.row.errors ? 'red' : 'orange' }}>
                        {params.row.errors || params.row.warnings}
                    </Typography>
                </Tooltip>
              ) : null
            ),
        },
    ];

    const unifiedSitemaps = unifySitemaps(sitemaps).map((sitemap: any) => ({
        ...sitemap,
        urls: getURLsCount(sitemap.contents),
        date: formatDateString(sitemap.lastDownloaded),
    }));

    return (
      <Box>
          <TableComponent rows={unifiedSitemaps} columns={columns} hideFooter={true}
                          paginationMode="client"
                          sortingMode="client" />
      </Box>
    );
};

export default SitemapsComponent;