import './Sidebar.scss';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ROUTES } from '../../utils/Constants';
import { useUsers } from '../../hooks/useUsers';
import { classNames, generateIcon } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import { List, Collapse, ListItem, ListItemText } from '@mui/material';
import { SubRoute } from './SubRouteAccount';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';
import React from 'react';

export function SidebarAccount() {
  const { t } = useTranslation();
  const location = useLocation();
  const { open, mode } = useColorModeContext();

  const { fetchRedirectStripe } = useUsers();

  const handleRedirectStripe = async () => await fetchRedirectStripe();

  const exactRoute = (path: string) => location.pathname.includes(path);

  return (
    <div className={classNames('sidebar account', open ? '' : 'sidebar-closed', mode)}>
      <List>
        <Collapse in sx={{ pt: 2 }}>
          <List component="div" disablePadding>
            <ListItem
              button
              component="a"
              href={ROUTES.HOME}>
              <KeyboardBackspaceIcon/><ListItemText primary={t('go_back')} />
            </ListItem>
            
            <SubRoute
              path={ROUTES.ACCOUNT_CONNECTIONS}
              subRouteName={t('routes.connections')}
              selected={exactRoute(ROUTES.ACCOUNT_CONNECTIONS)}
            />
            <SubRoute
              path={ROUTES.ACCOUNT_BILLING}
              subRouteName={t('routes.billings')}
              selected={exactRoute(ROUTES.ACCOUNT_BILLING)}
            />

            <ListItem
              button
              component={Link}
              onClick={handleRedirectStripe}
              sx={{ borderRadius: 1, paddingBlock: 0, pl: 3.5 }}
            >
              <ListItemText primary={t('routes.subscription')} />
            </ListItem>

            <SubRoute
              path={ROUTES.ACCOUNT_SUPPORT}
              subRouteName={t('routes.support')}
              selected={exactRoute(ROUTES.ACCOUNT_SUPPORT)}
            />
          </List>
        </Collapse>
      </List>
    </div>
  );
}