interface Props {
  common: string[];
  missing: string[];
  unique: string[];
  rowCount: number;
}

class ContentGapDto {
  common: string[];
  missing: string[];
  unique: string[];
  rowCount: number;


  constructor(data: Props) {
    this.common = data.common;
    this.missing = data.missing;
    this.unique = data.unique;
    this.rowCount = data.rowCount;
  }
}

export default ContentGapDto;
