import { useState } from "react";
import { useTranslation } from 'react-i18next';

import { getBaseUrl } from '../../../utils/Utils';
import { SERVICE_KEYWORDS_SUPPORT } from '../../../services/Endpoint';
import { useAccessToken } from '../../../hooks/useAccessToken';
import Swal from 'sweetalert2';
import { SweetAlertIcon } from '../../../utils/Constants';
import { keywordsService } from '../../../services/KeywordService';
import i18n from '../../../i18n';

const supportController = () => {
  const { t } = useTranslation();
  const token = useAccessToken();

  const [description, setDescription] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false)

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    // Append new files to the existing ones
    setImages((prevImages) => [...prevImages, ...files]);

    // Append new previews to the existing previews
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleImageRemove = (index) => {
    // Remove the image and its preview based on the index
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    const formData = new FormData();
    if (images && images.length > 0) {
      images.forEach((file) => {
        formData.append('img', file);
      });
    }
    formData.append('description', description);

    try {
      const response = await keywordsService.support({ body: formData, accessToken: token });
      if(response===200){
        Swal.fire({
          title: t('support.thanks'),
          icon: SweetAlertIcon.SUCCESS,
          text: t('support.thanks.text')
        });
      } else {
        Swal.fire({
          title: 'Oppss...',
          icon: SweetAlertIcon.ERROR,
          text: t('error_boundary')
        });
      }
    } catch (error) {
      console.error(error);
    }

    setDescription('')
    setImages([])
    setImagePreviews([])
    setLoading(false)

  };

  return {
    description,
    setDescription,
    images,
    setImages,
    imagePreviews,
    setImagePreviews,

    loading,
    setLoading,

    handleImageRemove,
    handleSubmit,
    handleImageChange
  };

}

export default supportController;