import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, IconButton, Link, TextField, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import PaperItem from '../../../components/Essential/PaperItem';
import TelegramIcon from '@mui/icons-material/Telegram';
import Swal from 'sweetalert2';

import supportController from './supportController';

const Support = () => {
  const { t } = useTranslation();

  const {
    description, setDescription, loading, setLoading, imagePreviews, setImagePreviews,
    handleImageRemove, handleSubmit, handleImageChange
  } = supportController();


  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>{t('routes.support')}</Typography>

          <Grid container spacing={4} sx={{ p: 3 }}>

            {/* Left Column */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('message')}
                multiline
                rows={16}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                sx={{ mb: 2 }}
                focused
                inputProps={{ maxLength: 2048 }}
                placeholder={t('messagePlaceholder')}
              />
              <Button type="submit" variant="contained" fullWidth startIcon={<SendIcon />}
                      disabled={loading===true || description.trim().length < 40} onClick={handleSubmit}>{t('submit')}</Button>
            </Grid>

            {/* Right Column */}
            <Grid item xs={6}>
              <Button variant="contained" component="label" sx={{ mb: 2, backgroundColor: '#515254' }}
                      startIcon={<CloudUploadIcon />} disabled={imagePreviews.length>8}>
                {t('uploadImage')}
                <input type="file" hidden multiple accept="image/*" onChange={handleImageChange} />
              </Button>

              {/* Image Preview Container with Fixed Height */}
              <Box
                sx={{
                  height: 400, // Fixed height for the container
                  overflowY: 'auto', // Enable scrolling when images overflow
                  border: '1px solid #ccc',
                  padding: 1,
                  borderRadius: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                {imagePreviews.length > 0 ? (
                  <Grid container spacing={2}>
                    {imagePreviews.map((preview, index) => (
                      <Grid item key={index} sx={{ position: 'relative' }}>
                        <img
                          src={preview}
                          alt={`Preview ${index + 1}`}
                          style={{
                            width: 200,
                            height: 200,
                            objectFit: 'cover',
                            borderRadius: 4
                          }}
                        />
                        {/* Cross button to remove the image */}
                        <IconButton
                          size="small"
                          onClick={() => handleImageRemove(index)}
                          sx={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            backgroundColor: 'rgba(255, 255, 255, 0.7)'
                          }}>x</IconButton>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="body2" color="textSecondary">{t('uploadNoImages')}</Typography>
                )}
              </Box>
            </Grid>


          </Grid>

          <Grid container spacing={4} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <Typography color="textSecondary">{t('contact.info')}</Typography>
              <Typography><Link href="https://rankerfy.com/#contacto" target="_blank"
                                rel="noopener noreferrer">https://rankerfy.com</Link></Typography>
              <Typography>
                <Link href="https://web.telegram.org/k/#-2076142060" target="_blank" rel="noopener noreferrer"
                      sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                  <TelegramIcon sx={{ marginRight: 1 }} />
                  {t('contact.telegram')}
                </Link>
              </Typography>
            </Grid>
          </Grid>

        </PaperItem>
      </Grid>
    </Grid>

  );

};

export default Support;