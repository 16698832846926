import './styles.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../../utils/Constants';
import { classNames, formatNumber } from '../../../utils/Utils';
import { DomainOverview } from '../../../types/domainOverview.type';
import { useColorModeContext } from '../../../context/ColorModeContext';

import { Grid, Typography } from '@mui/material';
import WithSkeleton from '../../WithSkeleton/WithSkeleton';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';
import Cost from '../../Cost';

interface Props {
  domain?: string;
  location?: number;
  data: DomainOverview | undefined;
}

const DomainMetadata: React.FC<Props> = ({ data, domain, location }) => {
  const { t } = useTranslation();
  const { mode } = useColorModeContext();
  const linkToDomainKeyword = `${ROUTES.DOMAIN_KEYWORDS}?q=${domain}&db=${location}`;

  return (
    <Grid container className={classNames('keyowrd info', mode)}>
      <Grid item xs={12} md={6} lg={3} className="info-items">
        <CustomTooltip content={t('tooltips.domains.overview_traffic')}>
          <Typography className="info-title" variant="h2" component="h2">
            {t('traffic')}
          </Typography>
        </CustomTooltip>
        <div className="flex items-end gap-1">
          <Typography className="info_text-highlight" variant="h3">
            <WithSkeleton data={data?.traffic} width={140} height={50}>
              {formatNumber(data?.traffic as number)}
            </WithSkeleton>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className="info-items">
        <CustomTooltip content={t('tooltips.domains.overview_traffic_price')}>
          <Typography className="info-title" variant="h2" component="h2">
            {t('price_traffic')}
          </Typography>
        </CustomTooltip>
        <div className="flex items-end gap-1">
          <Typography className="info_text-highlight" variant="h3">
            <WithSkeleton data={data?.trafficCost} width={140} height={50}>
              <Cost value={data?.trafficCost} />
            </WithSkeleton>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className="info-items">
        <CustomTooltip content={t('tooltip.keywords')}>
          <Typography className="info-title" variant="h2" component="h2">
            {t('total_keywords')}
          </Typography>
        </CustomTooltip>
        <div className="flex items-end gap-1">
          <WithSkeleton data={data?.totalKeywords} width={100} height={50}>
            <Link className="disabled-hover" to={linkToDomainKeyword}>
              <Typography className="info_text-highlight" variant="h3">
                {formatNumber(data?.totalKeywords as number)}
              </Typography>
            </Link>
          </WithSkeleton>
          <WithSkeleton data={data?.totalKeywords} width={30} height={50}>
            <Link className="disabled-hover" to={linkToDomainKeyword} />
          </WithSkeleton>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className="info-items">
        <CustomTooltip content={t('tooltip.urls')}>
          <Typography className="info-title" variant="h2" component="h2">
            {t('urls')}
          </Typography>
        </CustomTooltip>
        <div className="flex items-end gap-1 w-full">
          <Typography className="info_text-highlight w-full" variant="h3">
            <WithSkeleton data={data?.urls} width="100%" height={50}>
              {formatNumber(data?.urls as number)}
            </WithSkeleton>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default DomainMetadata;
