import { FC, useEffect, useState } from 'react';
import styles from './CustomTooltip.module.scss';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { CustomTooltipProps } from '../../types';
import { formatDate, formatNumber, formatStringDate } from '../../../../utils/Utils';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeDateParts = (dateString) => {
  return dateString.split(' ').map(capitalizeFirstLetter).join(' ');
};

const extractDomain = (url) => {
  return url.replace(/^(https?:\/\/)?(www\.)?/, '');
};

const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label, isCustomDomain, blurTitle }) => {
  const isMobile = useIsMobile();

  if (active && payload && payload.length && label) {
    let dataFormatted;

    if (isCustomDomain) {
      dataFormatted = payload.map((entry: any) => ({
        icon: null,
        favicon: isMobile ? `https://www.google.com/s2/favicons?sz=64&domain_url=${extractDomain(entry.name)}` : null,
        value: `${formatNumber(entry.value)}`,
        name: entry.name,
        color: entry.color
      }));
    } else {
      dataFormatted = payload.reduce((acc: any[], entry: any) => {
        if (entry.dataKey === 'impressions') {
          acc.push({
            icon: <RemoveRedEyeIcon style={{ color: '#5e35b1' }} />,
            value: formatNumber(entry.payload.impressions)
          });
        } else if (entry.dataKey === 'clicks') {
          acc.push({
            icon: <AdsClickIcon style={{ color: '#4285f4' }} />,
            value: formatNumber(entry.payload.clicks)
          });
        } else if (entry.dataKey === 'ctr') {
          const ctrPercentage = entry.payload.ctr * 100;
          acc.push({ icon: <PercentIcon style={{ color: '#00897b' }} />, value: `${formatNumber(ctrPercentage)}%` });
        } else if (entry.dataKey === 'position') {
          acc.push({
            icon: <TrendingUpIcon style={{ color: '#e8710a' }} />,
            value: formatNumber(entry.payload.position, 0)
          });
        }
        return acc;
      }, []);
    }

    return (
      <div className={`${styles.customTooltip} ${isMobile ? styles.mobile : ''}`}>
        <p className="label">{formatDate(label)}</p>
        <div className={styles.tooltipGrid}>
          {dataFormatted.map((data, index) => (
            <div key={index} className={styles.tooltipItem}>
              <span className={styles.tooltipColor} style={{ backgroundColor: data.color }}></span>
              {isMobile && isCustomDomain && data.favicon ? (
                <img src={data.favicon} alt="favicon" className={styles.tooltipFavicon} />
              ) : (
                data.icon && <span className={styles.tooltipIcon}>{data.icon}</span>
              )}
              {isCustomDomain && blurTitle && !isMobile ? (
                <>
                  <span className={styles.tooltipBlurTitle}>{data.name}</span>: <span>{data.value}</span>
                </>
              ) : (
                <span>{!isMobile && isCustomDomain ? `${data.name}: ` : ''}{data.value}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;