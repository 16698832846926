import { FC } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalDateRangeSelectorProps } from '../types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangeSelectorModel from './DateRangeSelectorModel';
import DateRangeSelectorController from './DateRangeSelectorController';
import dayjs from 'dayjs/dayjs.min';
import { FORMAT_DATE_DAYJS, MENU_ITEM_CUSTOM } from '../../../utils/Constants';
import { formatDate } from '../../../utils/Utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const DateRangeSelectorView: FC<LocalDateRangeSelectorProps> = ({ onChange }) => {
  const { t } = useTranslation();

  const model = DateRangeSelectorModel();
  const controller = DateRangeSelectorController(model, onChange);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>

        <Button variant="outlined" onClick={controller.handleOpenDatePicker} sx={{ textTransform: 'none' }}
                className={'btn-selector'}>
          {
            model.selectedOption.unit === MENU_ITEM_CUSTOM ? `${formatDate(model.customStartDate)} - ${formatDate(model.customEndDate)}`
                : `${t('optionDate.last')} ${model.selectedOption.value} ${t(`optionDate.${model.selectedOption.unit}`)}`
          }
          {/* Dynamic arrow icon */}
          {model.open ? (
            <KeyboardArrowUpIcon sx={{ marginLeft: 1 }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ marginLeft: 1 }} />
          )}
        </Button>

        <Menu anchorEl={model.anchorEl} open={model.open} onClose={controller.handleCloseDatePicker}>

          {/* Predefined Options */}
          {model.dateMenuItems.map((option) => (
            <MenuItem
              key={option.value}
              selected={model.tempSelectedOption.value === option.value}
              onClick={() => controller.handleOptionSelect(option)}
              onClose={controller.handleCloseDatePicker}
            >
              <Typography>{`${t('optionDate.last')} ${option.value} ${t(`optionDate.${option.unit}`)}`}</Typography>
            </MenuItem>
          ))}

          {/* Custom Date Range Option */}
          <MenuItem selected={model.tempSelectedOption.unit === MENU_ITEM_CUSTOM}
                    onClick={controller.handleOptionSelectCustom}>
            <Typography>{t('optionDate.custom')}</Typography>
          </MenuItem>

          <Box sx={{ px: 2, py: 2, display: 'flex', gap: 2 }}>
            <DatePicker
              label={t('start')}
              value={model.customStartDate}
              maxDate={dayjs().subtract(model.GSC_OFFSET_END_DAYS, 'days')}
              format={FORMAT_DATE_DAYJS}
              onChange={(date) => model.setTempCustomStartDate(date)}
              slotProps={{
                textField: {
                  sx: { width: '160px' },
                  InputLabelProps: { shrink: true },
                  onFocus: (event) => controller.handleOptionSelectCustom()
                },
                openPickerButton: {
                  onFocus: (event) => controller.handleOptionSelectCustom()
                }
              }}
            />
            <DatePicker
              label={t('end')}
              value={model.customEndDate}
              maxDate={dayjs().subtract(model.GSC_OFFSET_END_DAYS, 'days')}
              format={FORMAT_DATE_DAYJS}
              onChange={(date) => model.setTempCustomEndDate(date)}
              slotProps={{
                textField: {
                  sx: { width: '160px' },
                  InputLabelProps: { shrink: true },
                  onFocus: (event) => controller.handleOptionSelectCustom()
                },
                openPickerButton: {
                  onFocus: (event) => controller.handleOptionSelectCustom()
                }
              }}
            />
          </Box>

          <Box sx={{ px: 2, py: 1, display: 'flex', gap: 2 }}>
            <Button
              onClick={controller.handleCloseDatePicker}
              sx={{ flex: 1 }}
              variant="outlined">
              {t('cancel')}
            </Button>
            <Button
              onClick={controller.handleApply}
              sx={{ flex: 1 }}
              variant="contained" color="primary">
              {t('apply')}
            </Button>
          </Box>
        </Menu>

      </Box>
    </LocalizationProvider>

  );

};

export default DateRangeSelectorView;