import React from 'react';
import { Typography } from '@mui/material';
import { keywordDifficultyValuesProgress } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';
import './styles.css';

import {
  KEYWORD_DIFFICULTY_CLASS_DIFFICULT,
  KEYWORD_DIFFICULTY_CLASS_EASY,
  KEYWORD_DIFFICULTY_CLASS_HARD,
  KEYWORD_DIFFICULTY_CLASS_POSSIBLE,
  KEYWORD_DIFFICULTY_CLASS_VERY_EASY,
  KEYWORD_DIFFICULTY_CLASS_VERY_HARD
} from '../../utils/Constants';

interface ProgressBarSemiCircleProps {
  value: number; // The value between 0 and 100,
  htmlClass?: string;
}

const DifficultyProgress: React.FC<ProgressBarSemiCircleProps> = ({ value }) => {
  const radius = 50; // Radius of the circle
  const circumference = Math.PI * radius; // Circumference for half-circle
  const offset = circumference - (value / 100) * circumference; // Dynamic stroke offset based on value
  const { mode } = useColorModeContext();
  const { htmlClass } = keywordDifficultyValuesProgress(value, mode);

  return (
    <div className="difficulty">
      {/* SVG for the half circle */}
      <svg viewBox="0 0 120 60">
        {/* Background half-circle with rounded ends */}
        <defs>
          <linearGradient id={KEYWORD_DIFFICULTY_CLASS_VERY_EASY} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#59ddaa' }} />
            <stop offset="100%" style={{ stopColor: '#009f81' }} />
          </linearGradient>
          <linearGradient id={KEYWORD_DIFFICULTY_CLASS_EASY} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#009f81' }} />
            <stop offset="100%" style={{ stopColor: '#fdc23c' }} />
          </linearGradient>
          <linearGradient id={KEYWORD_DIFFICULTY_CLASS_POSSIBLE} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#fdc23c' }} />
            <stop offset="100%" style={{ stopColor: '#ff8c43' }} />
          </linearGradient>
          <linearGradient id={KEYWORD_DIFFICULTY_CLASS_DIFFICULT} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#ff8c43' }} />
            <stop offset="100%" style={{ stopColor: '#ff4953' }} />
          </linearGradient>
          <linearGradient id={KEYWORD_DIFFICULTY_CLASS_HARD} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#ff4953' }} />
            <stop offset="100%" style={{ stopColor: '#d1002f' }} />
          </linearGradient>
          <linearGradient id={KEYWORD_DIFFICULTY_CLASS_VERY_HARD} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#d1002f' }} />
            <stop offset="100%" style={{ stopColor: 'black' }} />
          </linearGradient>
        </defs>
        <path
          d="M 10,50 A 50,50 0 0,1 110,50"
          fill="none"
          stroke="#e0e0e0"
          strokeWidth="12"
          strokeLinecap="round" // Ensures the ends are rounded
        />

        {/* Foreground progress half-circle */}
        <path
          d="M 10,50 A 50,50 0 0,1 110,50"
          fill="none"
          stroke={`url(#${htmlClass})`}
          strokeWidth="12"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round" // Optional: Rounds progress ends if required
        />
      </svg>

      {/* Display value in the center */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Adjust for perfect centering
          textAlign: 'center'
        }}
      >
        <Typography className={`${htmlClass} value`}>{value}</Typography>
      </div>
    </div>
  );
};

export default DifficultyProgress;
