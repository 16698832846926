import { useState, useCallback } from 'react';
import useQueriesParams from './useQueriesParams';
import { useAccessToken } from './useAccessToken';
import { keywordsService } from '../services/KeywordService';
import { MOCK_DATA_KEYWORDS_SERVICES } from '../utils/Constants';
import {
  Keyword,
  KeywordResponse,
  SerpInfoContent,
  KeywordOverview,
  KeywordsNicheRequest,
  KeywordMetricsResponse,
} from '../types';
import { useKeywordsCache } from '../context/KeywordsCacheContext';

interface KeywordsDataParams {
  keywords: string | string[];
  location: string | number;
  limit?: number;
}

type KeywordsNicheDataParams = Omit<KeywordsNicheRequest, 'accessToken'>;

const SERP_INITIALIZE = { items: { serp_info: { serp: [] } as SerpInfoContent } };

export const useKeywordsData = (cacheKey: string) => {
  const { getCache, updateCache } = useKeywordsCache();
  const cachedData = getCache(cacheKey);

  const [keywords, setKeywords] = useState<KeywordResponse>(cachedData.keywords || MOCK_DATA_KEYWORDS_SERVICES);
  const [loadingKeywords, setLoadingKeywords] = useState(false);

  const [keywordOverviewItems, setKeywordOverviewItems] = useState<KeywordOverview>(
    cachedData.keywordOverviewItems || SERP_INITIALIZE
  );
  const [loadingKeywordOverviewItems, setLoadingKeywordOverviewItems] = useState(false);

  const [nicheIdeas, setNicheIdeas] = useState<KeywordMetricsResponse>(cachedData.nicheIdeas || MOCK_DATA_KEYWORDS_SERVICES);
  const [loadingNicheIdeas, setLoadingNicheIdeas] = useState(false);

  const [keywordsBulk, setKeywordsBulk] = useState<KeywordMetricsResponse>(cachedData.keywordsBulk || MOCK_DATA_KEYWORDS_SERVICES);
  const [loadingKeywordsBulk, setLoadingKeywordsBulk] = useState(false);

  const [keywordSearched, setKeywordSearched] = useState<Keyword<any> | undefined>(cachedData.keywordSearched);

  const [error, setError] = useState<any>(null);

  const token = useAccessToken();
  const validateQueriesParams = useQueriesParams();

  const handleError = (e: any) => {
    setError(e);
    setKeywords(MOCK_DATA_KEYWORDS_SERVICES);
    setKeywordSearched(undefined);
  };

  const fetchKeywords = useCallback(
    async (filterParams: KeywordsDataParams) => {
      setLoadingKeywords(true);
      try {
        const data = await keywordsService.getKeywords({ ...filterParams, limit: filterParams.limit ?? 10, location: filterParams.location, accessToken: token });
        setKeywords(data.keywords);
        updateCache(cacheKey, { keywords: data.keywords });
      } catch (e) {
        handleError(e);
      } finally {
        setLoadingKeywords(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchKeywordsBulk = useCallback(
    async (filterParams: KeywordsDataParams) => {
      setLoadingKeywordsBulk(true);
      try {
        const data = await keywordsService.getKeywordsBulk({ ...filterParams, limit: filterParams.limit ?? 10, location: filterParams.location, accessToken: token });
        setKeywordsBulk(data.keywords);
        updateCache(cacheKey, { keywordsBulk: data.keywords });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingKeywordsBulk(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchKeywordOverview = useCallback(
    async (filterParams: KeywordsDataParams) => {
      setLoadingKeywordOverviewItems(true);
      try {
        const data = await keywordsService.getKeywordOverview({ keywords: filterParams.keywords as string, location: filterParams.location, accessToken: token });
        setKeywordSearched(data.keywords.items);
        setKeywordOverviewItems(data.keywords);
        updateCache(cacheKey, { keywordSearched: data.keywords.items, keywordOverviewItems: data.keywords });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingKeywordOverviewItems(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchNicheIdeas = useCallback(
    async (filterParams: KeywordsNicheDataParams): Promise<KeywordMetricsResponse> => {
      setLoadingNicheIdeas(true);
      try {
        const data = await keywordsService.getNicheIdeas({ ...filterParams, accessToken: token });
        setNicheIdeas(data.keywords);
        updateCache(cacheKey, { nicheIdeas: data.keywords });
        return data.keywords;
      } catch (e) {
        setError(e);
        throw e;
      } finally {
        setLoadingNicheIdeas(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchKeywordsWithQueries = useCallback(async () => {
    await validateQueriesParams(async (data) => {
      const request = { keywords: data.q, location: data.db };
      await Promise.all([fetchKeywordOverview(request), fetchKeywords(request)]);
    });
  }, [validateQueriesParams, fetchKeywordOverview, fetchKeywords]);

  const fetchKeywordsIdeasWithQueries = useCallback(async () => {
    await validateQueriesParams(async (data) => {
      const request = { keywords: data.q, location: data.db };
      await fetchKeywords(request);
    });
  }, [validateQueriesParams, fetchKeywords]);

  return {
    keywords,
    loadingKeywords,
    keywordOverviewItems,
    loadingKeywordOverviewItems,
    nicheIdeas,
    loadingNicheIdeas,
    keywordsBulk,
    loadingKeywordsBulk,
    keywordSearched,
    error,
    fetchKeywords,
    fetchKeywordOverview,
    fetchNicheIdeas,
    fetchKeywordsBulk,
    fetchKeywordsWithQueries,
    fetchKeywordsIdeasWithQueries,
  };
};