import projectDetailModel from './ProjectDetailModel';
import projectDetailController from './ProjectDetailController';
import { Box, Divider, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ProjectsPerformanceFilterView from '../performance/ProjectsPerformanceFilterView';
import SearchConsoleChart from '../../../components/Charts/SearchConsoleChart';
import { cleanUrl, formatRows } from '../../../utils/format';
import SitemapsComponent from '../../../components/SitemapsComponent/SitemapsComponent';
import PageSpeedInsights from '../../../components/PageSpeedInsights/PageSpeedInsights';
import TableComponent from '../../../components/TableComponent';
import { calculateDisplayedPercentages } from '../../../utils/calculations';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';

export const ProjectDetail = () => {

  const model = projectDetailModel();
  const controller = projectDetailController(model);

  return (
    <>
      <Paper elevation={3} sx={{ padding: 2 }}>

        <Grid container spacing={3}>
          <Grid size={12}>
            <Box mb={0} display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
              <ProjectsPerformanceFilterView model={model} controller={controller} />
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid size={12}>
            <SearchConsoleChart
              project={{ id: model.project, url: cleanUrl(model.projectUrl), property: model.project }}
              isGeneralView={false}
              showChartHeader={false}
              searchData={model.chartData}
              previousData={model.projectInfo?.previousData?.dates || []}
              showDots={false}
              showLegend={false}
              blurTitle={model.blur}
              showSummaryCards={true}
              showCustomDots={model.showCustomDots}
              metrics={model.globalMetrics}
              setMetrics={model.setGlobalMetrics}
              numberOfColumns={1}
              loading={model.loading || model.projectLoading}
            />
          </Grid>

          <Grid size={12}>
            <PageSpeedInsights url={model.project} />
          </Grid>

          <Grid size={12}>
            {model.projectInfo?.currentData?.sitemaps && <SitemapsComponent sitemaps={model.projectInfo.currentData.sitemaps} />}
          </Grid>

          {model.projectInfo?.currentData?.queryList?.queryList?.length > 0 && (
            <Grid size={12} className={'projectDetailTable'} >
              <TableComponent
                rows={controller.formattedRows(model.projectInfo.currentData.queryList[model.filterMode], 'queryList.queryList')}
                columns={controller.filteredColumns(model.COLUMNS_QUERIES)}
                loading={model.loading || model.projectLoading}
                blurTitle={model.blur}
                overlayPercentages={calculateDisplayedPercentages(
                  model.projectInfo.currentData.queryList.queryList,
                  model.projectInfo.currentData.maxMinClicks?.queryList || {},
                  model.globalMetrics
                )}
                paginationMode="client"
                sortingMode="client"
                slots={{
                  toolbar: () => (
                    <div className="py-2">
                      <ButtonGroup
                        current={model.filterMode}
                        onChange={(value) => model.setFilterMode(value)}
                        suggestions={model.FILTER_KEYWORD}
                      />
                    </div>
                  )
                }}
              />
            </Grid>
          )}

          {model.projectInfo?.currentData?.pagesList?.length > 0 && (
            <Grid size={12} className={'projectDetailTable'} >
              <TableComponent
                rows={controller.formattedRows(model.projectInfo.currentData.pagesList, 'pagesList')}
                columns={controller.filteredColumns(model.COLUMNS_PAGES)}
                loading={model.loading || model.projectLoading}
                blurTitle={model.blur}
                overlayPercentages={calculateDisplayedPercentages(
                  model.projectInfo.currentData.pagesList,
                  model.projectInfo.currentData.maxMinClicks?.pagesList || {},
                  model.globalMetrics
                )}
                paginationMode="client"
                sortingMode="client"
              />
            </Grid>
          )}

          {model.projectInfo?.currentData?.countries?.length > 0 && (
            <Grid size={12} className={'projectDetailTable'} >
              <TableComponent
                rows={controller.formattedRows(model.projectInfo.currentData.countries, 'countries')}
                columns={controller.filteredColumns(model.COLUMNS_COUNTRIES)}
                loading={model.loading || model.projectLoading}
                overlayPercentages={calculateDisplayedPercentages(
                  model.projectInfo.currentData.countries,
                  model.projectInfo.currentData.maxMinClicks?.countries || {},
                  model.globalMetrics
                )}
                paginationMode="client"
                sortingMode="client"
              />
            </Grid>
          )}

          {model.projectInfo?.currentData?.devices?.length > 0 && (
            <Grid size={12} className={'projectDetailTable'} >
              <TableComponent
                rows={controller.formattedRows(model.projectInfo.currentData.devices, 'devices')}
                columns={controller.filteredColumns(model.COLUMNS_DEVICES)}
                loading={model.loading || model.projectLoading}
                overlayPercentages={calculateDisplayedPercentages(
                  model.projectInfo.currentData.devices,
                  model.projectInfo.currentData.maxMinClicks?.devices || {},
                  model.globalMetrics
                )}
                paginationMode="client"
                sortingMode="client"
              />
            </Grid>
          )}

          {model.projectInfo?.currentData?.searchAppearanceList?.length > 0 && (
            <Grid size={12} className={'projectDetailTable'} >
              <TableComponent
                rows={controller.formattedRows(model.projectInfo.currentData.searchAppearanceList, 'searchAppearanceList')}
                columns={controller.filteredColumns(model.COLUMNS_SEARCH_APPEARANCES)}
                loading={model.loading || model.projectLoading}
                overlayPercentages={calculateDisplayedPercentages(
                  model.projectInfo.currentData.searchAppearanceList,
                  model.projectInfo.currentData.maxMinClicks?.searchAppearanceList || {},
                  model.globalMetrics
                )}
                paginationMode="client"
                sortingMode="client"
              />
            </Grid>
          )}

        </Grid>

      </Paper>
    </>
  );
};
