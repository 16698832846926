import './Header.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  CircularProgress,
  ListItemIcon,
  Box,
  Typography as MuiTypography,
} from '@mui/material';
import { classNames, generateIcon } from '../../utils/Utils';
import { useUserContext } from '../../context/UserContext';
import { useColorModeContext } from '../../context/ColorModeContext';
import SettingsModal from './SettingsModal';
import rankerfyLogo from '../../assets/img/logo.png';
import rankerfyLogoWhite from '../../assets/img/logo-white.png';

const seoIcon = new URL('../../assets/img/logonormal.png', import.meta.url).href;
const seoIconWhite = new URL('../../assets/img/logoclaro.png', import.meta.url).href;

export const Header = ({ disableButtons }: { disableButtons?: boolean }) => {
  const [settingsModalOpen, setSettingsModalOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const { open, mode, onOpenSidebar } = useColorModeContext();
  const { loginWithRedirect, myUser, isLoading } = useUserContext();

  const toggleSidebar = () => onOpenSidebar(!open);
  const handleSettingsModalOpen = () => setSettingsModalOpen(true);
  const handleSettingsModalClose = () => setSettingsModalOpen(false);

  return (
    <div className={`app-bar ${mode === 'dark' ? 'dark' : ''}`} data-theme={mode}>
      <AppBar
        position="static"
        className={classNames('header', mode)}
        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <IconButton className="iconMenuSidebar" onClick={toggleSidebar}>
            {generateIcon('Menu', { className: 'iconMenuHamburguesa' })}
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <Link to={disableButtons ? '#' : '/'} style={{ textDecoration: 'none' }}>
              <img
                className="logo"
                alt={t('rankerfy_logo_title')}
                title={t('rankerfy_logo_title')}
                src={mode === 'dark' ? rankerfyLogoWhite : rankerfyLogo}
              />
            </Link>
          </Typography>
          {!disableButtons && (
            <a
              href="https://learn.rankerfy.com"
              className={`highlighted-button ${mode}`}
              style={{ textDecoration: 'none' }}
            >
              <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <ListItemIcon>
                  <img src={mode === 'dark' ? seoIconWhite : seoIcon} alt="Academia" className="sidebar-icon" />
                </ListItemIcon>
                <MuiTypography className="MuiListItemText-root" style={{ paddingLeft: '2px' }}>
                  Academia
                </MuiTypography>
              </Box>
            </a>
          )}
          {!myUser && (
            <IconButton title={t('login')} className="close-button" onClick={() => loginWithRedirect()}>
              {generateIcon('Login')}
            </IconButton>
          )}
          <IconButton onClick={handleSettingsModalOpen}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Avatar alt={myUser?.name} src={myUser?.picture} title={myUser?.name} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <SettingsModal open={settingsModalOpen} onClose={handleSettingsModalClose} />
    </div>
  );
};