import PaperItem from '../../../components/Essential/PaperItem';
import ProjectsPerformanceFilterView from './ProjectsPerformanceFilterView';
import SearchConsoleChart from '../../../components/Charts/SearchConsoleChart';
import ProjectsPerformanceModel from './ProjectsPerformanceModel';
import ProjectsPerformanceController from './ProjectsPerformanceController';
import { Box } from '@mui/material';
import ProjectsFavoriteView from '../favorite/ProjectsFavoriteView';

export const ProjectsPerformanceView = () => {

  const model = ProjectsPerformanceModel();
  const controller = ProjectsPerformanceController(model);

  return (
    <>
      <PaperItem sx={{ p: 3, mb: 2 }} >
        <Box mb={0} display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
          <ProjectsPerformanceFilterView model={model} controller={controller} />
        </Box>
      </PaperItem>

      <SearchConsoleChart
        project={{ id: 'General', url: 'General Overview', property: 'General Overview' }}
        isCustomDomain={true}
        searchData={model.customDomainData}
        showDots={false}
        showLegend={true}
        blurTitle={model.blur}
        showChartHeader={false}
        showSummaryCards={false}
        showCustomDots={model.showCustomDots}
        metrics={model.globalMetrics}
        numberOfColumns={model.columns}
        isFavorite={false}
        loading={controller.loadingPerformance}
        modelProject={model}
      />
      <ProjectsFavoriteView model={model} controller={controller} />
    </>
  );
};

export default ProjectsPerformanceView;