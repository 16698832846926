import { useTranslation } from 'react-i18next';

import { classNames } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';
import { Tooltip } from '@mui/material';

interface Props<Item = any> {
  current: Item;
  suggestions: Readonly<string[]>;
  onChange: (value: Item) => void;
}

const ButtonGroup: React.FC<Props> = ({ current, onChange, suggestions }) => {
  const { t } = useTranslation();
  const { mode } = useColorModeContext();
  return (
    <div className={classNames('btn-group-chip', mode)}>
      {suggestions.map((item, index) => (
        <Tooltip title={t(`tooltip.${item}`)} key={index} >
          <button
            key={item}
            onClick={() => onChange(item)}
            data-current={current === item}>
            {t(`button_${item}`)}
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

export default ButtonGroup;
