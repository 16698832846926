import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useExportData from '../../hooks/useExportData';
import { useDomainsData } from '../../hooks/useDomainsData';
import { useAccessToken } from '../../hooks/useAccessToken';
import useRefreshQueries from '../../hooks/useRefreshQueries';
import usePaginationModel from '../../hooks/usePaginationModel';
import { COLUMNS_DOMAIN_KEYWORDS, COLUMNS_DOMAIN_POSITIONS } from '../../utils/TableColumnsConstants';
import { KeywordsFiltersProvider, useKeywordsFiltersContext } from '../../context/KeywordsFilterContext';

import { Grid, Typography, Box } from '@mui/material';
import Search from '../../components/Search';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import ButtonExportExcel from '../../components/Buttons/ExportExcel';
import KeywordsFilters from '../../components/Filters/Keywords/KeywordsFilter';
import { DomainsCacheProvider } from '../../context/DomainsCacheContext';
import { LOCATION_CODE_ES } from '../../utils/Constants';
import { SearchCacheProvider, useSearchCache } from '../../context/SearchCacheContext';
import * as domain from 'node:domain';

const KEYWORDS_DOMAINS_CACHE_KEY = 'keywords_domains_search';

const ContainerPage = ({ cacheKey }) => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const { isLoading, fetchExportDomainKeywords } = useExportData();
  const { query, location, handleQuery, handleLocation } = useRefreshQueries(cacheKey);
  const { keywords, loadingKeywords, fetchDomainKeyword, fetchDomainKeywordWithQueries } = useDomainsData(cacheKey);
  const { sortModel, paginationModel, handlePaginationChange, handleSortModelChange } = usePaginationModel();
  const { selected, modeFilter, cpcOfSearches, volumeOfSearches, differenceOfSearches, handleSearchKeywords } = useKeywordsFiltersContext();
  
  const { getPagination, updatePagination, getSearchCache } = useSearchCache();

  const getPaginatedParams = useCallback((paginationModel) => ({
    from: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize
  }), []);

  const getsortParams = useCallback((sortModel) => ({
    field: sortModel[0].field,
    sort: sortModel[0].sort
  }), []);

  useEffect(() => {
    const savedPagination = getPagination(cacheKey);
    handlePaginationChange(savedPagination);
  }, [cacheKey, handlePaginationChange, getPagination]);

  const refreshData = useCallback(
    async (domain) => {
      const paginatedParams = getPaginatedParams(paginationModel);
      const data = { domain, location, ...paginatedParams, ...handleSearchKeywords() };
      await fetchDomainKeyword(data);
    },
    [location, paginationModel, sortModel, handleSearchKeywords, fetchDomainKeyword, getPaginatedParams, getsortParams]
  );

  const handleSearchBtn = async (search) => {
    handleQuery(search);
    if (search.length === 0) return;
    await refreshData(search);
  };

  const handleExportBtn = async () => {
    const cachedQuery = getSearchCache(cacheKey);
    if (cachedQuery.length === 0 && query.length === 0) return;

    const paginatedParams = getPaginatedParams(paginationModel);
    const data = {
      domain: cachedQuery || query,
      location,
      ...paginatedParams,
      ...handleSearchKeywords(),
      title: 'Posiciones'
    };

    await fetchExportDomainKeywords(data);
  };

  const handleSortChange = async (newSortModel) => {
    handleSortModelChange(newSortModel);

    const currentQuery = query.length > 0 ? query : getSearchCache(KEYWORDS_DOMAINS_CACHE_KEY);
    if (currentQuery) {
      const paginatedParams = getPaginatedParams(paginationModel)
      const sortParams = getsortParams(newSortModel)
      const data = { ...paginatedParams, ...sortParams, domain: currentQuery, location, ...handleSearchKeywords() };
      await fetchDomainKeyword(data);
    }
  }

  const handlePaginationModelChange = async (newPaginationModel) => {

    handlePaginationChange(newPaginationModel);
    updatePagination(cacheKey, newPaginationModel);

    const currentQuery = query.length > 0 ? query : getSearchCache(KEYWORDS_DOMAINS_CACHE_KEY);
    if (currentQuery) {
      const paginatedParams = getPaginatedParams(newPaginationModel);
      const sortParams = sortModel.length>0? getsortParams(sortModel) : [] ;
      const data = { ...paginatedParams, ...sortParams, domain: currentQuery, location, ...handleSearchKeywords() };
      await fetchDomainKeyword(data);
    }
  };

  useEffect(() => {
    fetchDomainKeywordWithQueries();
  }, [token, fetchDomainKeywordWithQueries]);

  useEffect(() => {
    if(selected){
      refreshData(getSearchCache(KEYWORDS_DOMAINS_CACHE_KEY))
    }
  }, [selected]);

  /*
  useEffect(() => {
    if (query.length > 0 && !loadingKeywords ) {
      refreshData(query);
    }
  }, [query, refreshData, loadingKeywords]);

  useEffect(() => {
    if (query.length > 0 && !loadingKeywords ) {
      refreshData(query);
    }
  }, [
    cpcOfSearches,
    differenceOfSearches,
    volumeOfSearches,
    modeFilter,
    selected,
    query,
    refreshData,
    loadingKeywords
  ]);
*/

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="flex-col gap-2 boxShadow" sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain')}
            loading={loadingKeywords}
            onSearch={handleSearchBtn}
            onCountry={(v) => handleLocation(v)}
            cacheKey={KEYWORDS_DOMAINS_CACHE_KEY}
          />
          <div className="flex gap-2 w-full items-center">
            <KeywordsFilters />
          </div>
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography className="mb-5">{t('organic_keywords')}</Typography>
            <Box sx={{
                width: 120,
                height: 36,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <ButtonExportExcel
                isLoading={isLoading}
                onClick={handleExportBtn}
                totalKeywords={keywords.totalKeywords}
                query={query}
              />
            </Box>
          </Box>
          <TableComponent
            rows={keywords.items}
            loading={loadingKeywords}
            getRowHeight={() => 'auto'}
            columns={COLUMNS_DOMAIN_POSITIONS(t, { code: location })}
            //initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
            rowCount={keywords.totalKeywords}
            paginationModel={paginationModel}
            sortModel={sortModel}
            onSortModelChange={handleSortChange}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            sortingMode="server"
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};

export const KeywordsDomainsPage = () => {
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState(LOCATION_CODE_ES);

  const cacheKey = `${KEYWORDS_DOMAINS_CACHE_KEY}-${query}-${location}`;

  return (
    <KeywordsFiltersProvider storageKey={cacheKey}>
      <DomainsCacheProvider>
        <SearchCacheProvider>
          <ContainerPage cacheKey={cacheKey} />
        </SearchCacheProvider>
      </DomainsCacheProvider>
    </KeywordsFiltersProvider>
  );
};

export default KeywordsDomainsPage;