import { FC } from 'react';

import './OnboardingSteps.scss';

import { Box } from '@mui/material';
import LoginButton from '../LoginButton/LoginButton';
import Checkout from '../RankerfyPlans/Checkout';
import { useSelectedPlan } from '../../context/PlanContext';
import RankerfyImage from '../../assets/img/isotipo.png';
import { useTranslation } from 'react-i18next';
import PlansView from '../RankerfyPlans/PlansView';


interface StepTwoProps {
  plan: string | null;
  billing: string | null;
  coupon: string | null;
}

interface StepThreeProps {
  coupon: string | null;
}

const StepOne: FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center'
      }}>
      <img src={RankerfyImage} alt="Rankerfy" style={{ width: '200px', marginBottom: '20px' }} />
      <h2>{t('onboarding.happy')}</h2>
      <LoginButton />
    </Box>
  );
};

const StepTwo: FC<StepTwoProps> = ({ plan, billing, coupon }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <h2>🎉{coupon ? (t('onboarding.welcomeWithCoupon')) : (t('onboarding.welcome'))}</h2>
      <PlansView plan={plan} billing={billing} coupon={coupon} />
    </Box>
  );
};

const StepThree: FC<StepThreeProps> = ({ coupon }) => {
  const { t } = useTranslation();
  const { selectedPlan, selectedBilling } = useSelectedPlan();

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <h2>💳 {t('onboarding.paySecure')}</h2>
      <Checkout plan={selectedPlan} billing={selectedBilling} coupon={coupon} />
    </Box>
  );
};

export { StepOne, StepTwo, StepThree };
