import dayjs from 'dayjs/dayjs.min';
import { menuItem } from '../types';
import { useEffect } from 'react';
import { FORMAT_DATE_DAYJS, MENU_ITEM_CUSTOM } from '../../../utils/Constants';

const DateRangeSelectorController = (model, onChange) => {

  useEffect(() => {
    if (model.customStartDate && model.customEndDate) {
      onChange({
        startDate: model.customStartDate.format(FORMAT_DATE_DAYJS),
        endDate: model.customEndDate.format(FORMAT_DATE_DAYJS)
      });
    }
  }, [model.customStartDate, model.customEndDate]);

  const handleOpenDatePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    model.setAnchorEl(event.currentTarget);
    model.setTempSelectedOption(model.selectedOption);
  };

  const handleCloseDatePicker = () => {
    model.setAnchorEl(null);
    model.setTempSelectedOption(model.selectedOption); // Reset temporary selection
  };

  const handleOptionSelect = (option: menuItem) => {
    model.setTempSelectedOption(option);
  };

  const handleOptionSelectCustom = () => {
    model.setTempSelectedOption({ unit: MENU_ITEM_CUSTOM });
  };


  const handleApply = () => {
    model.setSelectedOption(model.tempSelectedOption);

    if (model.tempSelectedOption.unit === MENU_ITEM_CUSTOM) {
      if (model.tempCustomStartDate) {
        model.setCustomStartDate(model.tempCustomStartDate);
      }
      if (model.tempCustomEndDate) {
        model.setCustomEndDate(model.tempCustomEndDate);
      }
    } else {
      model.setCustomStartDate(dayjs().subtract(model.GSC_OFFSET_START_DAYS, 'days').subtract(model.tempSelectedOption.value, model.tempSelectedOption.unit));
      model.setCustomEndDate(dayjs().subtract(model.GSC_OFFSET_END_DAYS, 'days'));
    }
    handleCloseDatePicker();
  };

  return {
    handleOpenDatePicker, handleCloseDatePicker,
    handleOptionSelect, handleOptionSelectCustom,
    handleApply
  };

};

export default DateRangeSelectorController;