import { useState } from 'react';

const pageSpeedInsightsModel = () => {

  interface PageSpeedResult {
    fcp: string;
    lcp: string;
    si: string;
    tbt: string;
    cls: string;
    score: number;
  }

  const [data, setData] = useState<{ desktop: PageSpeedResult | null, mobile: PageSpeedResult | null }>({
    desktop: null,
    mobile: null
  });

  const [url, setUrl] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false);
  const [loadedFirstTime, setLoadedFirstTime] = useState<boolean>(false)
  const [error, setError] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [opportunities, setOpportunities] = useState()
  const [diagnostics, setDiagnostics] = useState()
  const [serverResponseTime, setServerResponseTime] = useState<number>(0)

  return {
    url, setUrl,
    data, setData,
    loading, setLoading,
    loadedFirstTime, setLoadedFirstTime,
    error, setError,
    progress, setProgress,
    open, setOpen,
    opportunities, setOpportunities,
    diagnostics, setDiagnostics,
    serverResponseTime, setServerResponseTime
  };
};

export default pageSpeedInsightsModel;