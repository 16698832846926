import './ErrorPage.scss'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { generateUuid } from '../../utils/Utils';

import { Box, Button, Typography } from '@mui/material';
import { keywordsService } from '../../services/KeywordService';
import { useAccessToken } from '../../hooks/useAccessToken';

// Error Page Component
const ErrorPage = () => {
  const { t } = useTranslation();
  const token = useAccessToken();

  const location = useLocation();
  const { error } = location.state || { error: { message: 'Unknown error' } };
  const navigate = useNavigate()

  const handleRedirectToHome = () => {
    navigate('/'); // Redirect to the home page
  };

  const uuid = generateUuid()

  useEffect(() => {
    (async () => {
      await keywordsService.traceLog({ id: uuid, error: error.toString(), accessToken: token });
    })()
  }, []);

  return (
    <Box className={'errorPageBox'}>
      <Typography className={'error'}>
        {t('error_boundary')}
      </Typography>

      <Typography className={'errorText'}>
        {`Id: ${uuid}`}
      </Typography>

      <Button variant="contained" onClick={handleRedirectToHome} sx={{ mt: 2 }}>
        {t('continue')}
      </Button>
    </Box>
  );
};

export default ErrorPage;
