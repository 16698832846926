import styles from './SummaryCards.module.scss';

import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useTranslation } from 'react-i18next';
import { SummaryCardsProps } from '../../types';
import { useLocation } from 'react-router-dom';
import { formatNumber } from '../../../../utils/Utils';

interface SummaryCardsMobileProps extends Omit<SummaryCardsProps, 'handleMetricToggle'> {
  alwaysShow?: boolean;
  handleMetricToggle: (metric: string) => void;
  hasData: boolean;
  isFavorite: boolean;
  onFavoriteToggle: () => void;
  customIndex?: number;
  prevTotalClicks: number;
  prevTotalImpressions: number;
  prevAvgCTR: number;
  prevAvgPosition: number;
}

const calculateDifference = (currentValue, previousValue) => {
  if (!previousValue || !currentValue) return 0;
  const difference = parseFloat((currentValue - previousValue).toFixed(2));
  return difference;
};

const calculatePercentageDifference = (currentValue, previousValue) => {
  if (!previousValue || !currentValue) return '0.00%';
  const difference = ((currentValue - previousValue) / previousValue) * 100;
  const percentageDifference = difference.toFixed(2);
  return `${percentageDifference >= 0 ? '+' : ''}${percentageDifference}%`;
};

const getDifferenceDirection = (difference) => {
  return difference>0?'⬆':difference<0?'⬇':'';
};


const SummaryCards: React.FC<SummaryCardsMobileProps> = ({
                                                           formatNumber,
                                                           totalClicks,
                                                           totalImpressions,
                                                           avgCTR,
                                                           avgPosition,
                                                           metrics,
                                                           prevTotalClicks,
                                                           prevTotalImpressions,
                                                           prevAvgCTR,
                                                           prevAvgPosition,
                                                           alwaysShow = false,
                                                           handleMetricToggle,
                                                           hasData,
                                                           customIndex
                                                         }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const location = useLocation();
  const specificDomainPath = new RegExp('/projects/performance/detail/[^/]+').test(location.pathname);
  const isPerformanceOverviewPage = location.pathname === '/projects/performance/';

  if (!isMobile && !alwaysShow) {
    return null;
  }

  const applyMidIndex = specificDomainPath && !isMobile;

  const getDisplayIndex = (metricName: string) => {
    if (customIndex !== undefined) {
      return customIndex;
    }
    if (applyMidIndex && metrics[metricName]) {
      return '';
    }
    return '';
  };

  const clicksDifference = calculatePercentageDifference(totalClicks, prevTotalClicks);
  const impressionsDifference = calculatePercentageDifference(totalImpressions, prevTotalImpressions);
  const ctrDifference = calculateDifference(avgCTR, prevAvgCTR);
  const positionDifference = calculateDifference(avgPosition, prevAvgPosition);

  return (
    <Box className={`${styles.summaryCards} ${specificDomainPath ? styles.specificDomain : ''} metrics`}>
      <Box
        className={`${styles.metric} ${applyMidIndex ? styles.midIndex : ''} ${
          metrics.clicks ? styles.fadeIn : styles.inactive
        } ${specificDomainPath ? styles.nonClickable : ''}`}
      >
        <Tooltip title={t('headerName.clicks')} placement="top" arrow followCursor>
          <AdsClickIcon
            sx={{ fontSize: !isMobile && specificDomainPath ? '1rem' : '1.5rem' }}
            className={metrics.clicks ? styles.clicksActive : styles.iconInactive}
            onClick={() => hasData && handleMetricToggle('clicks')}
          />
        </Tooltip>
        <Typography
          sx={{
            fontSize: !isMobile && specificDomainPath ? '1.5rem' : 'default',
            cursor: specificDomainPath ? 'default' : 'pointer'
          }}
        >
          {formatNumber?.(totalClicks || 0) || '0'}
        </Typography>
        {!isPerformanceOverviewPage && (
          <Tooltip title={clicksDifference} arrow>
            <Typography
              variant="caption"
              sx={{
                fontSize: !isMobile && specificDomainPath ? '0.8rem' : 'default',
                cursor: specificDomainPath ? 'default' : 'pointer'
              }}
              className={Number(clicksDifference.replace('%', '')) >= 0 ? styles.textPositive : styles.textNegative}
            >
              <span
                style={{ fontSize: '1.2rem' }}>{getDifferenceDirection(Number(clicksDifference.replace('%', '')))}</span>
            </Typography>
          </Tooltip>
        )}
        {!isPerformanceOverviewPage && applyMidIndex && (
          <span className={styles.midIndexText}>{getDisplayIndex('clicks')}</span>
        )}
      </Box>
      <Box
        className={`${styles.metric} ${applyMidIndex ? styles.midIndex : ''} ${
          metrics.impressions ? styles.fadeIn : styles.inactive
        } ${specificDomainPath ? styles.nonClickable : ''}`}
      >
        <Tooltip title={t('headerName.impressions')} placement="top" arrow followCursor>
          <RemoveRedEyeIcon
            sx={{ fontSize: !isMobile && specificDomainPath ? '1rem' : '1.5rem' }}
            className={metrics.impressions ? styles.impressionsActive : styles.iconInactive}
            onClick={() => hasData && handleMetricToggle('impressions')}
          />
        </Tooltip>
        <Typography
          sx={{
            fontSize: !isMobile && specificDomainPath ? '1.5rem' : 'default',
            cursor: specificDomainPath ? 'default' : 'pointer'
          }}
        >
          {formatNumber?.(totalImpressions || 0) || '0'}
        </Typography>
        {!isPerformanceOverviewPage && (
          <Tooltip title={impressionsDifference} arrow>
            <Typography
              variant="caption"
              sx={{
                fontSize: !isMobile && specificDomainPath ? '0.8rem' : 'default',
                cursor: specificDomainPath ? 'default' : 'pointer'
              }}
              className={Number(impressionsDifference.replace('%', '')) >= 0 ? styles.textPositive : styles.textNegative}
            >
              <span
                style={{ fontSize: '1.2rem' }}>{getDifferenceDirection(Number(impressionsDifference.replace('%', '')))}</span>
            </Typography>
          </Tooltip>
        )}
        {!isPerformanceOverviewPage && applyMidIndex && (
          <span className={styles.midIndexText}>{getDisplayIndex('impressions')}</span>
        )}
      </Box>
      <Box
        className={`${styles.metric} ${applyMidIndex ? styles.midIndex : ''} ${
          metrics.ctr ? styles.fadeIn : styles.inactive
        } ${specificDomainPath ? styles.nonClickable : ''}`}
      >
        <Tooltip title={t('headerName.ctr')} placement="top" arrow followCursor>
          <PercentIcon
            sx={{ fontSize: !isMobile && specificDomainPath ? '1rem' : '1.5rem' }}
            className={metrics.ctr ? styles.ctrActive : styles.iconInactive}
            onClick={() => hasData && handleMetricToggle('ctr')}
          />
        </Tooltip>
        <Typography
          sx={{
            fontSize: !isMobile && specificDomainPath ? '1.5rem' : 'default',
            cursor: specificDomainPath ? 'default' : 'pointer'
          }}
        >
          {`${(avgCTR || 0).toFixed(2)}%`}
        </Typography>
        {!isPerformanceOverviewPage && (
          <Tooltip title={`${ctrDifference >= 0 ? '+' : ''}${ctrDifference}`} arrow>
            <Typography
              variant="caption"
              sx={{
                fontSize: !isMobile && specificDomainPath ? '0.8rem' : 'default',
                cursor: specificDomainPath ? 'default' : 'pointer'
              }}
              className={ctrDifference >= 0 ? styles.textPositive : styles.textNegative}
            >
              <span style={{ fontSize: '1.2rem' }}>{getDifferenceDirection(ctrDifference)}</span>
            </Typography>
          </Tooltip>
        )}
        {!isPerformanceOverviewPage && applyMidIndex && (
          <span className={styles.midIndexText}>{getDisplayIndex('ctr')}</span>
        )}
      </Box>
      <Box
        className={`${styles.metric} ${applyMidIndex ? styles.midIndex : ''} ${
          metrics.position ? styles.fadeIn : styles.inactive
        } ${specificDomainPath ? styles.nonClickable : ''}`}
      >
        <Tooltip title={t('positions')} placement="top" arrow followCursor>
          <TrendingUpIcon
            sx={{ fontSize: !isMobile && specificDomainPath ? '1rem' : '1.5rem' }}
            className={metrics.position ? styles.positionActive : styles.iconInactive}
            onClick={() => hasData && handleMetricToggle('position')}
          />
        </Tooltip>
        <Typography
          sx={{
            fontSize: !isMobile && specificDomainPath ? '1.5rem' : 'default',
            cursor: specificDomainPath ? 'default' : 'pointer'
          }}
        >
          {formatNumber(avgPosition, 0)}
        </Typography>
        {!isPerformanceOverviewPage && (
          <Tooltip title={`${positionDifference >= 0 ? '+' : ''}${positionDifference}`} arrow>
            <Typography
              variant="caption"
              sx={{
                fontSize: !isMobile && specificDomainPath ? '0.8rem' : 'default',
                cursor: specificDomainPath ? 'default' : 'pointer'
              }}
              className={positionDifference >= 0 ? styles.textPositive : styles.textNegative}
            >
              <span style={{ fontSize: '1.2rem' }}>{getDifferenceDirection(positionDifference)}</span>
            </Typography>
          </Tooltip>
        )}
        {!isPerformanceOverviewPage && applyMidIndex && (
          <span className={styles.midIndexText}>{getDisplayIndex('position')}</span>
        )}
      </Box>
    </Box>
  );
};

export default SummaryCards;