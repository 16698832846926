import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelectedPlan } from '../../context/PlanContext';
import { ALL_PLANS, PER_YEAR_OR_MONTH } from '../../utils/SubscriptionPlans';
import { useUserContext } from '../../context/UserContext';
import { useUsers } from '../../hooks/useUsers';
import Splash from '../Splash';
import PlansViewModel from './PlansModel';
import PlansViewController from './PlansController';

interface CheckoutProps {
  plan: string | null;
  billing: string | null;
  coupon: string | null;
}

const Checkout: React.FC<CheckoutProps> = ({ plan, billing, coupon }) => {

  const model = PlansViewModel()
  const controller = PlansViewController(model)

  useEffect(() => {
    controller.redirectToStripeCheckout(plan, billing, coupon)
  }, []);

  return (
    <Splash />
  );
};

export default Checkout;