import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import WithSkeleton from '../WithSkeleton/WithSkeleton';
import ContainerIntents from '../KeywordIntent/Container';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import KeywordFeatures from '../KeywordFeatures/KeywordFeatures';
import KeywordPositionLevel from '../KeywordPositionLevel/KeywordPositionLevel';
import { formatNumber } from '../../utils/Utils'
import Cost from '../Cost';

interface LevelCompetitionProps {
  data: {
    cpc?: number;
    competition?: number;
    low_top?: number;
    high_top?: number;
    intent_info?:
      | {
      foreign_intent?: string[];
      main_intent?: string;
    }
      | undefined;
    time_update?: string;
  };
  results: {
    item_types?: string[];
    se_results_count?: number;
  };
}

const LevelCompetition = ({ data, results }: LevelCompetitionProps) => {
  const { t } = useTranslation();
  if (!data) {
    data = {
      cpc: 0,
      low_top: 0,
      high_top: 0,
      competition: 0,
      time_update: '',
      intent_info: undefined
    };
  }

  return (
    <div className="level-competition h-max flex-col space-between">
      <div className="py-2 flex justify-between">
        <div>
          <CustomTooltip content={t('tooltip.keyword_info.cpc')}>
            <Typography className="text-gray-500">{t('headerName.keyword_info.cpc')}</Typography>
          </CustomTooltip>
          <WithSkeleton data={data?.cpc} width="100%" height={40}>
            <Typography className="text-2xl font-bold"><Cost value={data?.cpc} /></Typography>
            <KeywordPositionLevel low_top={data.low_top} high_top={data.high_top} />
          </WithSkeleton>
        </div>
        <div>
          <CustomTooltip content={t('tooltips.keywords.explore_intent')}>
            <Typography className="text-gray-500">{t('headerName.search_intent_info.intent')}</Typography>
          </CustomTooltip>
          <WithSkeleton data={data?.intent_info} width="100%" height={40}>
            <ContainerIntents data={data?.intent_info} />
          </WithSkeleton>
        </div>
      </div>
      <hr />
      <div className="flex justify-between py-2">
        <div>
          <CustomTooltip content={t('tooltip.serp_info.se_results_count')}>
            <Typography className="text-gray-500">{t('headerName.serp_info.se_results_count')}</Typography>
          </CustomTooltip>
          <WithSkeleton data={results?.se_results_count} width="100%" height={40}>
            <Typography className="text-2xl font-bold">
              {formatNumber(results.se_results_count) as number}
            </Typography>
          </WithSkeleton>
        </div>
        <div>
          <CustomTooltip content={t('tooltip.keyword_info.competition')}>
            <Typography className="text-gray-500">{t('headerName.keyword_info.competition')}</Typography>
          </CustomTooltip>
          <WithSkeleton data={data?.competition} width="100%" height={40}>
            <Typography className="text-2xl font-bold text-right">{formatNumber(data.competition)}</Typography>
          </WithSkeleton>
        </div>
      </div>
      <hr />
      <div className="flex justify-between py-2">
        <div>
          <CustomTooltip content={t('tooltips.keywords.explore_features')}>
            <Typography className="text-gray-500">{t('features')}</Typography>
          </CustomTooltip>
          <div className="text-2xl font-bold line-height-0">
            <KeywordFeatures data={results.item_types as string[]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelCompetition;