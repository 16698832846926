import { useTranslation } from 'react-i18next';

import { useDomainsData } from '../../hooks/useDomainsData';
import { LOCATION_CODE_ES } from '../../utils/Constants';
import { COLUMNS_DOMAIN_BACKLINKS } from '../../utils/TableColumnsConstants';

import { Grid, Typography } from '@mui/material';
import PaperItem from '../../components/Essential/PaperItem';
import Search from '../../components/Search';
import TableComponent from '../../components/TableComponent';

const BACKLINKS_CACHE_KEY = 'backlinks_search';

export const BacklinksPage = () => {
  const { t } = useTranslation();
  const { domainsBacklinks, loadingBacklinks, fetchDomainBacklinks } =
    useDomainsData('backlinks_cache_key');

  const handleSearchBtn = async (search: string) => {
    if (search.length === 0) return;
    const data = { location: LOCATION_CODE_ES, domain: search };
    await fetchDomainBacklinks(data);
  };

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain')}
            loading={loadingBacklinks}
            onSearch={handleSearchBtn}
            cacheKey={BACKLINKS_CACHE_KEY}
          />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Typography className="mb-5">{t('routes.backlinks')}</Typography>
          <TableComponent
            getRowHeight={() => 'auto'}
            loading={loadingBacklinks}
            rows={domainsBacklinks.items}
            columns={COLUMNS_DOMAIN_BACKLINKS(t, { code: LOCATION_CODE_ES })}
            paginationMode="client"
            sortingMode="client"
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};
