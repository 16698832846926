import './KeywordMetrics.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Keyword } from '../../types';
import { useColorModeContext } from '../../context/ColorModeContext';
import { formatNumber, keywordDifficultyValuesProgress } from '../../utils/Utils';

import { Grid, Divider, Typography } from '@mui/material';
import WithSkeleton from '../WithSkeleton/WithSkeleton';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import DifficultyProgress from '../DifficultyProgress/DifficultyProgress';

const KeywordMetrics = ({ data }: { data?: Keyword }) => {
  const { t } = useTranslation();
  const { mode } = useColorModeContext();

  const { htmlClass } = keywordDifficultyValuesProgress(data?.extra?.keyword_difficulty ?? 0, mode);

  return (
    <Grid className="KeywordMetrics">
      <Grid item xs={12} md={12}>
        <CustomTooltip content={t('tooltip.keyword_info.search_volume')}>
          <Typography className="title">{t('headerName.keyword_info.search_volume')}</Typography>
        </CustomTooltip>
        <WithSkeleton data={data?.keyword_info?.search_volume} width="100%" height={40}>
          <Typography className="text-2xl font-bold search-volume">
            {formatNumber(data?.keyword_info?.search_volume as number) || 0}
          </Typography>
        </WithSkeleton>
        <Divider />
      </Grid>

      <Grid item xs={12} md={12}>
        <div>
          <CustomTooltip content={t('tooltips.keywords.explore_keywords_difficult')}>
            <Typography className="title">{t('keywordDifficulty')}</Typography>
          </CustomTooltip>
          <WithSkeleton
            variant="rounded"
            width="100%"
            height={90}
            data={data?.extra?.keyword_difficulty}>
            <DifficultyProgress
              htmlClass={htmlClass}
              value={data?.extra?.keyword_difficulty || 0}
            />
          </WithSkeleton>
        </div>
      </Grid>
    </Grid>
  );
};

export default KeywordMetrics;
