import './percentage.scss'

interface Props {
  value: number;
}

const percentage = ({ value }: Props) => {

  if(value===undefined){
    return '0%'
  }

  return (
    <span className={'distribution'}>{`${value}%`}</span>
  );
};

export default percentage;