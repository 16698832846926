import { useState } from 'react';
import { ProjectInterface } from '../../../interfaces/ProjectInterface';
import { useProjectsData } from '../../../hooks/useProjectsData';
import useProjectsFilters from '../../../hooks/useProjectsFilters';
import useRangesDate from '../../../hooks/useRangesDate';
import useExportData from '../../../hooks/useExportData';

const CannibalizationModel = () => {

  const [project, setProject] = useState<ProjectInterface>({ property: null });
  const { dataProject, fetchCannibalization, loadingCannibalization } = useProjectsData();
  const [dataCannibalization, setDataCannibalization] = useState<any[]>([]);
  const { rangesDate, handleChangeRanges } = useRangesDate();
  const { fetchExportProjectsCannibalization, isLoading: isExportLoading } = useExportData();

  const {
    clicksOfSearches, hasClicksOfSearches, defaultValueClicks, handleClicksOfSearches,
    impressionsOfSearches, defaultValueImpression, hasImpressionsOfSearches, handleImpressionsOfSearches,
    positionOfSearches, hasPositionsOfSearches, defaultValuePositions, handlePositionOfSearches,
    ctrOfSearches, hasCtrOfSearches, defaultValueCtr, handleCtrOfSearches,
    handleFiltersApply
  } = useProjectsFilters();

  return {
    project, setProject,
    dataProject, fetchCannibalization, loadingCannibalization,
    dataCannibalization, setDataCannibalization,
    rangesDate, handleChangeRanges,
    clicksOfSearches, hasClicksOfSearches, defaultValueClicks, handleClicksOfSearches,
    impressionsOfSearches, defaultValueImpression, hasImpressionsOfSearches, handleImpressionsOfSearches,
    positionOfSearches, hasPositionsOfSearches, defaultValuePositions, handlePositionOfSearches,
    ctrOfSearches, hasCtrOfSearches, defaultValueCtr, handleCtrOfSearches,
    fetchExportProjectsCannibalization, isLoading: isExportLoading,
    handleFiltersApply
  }
}

export default CannibalizationModel