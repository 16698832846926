import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useExportData from '../../hooks/useExportData';
import { useKeywordsData } from '../../hooks/useKeywordsData';
import { removeNumericValue } from '../../utils/Utils';
import { LOCATION_CODE_ES } from '../../utils/Constants';
import { COLUMNS_KEYWORD_ANALYSIS, COLUMNS_KEYWORD_BATCH } from '../../utils/TableColumnsConstants';
import { KeywordsFiltersProvider, useKeywordsFiltersContext } from '../../context/KeywordsFilterContext';
import { Grid, Typography } from '@mui/material';
import PaperItem from '../../components/Essential/PaperItem';
import TableComponent from '../../components/TableComponent';
import KeywordMetadata from '../../components/KeywordMetadata';
import ButtonExportExcel from '../../components/Buttons/ExportExcel';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import KeywordsFilters from '../../components/Filters/Keywords/KeywordsFilter';
import TextareaWithCountry from '../../components/Textarea/TextareaWithCountry';
import { SearchCacheProvider, useSearchCache } from '../../context/SearchCacheContext';
import usePaginationModel from '../../hooks/usePaginationModel';
import Search from '../../components/Search';
import useRefreshQueries from '../../hooks/useRefreshQueries';
import { useKeywordsIdeasCache } from '../../context/KeywordsIdeasCacheContext';

const BATCH_ANALYSIS_PAGE_CACHE_CONTEXT = 'batchAnalysisPage';
const BATCH_ANALYSIS_SEARCH_CACHE_CONTEXT = 'batchAnalysisSearch';
const FILTERS_STORAGE_KEY = 'batch_analysis_filters';

const ContainerPage: React.FC = () => {
  const { t } = useTranslation();
  const { isLoading, fetchExportKeywordsBulkAnalysis } = useExportData();
  const { getSearchCache, updateSearchCache, getPagination, updatePagination } = useSearchCache();

  const { location, handleLocation } = useRefreshQueries(BATCH_ANALYSIS_SEARCH_CACHE_CONTEXT);
  const { updateLocation } = useKeywordsIdeasCache();

  const initialEditorValue = useMemo(() => {
    const cachedValue = getSearchCache(`${BATCH_ANALYSIS_SEARCH_CACHE_CONTEXT}_input`);
    return cachedValue ?? '';
  }, [getSearchCache]);

  const [keywords, setKeywords] = useState<string[] | undefined>(removeNumericValue(initialEditorValue));
  const [editorValue, setEditorValue] = useState<string>(initialEditorValue);
  const [query, setQuery] = useState<string>(initialEditorValue);

  const { keywordsBulk, loadingKeywordsBulk, fetchKeywordsBulk } = useKeywordsData(BATCH_ANALYSIS_PAGE_CACHE_CONTEXT);
  const { selected,handleSearchKeywords } = useKeywordsFiltersContext();
  const cachedPagination = getPagination(BATCH_ANALYSIS_PAGE_CACHE_CONTEXT);
  const { paginationModel, handleSortModelChange, handlePaginationChange } = usePaginationModel(cachedPagination);
  const [initialRenderDone, setInitialRenderDone] = useState(false);

  const requestMemoize = useMemo(
    () => ({
      location,
      keywords,
      ...handleSearchKeywords(),
    }),
    [selected, location, keywords, handleSearchKeywords]
  );

  const refreshData = useCallback(
    async (keywords: string) => {
      const keywordArray = removeNumericValue(keywords);
      setQuery(keywords);
      await fetchKeywordsBulk({ ...requestMemoize, keywords: keywordArray });
    },
    [requestMemoize, fetchKeywordsBulk]
  );

  useEffect(() => {
    if(selected){
      refreshData(editorValue)
    }
  }, [selected]);


  const handleSearchBtn = useCallback(async () => {
    const removeNumeric = removeNumericValue(editorValue);
    setKeywords(removeNumeric);
    updateSearchCache(`${BATCH_ANALYSIS_SEARCH_CACHE_CONTEXT}_input`, editorValue);
    await refreshData(editorValue);
  }, [editorValue, refreshData, updateSearchCache]);

  const handleExportBtn = async (format: string) => {
    if (!keywords || keywords.length === 0) return;

    const data = {
      location,
      keywords,
      ...requestMemoize,
      title: 'Análisis Masivo',
    };

    try {
      await fetchExportKeywordsBulkAnalysis(data);
    } catch (error) {
      console.error('Error al exportar los datos:', error);
    }
  };

  useEffect(() => {
    if (initialRenderDone) {
      updateSearchCache(`${BATCH_ANALYSIS_SEARCH_CACHE_CONTEXT}_input`, editorValue);
    }
  }, [editorValue, updateSearchCache, initialRenderDone]);

  useEffect(() => {
    if (initialRenderDone) {
      updatePagination(BATCH_ANALYSIS_PAGE_CACHE_CONTEXT, paginationModel);
    }
  }, [paginationModel, updatePagination, initialRenderDone]);

  useEffect(() => {
    setInitialRenderDone(true); 
  }, []);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>

          <Search
            cacheKey={BATCH_ANALYSIS_SEARCH_CACHE_CONTEXT}
            placeholder={t('search_placeholder_keyword_list')}
            loading={loadingKeywordsBulk}
            onSearch={handleSearchBtn}
            onCountry={(code: number) => {
              handleLocation(code);
              updateLocation(BATCH_ANALYSIS_SEARCH_CACHE_CONTEXT, code);
            }}
            onChange={(value: string) => setEditorValue(value)}
            lines={4}
          />

          <div className="flex gap-2 w-full items-center pt-3">
            <KeywordsFilters />
          </div>
        </PaperItem>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <div className="flex flex-col gap-2">
            <CustomTooltip content={t('tooltips.keywords.batch_analysis_title')}>
              <Typography>{t('routes.batch-analysis')}</Typography>
            </CustomTooltip>
            <div className="flex items-center justify-between">
              <KeywordMetadata metadata={keywordsBulk} />
              <ButtonExportExcel
                isLoading={isLoading}
                onClick={handleExportBtn}
                totalKeywords={keywordsBulk.items.length}
                query={query}
              />
            </div>
          </div>
          <TableComponent
            rows={keywordsBulk.items}
            loading={loadingKeywordsBulk}
            columns={COLUMNS_KEYWORD_BATCH(t, { code: location })}
            paginationModel={paginationModel}
            onSortModelChange={handleSortModelChange}
            onPaginationModelChange={handlePaginationChange}
            paginationMode="client"
            sortingMode="client"
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};

export const BatchAnalysisPage: React.FC = () => (
  <KeywordsFiltersProvider storageKey={FILTERS_STORAGE_KEY}>
    <SearchCacheProvider>
      <ContainerPage />
    </SearchCacheProvider>
  </KeywordsFiltersProvider>
);