import './manage.scss';
import { useTranslation } from 'react-i18next';
import useProjectsManage from '../../hooks/useProjectsManage';
import { Button, MenuItem, Typography } from '@mui/material';
import SimpleDialog from '../../components/Dialog/Dialog';
import Selector from '../../components/Selector/Selector';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import SplitButton from '../../components/SplitButton/SplitButton';
import { extractDomain } from '../../utils/Utils';

export const ProjectsManagePage = () => {
  const { t } = useTranslation();

  const {
    error,
    loading,
    projectId,
    openModal,
    propertyId,
    dataProject,
    dataProperties,
    loadingProjects,
    COLUMNS_PROJECTS,
    connectedAccounts,
    blur,

    fetchToProperties,
    fetchCreateProjects,

    handleToggleModal,
    handleToggleProperty,
    validateCurrentsProject,
    handleOpenModalProjects,
    handleRedirectNewAccount,
  } = useProjectsManage();

  return (
    <div className="flex-col">
      <div className="manage-header">
        <h1>{t('projects')}</h1>
        <div className="manage-buttons">
          <SplitButton
            loading={loading}
            onClick={handleOpenModalProjects}
            onClickAccount={() => handleRedirectNewAccount()}
          />
        </div>
      </div>
      <PaperItem className="boxShadow" sx={{ p: 3 }}>
        <div className="data-fade-in">
          <TableComponent
            hideFooter={dataProject.length<11}
            rows={dataProject}
            loading={loadingProjects}
            disableRowSelectionOnClick
            columns={COLUMNS_PROJECTS}
            getRowHeight={() => 'auto'}
            slots={{
              noResultsOverlay: () => (
                <div className="manage-info">
                  <Typography>{error?.message}</Typography>
                </div>
              ),
              noRowsOverlay: () => (
                <div className="manage-info">
                  {error ? (
                    <Typography>{error?.message}</Typography>
                  ) : (
                    <Typography>{t('no_projects_created')}</Typography>
                  )}
                </div>
              )
            }}
            paginationMode={'client'}
            sortingMode={'client'}
          />
        </div>
      </PaperItem>

      <SimpleDialog
        open={openModal}
        onClose={() => handleToggleModal(false)}
        title={t('create_your_new_project')}>
        <div className="manage-popover">
          <Selector
            id="account"
            value={projectId}
            label={t('account')}
            placeholder={t('select_an_account_to_continue')}
            onChange={(event) => fetchToProperties(event.target.value)}>
            {connectedAccounts?.map((account) => (
              <MenuItem key={account.id} value={JSON.stringify(account)}>
                {account.name}
              </MenuItem>
            ))}
          </Selector>
          <Selector
            id="property"
            value={propertyId}
            label={t('property')}
            disabled={projectId === undefined}
            onChange={(event) => handleToggleProperty(event.target.value)}>
            {dataProperties?.map((property) => (
              <MenuItem key={property.siteUrl} value={property.siteUrl} disabled={validateCurrentsProject(property.siteUrl)}>
                <div className={'menuItem'}>
                  <div className={'domain'}>
                    <img src={`https://www.google.com/s2/favicons?sz=32&domain=${extractDomain(property.siteUrl)}`}
                      loading="lazy" alt="favicon" width={32} height={32} />
                    {property.siteUrl}
                  </div>
                  {validateCurrentsProject(property.siteUrl) && (
                    <div className={'error'}>{t('project_already_added')}</div>
                  )}
                </div>
              </MenuItem>
            ))}
          </Selector>

          <Button
            variant="contained"
            className="btn-manage"
            onClick={fetchCreateProjects}
            disabled={propertyId === undefined}>
            {t('add_projects')}
          </Button>
        </div>
      </SimpleDialog>
    </div>
  );
};