import './PageContainer.scss';
import { Outlet } from 'react-router-dom';

import { Header } from './Header';
import { Sidebar } from './Sidebar';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { FC, PropsWithChildren } from 'react';

export const PageContainer: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Header />
    <div className="page-container">
      <Sidebar />
      <div className="page-content">
        <Breadcrumb />
        {children ?? <Outlet />}
      </div>
    </div>
  </>
);
