import { useState } from 'react';
import { useProjectsData } from '../../../hooks/useProjectsData';
import { ProjectInterface } from '../../../interfaces/ProjectInterface';
import useProjectsFilters from '../../../hooks/useProjectsFilters';
import useRangesDate from '../../../hooks/useRangesDate';
import * as CONSTANTS from '../../../utils/Constants';
import useExportData from '../../../hooks/useExportData';

const OpportunityKeywordsModel = () => {

  const {
    clicksOfSearches, hasClicksOfSearches, defaultValueClicks, handleClicksOfSearches,
    impressionsOfSearches, hasImpressionsOfSearches, defaultValueImpression, handleImpressionsOfSearches,
    positionOfSearches, hasPositionsOfSearches, defaultValuePositions, handlePositionOfSearches,
    ctrOfSearches, hasCtrOfSearches, defaultValueCtr, handleCtrOfSearches,
    handleFiltersApply
  } = useProjectsFilters();
  const { rangesDate, handleChangeRanges } = useRangesDate();
  const [dataKeyword, setDataKeyword] = useState<any[]>([]);

  const { fetchExportProjectsKeywords, isLoading: isExportLoading } = useExportData();

  const [project, setProject] = useState<ProjectInterface>({ property: null });
  const [url, setUrl] = useState<string>()

  const { loadingKeywords, dataProject, fetchKeywords } = useProjectsData();

  type FilterModeType = (typeof CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY)[number];
  const [filterMode, setFilterMode] = useState<FilterModeType>(
    CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY[0]
  );


  return {
    project, setProject,
    loadingKeywords, dataProject, fetchKeywords,
    url, setUrl,
    clicksOfSearches, hasClicksOfSearches, defaultValueClicks, handleClicksOfSearches,
    impressionsOfSearches, defaultValueImpression, hasImpressionsOfSearches, handleImpressionsOfSearches,
    positionOfSearches, hasPositionsOfSearches, defaultValuePositions, handlePositionOfSearches,
    ctrOfSearches, hasCtrOfSearches, defaultValueCtr, handleCtrOfSearches,
    handleFiltersApply,
    rangesDate, handleChangeRanges,
    dataKeyword, setDataKeyword,
    filterMode, setFilterMode,
    fetchExportProjectsKeywords, isLoading: isExportLoading
  }
}

export default OpportunityKeywordsModel