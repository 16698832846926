import React from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatNumber, generateUuid } from '../../../utils/Utils';
import { DomainRankRanges } from '../../../types/domainRankRanges.type';

const MIN_LENGTH = 5;

interface Props {
  loading: boolean;
  data: DomainRankRanges | undefined;
  height?: number;
  width?: number;
}

/**
 * BarChartTop
 * @param data - json data array
 * @param height - custom height for the chart
 * @returns
 */
export const BarChartTop = ({ data, loading, height = 400, width = 500 }: Props) => {
  const { t } = useTranslation();

  const fillBarMemoize = React.useMemo(() => {
    const fillBar: any[] = [];
    if (!data) return fillBar;

    Array.from({ length: MIN_LENGTH }).forEach((_, index) => {
      const nameItems = Object.keys(data)[index];
      fillBar.push({
        name: nameItems,
        label: nameItems,
        dataKey: data[nameItems]
      });
    });

    return fillBar;
  }, [data]);

  if (!data) {
    return (
      <div className="container-chart" style={{ minHeight: `${height - 10}px` }}>
        <div className="skeleton" style={{ minHeight: '100%' }}>
          {Array.from({ length: MIN_LENGTH }).map(() => (
            <Skeleton
              key={generateUuid()}
              height={height}
              className="flex"
              variant="rectangular"
              width={`${100 / MIN_LENGTH}%`}
              animation={loading ? 'pulse' : false}
              style={{ alignSelf: 'flex-end', overflow: 'hidden' }}
            />
          ))}
        </div>
      </div>
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return(
        <div className="tooltip container">
          <div key={data.dataKey} className={'inner'}>
            <div className={'left'}>{t('positions')}:</div>
            <div className={'right'}><span className={'domain'}>{data.name}</span></div>
          </div>
          <div key={data.dataKey} className={'inner'}>
            <div className={'left'}>{t('headerName.keywords')}:</div>
            <div className={'right'}>{formatNumber(data.dataKey)}</div>
          </div>
        </div>
      )
    }

  };

  return (
    <ResponsiveContainer
      width="100%"
      height={height}
      minHeight="100%"
      className="flex container-chart-responsive">
    <BarChart width={width} height={height} data={fillBarMemoize}>
        <YAxis axisLine={false} tickFormatter={(value) => {
          return formatNumber(value).toString();
        }} />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomTooltip active={undefined} payload={undefined} />}
        />
        <XAxis dataKey="label" name="value" />
        <Bar dataKey="dataKey" name="value" fill="rgb(126 58 242)" background={{ fill: '#eee' }} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartTop;
