import './PageSpeedInsights.scss'

import { FC } from 'react';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { formatNumber } from '../../utils/Utils';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';

interface PageSpeedResult {
  fcp: string;
  lcp: string;
  si: string;
  tbt: string;
  cls: string;
  score: number;
}

const getColor = (value: number, type: string) => {
  switch (type) {
    case 'score':
      return value > 90 ? '#4caf50' : value > 50 ? '#ffeb3b' : '#f44336';
    case 'si':
      return value < 3.4 ? '#4caf50' : value < 5.8 ? '#ffeb3b' : '#f44336';
    case 'tbt':
      return value < 200 ? '#4caf50' : value < 600 ? '#ffeb3b' : '#f44336';
    case 'cls':
      return value < 0.1 ? '#4caf50' : value < 0.25 ? '#ffeb3b' : '#f44336';
    default:
      return value < 2.5 ? '#4caf50' : value < 4 ? '#ffeb3b' : '#f44336';
  }
};

const ReportCard: FC<{data: PageSpeedResult}> = ({ data }) => {
  const { t } = useTranslation();

  const score = data?data.score:0

  const pieData = [
    { name: 'score', value: score },
    { name: 'remainder', value: 100 - score }
  ];

  return (
    <Box mt={2}>
      <Box className={'insight'} marginBottom={'20px'}>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie data={pieData} dataKey="value" startAngle={90} endAngle={-270} innerRadius={70} outerRadius={80} labelLine={false} >
              {pieData.map((entry, index) => (
                <Cell key={index} fill={entry.name === 'score' ? getColor(entry.value, 'score') : '#e0e0e0'} />
              ))}
            </Pie>

            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fill={score?getColor(score, 'score'):'#e0e0e0'} fontSize={24} >
              {formatNumber(score, 0)}
            </text>
          </PieChart>
        </ResponsiveContainer>
        <Typography variant="h6" className={'performanceText'} align="center">{t('routes.performance')}</Typography>
      </Box>


      <Grid container spacing={2} className={'resultsGrid'}>
        {Object.entries({
          fcp: 'First Contentful Paint (FCP)',
          lcp: 'Largest Contentful Paint (LCP)',
          si: 'Speed Index (SI)',
          tbt: 'Total Blocking Time (TBT)',
          cls: 'Cumulative Layout Shift (CLS)',
        }).map(([key, label]) => (
          <Grid size={{ xs: 12, sm: 6 }} key={key}>
            <Card>
              <CardContent>
                <Box className={'cardHeader'} display={'flex'} alignItems={'center'} >
                  <span className={'indicator'} style={{ backgroundColor: data?(getColor(parseFloat(data[key as keyof PageSpeedResult]), key)):'#e0e0e0' }}></span>
                  <Typography variant="body2" color="textSecondary">{label}</Typography>
                </Box>
                {data?(
                  <Typography variant="h5" component="p" className={'cardValue'}>
                    {data[key as keyof PageSpeedResult]}
                  </Typography>
                ):(
                  <Skeleton variant="rectangular" width={60} height={24} />
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

}

export default ReportCard