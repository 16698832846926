import React from 'react';
import { formatCurrencyByLocation } from '../../utils/Utils';

interface Props {
  value: number;
}

const cost = ({ value }: Props) => {
  return (
      formatCurrencyByLocation(value)
  );
};

export default cost;