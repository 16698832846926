import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../utils/Constants';
import { classNames } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import { Typography } from '@mui/material';
import KeywordLinkBlank from '../KeywordLinkBlank';

interface Props {
  url: string;
  domain: string;
  location?: string | number;
}

const OverviewRedirectLink: React.FC<Props> = ({ url, domain, location }) => {
  const { mode } = useColorModeContext();
  const href = `${ROUTES.DOMAIN_VIEW}?q=${domain}&db=${location}`;

  return (
    <div className="flex flex-col py-2">
      <Typography className="flex-start">
        <Link
          replace
          to={href}
          relative="path"
          className={classNames('btn-link keyword link-domain', mode)}>
          {domain}
        </Link>
      </Typography>
      <KeywordLinkBlank href={url} />
    </div>
  );
};

export default OverviewRedirectLink;
