import './PageSpeedInsights.scss'

import { FC, useState } from 'react';
import { Paper, Typography, Link, IconButton, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Constant from '../../utils/Constants';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

interface opportunityProps {
  opportunities: Array<{
    id: string,
    title: string
  }>
}

const Opportunities: FC<opportunityProps>  = ({opportunities}) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(null);
  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };


  const renderSkeleton = () => {
    return (
      <div>
        {[...Array(10)].map((_, index) => (
          <Paper key={index} style={{ marginBottom: '10px', padding: 2 }}>
            <Skeleton variant="rectangular" width={300} height={14} style={{ margin: '8px 0 30px 10px' }} />
          </Paper>
        ))}
      </div>
    );
  }

  return (
    <>
      <Paper sx={{ padding: 2, marginTop: 2 }} className={'opportunities'} >
        <Typography className={'headerTitle'} align="center">{`${t('opportunities')} ${opportunities?.length}`}</Typography>
        {opportunities && opportunities.length>0?(

          opportunities.map((item, index) => (
            <Paper key={index} style={{ marginBottom: '10px', padding: '5px 5px 1px 10px' }}>
              <p onClick={() => handleToggle(index)} style={{ cursor: 'pointer' }} className={'title'}>
                {t(`opportunity.${item.id}`)}
              </p>
              {expanded === index && (
                <p>
                  {t(`opportunity.description.${item.id}`)}

                  {Constant.OPPORTUNITY_LINKS.get(item.id) &&
                    <Link href={Constant.OPPORTUNITY_LINKS.get(item.id)} target="_blank" rel="noopener noreferrer" underline="none">
                      <IconButton>
                        <LaunchRoundedIcon className="noColor" fontSize="small" />
                      </IconButton>
                    </Link>
                  }

                </p>
              )}
            </Paper>
          ))


        ):(
          renderSkeleton()
        )}
      </Paper>


    </>
  )

}

export default Opportunities