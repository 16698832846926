import BaseService from './BaseService';
import { getBaseUrl } from '../utils/Utils';
import * as SERVICES from './Endpoint';
import {
  ProjectsRequest,
  ProjectsResponse,
  ProjectsPropertiesRequest,
  ProjectsPropertiesResponse,
  ProjectsThinContentResponse,
  ProjectsOpportunityResponse,
  ProjectsCannibalizationResponse,
  ProjectsPerformanceResponse,
  ProjectsInfoRequest,
  ProjectsInfoResponse
} from '../types';

class ProjectsService extends BaseService {
  async getProjects(params: ProjectsRequest): Promise<ProjectsResponse> {
    const { account_id, accessToken } = params;
    const url = `${getBaseUrl()}${SERVICES.SERVICE_USERS_GET_PROJECTS}`;
    const response = await this.post(url, { account_id }, accessToken);
    return response.data;
  }

  async deleteProjects(params: ProjectsRequest): Promise<string> {
    const { id, accessToken } = params;
    const url = `${getBaseUrl()}${SERVICES.SERVICE_USERS_DELETE_PROJECT}`;
    const response = await this.post(url, { id }, accessToken);
    return response.data;
  }

  async getKeywords(params: ProjectsRequest): Promise<ProjectsOpportunityResponse> {
    const { id, url, accessToken, ...rest } = params;
    const baseUrl = `${getBaseUrl()}${SERVICES.SERVICE_PROJECTS_GET_KEYWORDS_FROM_URL}`;
    const data = { id, url, ...rest };
    const response = await this.post(baseUrl, data, accessToken);
    return response.data ?? [];
  }

  async getThinContent(params: ProjectsRequest): Promise<ProjectsThinContentResponse> {
    const { id, accessToken, ...rest } = params;
    const baseUrl = `${getBaseUrl()}${SERVICES.SERVICE_PROJECTS_GET_THIN_CONTENT}`;
    const data = { id, ...rest };
    const response = await this.post(baseUrl, data, accessToken);
    return response.data ?? [];
  }

  async getCannibalization(params: ProjectsRequest): Promise<ProjectsCannibalizationResponse> {
    const { id, accessToken, ...rest } = params;
    const baseUrl = `${getBaseUrl()}${SERVICES.SERVICE_PROJECTS_GET_CANNIBALIZATION}`;
    const data = { id, ...rest };
    const response = await this.post(baseUrl, data, accessToken);
    return response.data ?? [];
  }

  async createProject(params: ProjectsRequest): Promise<string> {
    const { id, property, accessToken } = params;
    const baseUrl = `${getBaseUrl()}${SERVICES.SERVICE_USERS_CREATE_PROJECT}`;
    const data = { id, property };
    const response = await this.post(baseUrl, data, accessToken);
    return response.data;
  }

  async getProperties(params: ProjectsPropertiesRequest): Promise<ProjectsPropertiesResponse[]> {
    const { id, accessToken } = params;
    const baseUrl = `${getBaseUrl()}${SERVICES.SERVICE_USERS_GET_PROPERTIES}`;
    const data = { id };
    const response = await this.post(baseUrl, data, accessToken);
    return response.data;
  }

  async getProjectsPerformance(params: ProjectsRequest): Promise<ProjectsPerformanceResponse> {
    const { id, accessToken, ...rest } = params;
    const baseUrl = `${getBaseUrl()}${SERVICES.SERVICE_PROJECTS_GET_PROJECTS_PERFORMANCE}`;
    const data = { id, ...rest };
    const response = await this.post(baseUrl, data, accessToken);
    return response.data ?? { items: [] };
  }
  
  async getProjectInfo(params: ProjectsInfoRequest): Promise<ProjectsInfoResponse> {
    const { id, accessToken, ...rest } = params;
    const baseUrl =  `${getBaseUrl()}${SERVICES.SERVICE_PROJECTS_GET_PROJECT_INFO}`;
    const data = { id, ...rest };
    const response = await this.post(baseUrl, data, accessToken);
    return response.data ?? { items: [] };
  }
}

export const projectsService = new ProjectsService();