import './ProjectProperty.scss'
import { Typography } from '@mui/material';
import { extractDomain } from '../../utils/Utils';



interface Props {
  property: string
  options: any
}

const ProjectProperty  = ({ property, options } : Props) => {

  if (!property) {
    return;
  }

  const domain = extractDomain(property);
  return (
    <div className={'projectProperty'}>
      <img
        src={`https://www.google.com/s2/favicons?sz=32&domain=${domain}`}
        loading="lazy"
        alt="favicon"
        width={32}
        height={32}
      />
      <Typography className={options.blur ? 'blurred-text' : ''}>{domain}</Typography>
    </div>
  );
};

export default ProjectProperty;
