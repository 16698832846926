import React from 'react';
import './BacklinkDate.scss'

import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/Utils'
import Tooltip from '@mui/material/Tooltip';

interface Props {
  value: string,
  row:any,
  isFirstSeen?: boolean,
  isLastSeen?: boolean
}

const BacklinkDate: React.FC<Props> = ( {value, row, isFirstSeen, isLastSeen} : Props ) => {
  const { t } = useTranslation();

  let className = undefined
  if(row.is_new==true && isFirstSeen){
    className='is_new'
  } else if(row.is_lost==true && isLastSeen){
    className='is_lost'
  } else if(row.is_broken==true){
    className='is_broken'
  } else if(row.is_indirect_link==true) {
    className='is_indirect_link'
  }

  return (
    <>
      {className!==undefined ? (
        <Tooltip arrow followCursor title={t(`tooltip.${className}`)} >
          <div className={className}>{formatDate(value)}</div>
        </Tooltip>
        ) : (
        <div className={className}>{formatDate(value)}</div>
      )
      }
    </>
  )
};

export default BacklinkDate;

