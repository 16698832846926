import './PageSpeedInsights.scss'

import { useTranslation } from 'react-i18next';
import pageSpeedInsightsModel from './PageSpeedInsightsModel';
import pageSpeedInsightsController from './PageSpeedInsightsController';
import { Alert, Box, Collapse, IconButton, LinearProgress, Paper, Tooltip, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import ReportCard from './ReportCard';
import Opportunities from './Opportunities';
import DiagnosticsCard from './DiagnosticsCard';

const PageSpeedInsights: FC<{url:string}>  = ({url}) => {
  const { t } = useTranslation();

  const model = pageSpeedInsightsModel()
  const controller = pageSpeedInsightsController(model, url)

  return (
    <Paper className={'pageSpeedInsights'} elevation={3}>
      {model.loading && ( <LinearProgress variant="determinate" value={model.progress} className={'loader'} /> )}

      <Box className={'header'} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" className={'headerTitle'}>{t('cwv')}</Typography>
        <Box>

          <Tooltip title={t('tooltip.recalculate')} hidden={!model.loadedFirstTime}>
            <span><
                IconButton onClick={() => controller.fetchPageSpeedData(url)} disabled={!model.loadedFirstTime || model.loading} >
                  <ReplayIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title={model.open ? t('hide') : t('show')}>
            <IconButton onClick={() => model.setOpen(!model.open)} disabled={model.loading} className={'iconButton'} >
              {model.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography variant="body2" className={'iconButtonText'}>{model.open ? t('hide') : t('show')}</Typography>
            </IconButton>
          </Tooltip>

        </Box>
      </Box>
      {model.error && <Alert severity="error">{model.error}</Alert>}

      <Collapse in={model.open}>
        <Grid container spacing={4}>

          <Grid size={{ xs: 12, sm: 6 }} alignContent={'center'}>
            <Typography variant="h6" className={'title'} align="center">Mobile</Typography>
            <ReportCard data={model.data.mobile} />
          </Grid>

          <Grid size={{ xs: 12, sm: 6 }}>
            <Typography variant="h6" className={'title'} align="center" >Desktop</Typography>
            <ReportCard data={model.data.desktop} />
          </Grid>

        </Grid>


        <Grid container spacing={4} alignItems="flex-start" style={{ marginTop: '40px' }} >
          <Grid size={{ xs: 12, sm: 6 }} alignContent={'center'}>
            <Opportunities opportunities={model.opportunities} />
          </Grid>

          <Grid size={{ xs: 12, sm: 6 }} alignContent={'center'}>
            <DiagnosticsCard model={model} />
          </Grid>
        </Grid>

      </Collapse>


    </Paper>
  )

}

export default PageSpeedInsights;