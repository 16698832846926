import { Tooltip } from '@mui/material';
import { FC, ReactElement } from 'react';

interface Props {
  content?: string;
  children: ReactElement<any, any>;
}

const CustomTooltip: FC<Props> = ({ content, children }) => (
  <Tooltip arrow followCursor title={<div>{content}</div>} sx={{ maxWidth: 'max-content' }}>
    {children}
  </Tooltip>
);

export default CustomTooltip;
