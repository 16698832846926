import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Collapse, ListItemText } from '@mui/material';
import { classNames } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';
import { ROUTES_DOMAINS, ROUTES_KEYWORDS, ROUTES_PROJECTS, ROUTES_NICHE_IDEAS } from '../../utils/routes';
import { SubRoute } from './SubRouteMain';

export function Sidebar() {
  const { t } = useTranslation();
  const location = useLocation();
  const { open, mode } = useColorModeContext();

  const exactRoute = (path) => location.pathname.includes(path);

  // TOOLTIPS TEMPORALES EN SIDEBAR SE DEBE TRASLADAR AL JSON DE TRASLATION
  const tooltips = {
    projects: {
      manage: t('tooltip.planItem.projectAdmin'),
      performance: t('tooltip.planItem.projectPerformance'),
      cannibalization: t('tooltip.planItem.cannibalization'),
      'thin-content': t('tooltip.planItem.thinContent'),
      'opportunity-keywords': t('tooltip.planItem.opportunityKeywords'),
    },
    keywords: {
      explorer: t('tooltip.planItem.keywords'),
      ideas: t('tooltip.planItem.keywordSugestion'),
      'batch-analysis': t('tooltip.planItem.keywordsBatch'),
    },
    domains: {
      view: t('tooltip.planItem.domain'),
      'bulk-analysis': t('tooltip.planItem.domainBatch'),
      positions: t('tooltip.planItem.domainPosition'),
      competitors: t('tooltip.planItem.domainCompetitors'),
      backlinks: t('tooltip.planItem.backlinks'),
      'content-gap': t('tooltip.planItem.contentGap'),
    },
    nicheIdeas: {
      'niche-keywords': t('tooltip.planItem.keywordIdeas'),
      'niche-domains': t('tooltip.planItem.domainIdeas'),
    },
  };

  const getTooltipContent = (category, itemName) => {
    const baseItemName = itemName?.split('.').pop();
    return tooltips[category]?.[baseItemName] || '';
  };

  return (
    <div className={classNames('sidebar', open ? '' : 'sidebar-closed', mode)}>
      <List className="ListSidebar">
        <ListItem>
          <ListItemText primary={t('routes.projects')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_PROJECTS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('projects', item.name)}
                selected={exactRoute(item.path)}
              />
            ))}
          </List>
        </Collapse>

        <ListItem>
          <ListItemText primary={t('routes.keywords')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_KEYWORDS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('keywords', item.name)}
                selected={exactRoute(item.path)}
              />
            ))}
          </List>
        </Collapse>

        <ListItem>
          <ListItemText primary={t('routes.domain')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_DOMAINS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('domains', item.name)}
                selected={exactRoute(item.path)}
              />
            ))}
          </List>
        </Collapse>

        <ListItem>
          <ListItemText primary={t('routes.niche-ideas')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_NICHE_IDEAS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('nicheIdeas', item.name)}
                selected={exactRoute(item.path)}
              />
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );
}