import { getBaseUrl } from '../utils/Utils';
import { AccessToken } from '../types';
import { BillingsResponse, BuyPlanRequest } from '../types/common.type';
import BaseService from './BaseService';
import {
  SERVICE_USERS_CREATE_CHECKOUT,
  SERVICE_USERS_GET_INVOICES,
  SERVICE_USERS_REDIRECT_STRIPE,
  SERVICE_USERS_RETRIEVE_CHECKOUT
} from './Endpoint';

class UserService extends BaseService {
  async getToRedirectStripe({ accessToken }: AccessToken): Promise<string> {
    const url = `${getBaseUrl()}${SERVICE_USERS_REDIRECT_STRIPE}`;
    const response = await this.post(url, {}, accessToken);
    return response.data as string;
  }

  async redirectToCheckoutStripe({ plan, billing, coupon, trial, accessToken }: BuyPlanRequest): Promise<string> {
    const url = `${getBaseUrl()}${SERVICE_USERS_CREATE_CHECKOUT}`;
    const response = await this.post(url, { plan, billing, coupon, trial }, accessToken);
    return response.data as string;
  }

  async getInvoices({ accessToken }: AccessToken): Promise<Array<BillingsResponse>> {
    const url = `${getBaseUrl()}${SERVICE_USERS_GET_INVOICES}`;
    const response = await this.post(url, {}, accessToken);
    return response.data;
  }

  async retrieveCheckout({ accessToken }: AccessToken, sessionId: string): Promise<any> {
    const url = `${getBaseUrl()}${SERVICE_USERS_RETRIEVE_CHECKOUT}`;
    const response = await this.post(url, { sessionId: sessionId }, accessToken);
    return response.data;
  }
}

export const userService = new UserService();
