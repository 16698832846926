import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDomainsData } from '../../hooks/useDomainsData';
import * as CONSTANTS from '../../utils/Constants';
import { COLUMNS_DOMAIN_CONTENT_GAP } from '../../utils/TableColumnsConstants';

import { Grid, Typography } from '@mui/material';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import './Comparator.scss';
import { useSearchCache } from '../../context/SearchCacheContext';
import Search from '../../components/Search';

type FilterModeType = (typeof CONSTANTS.ALL_FILTER_MODE_CONTENT_GAP)[number];

export const Comparator = () => {

  const { t } = useTranslation();

  const context = 'contentGapContext';
  const { updateSearchCache, getSearchCache, getLocation, updateLocation } = useSearchCache();
  const [domains, setDomains] = useState<string[]>(['']);
  const [location, setLocation] = useState<number>(CONSTANTS.LOCATION_CODE_ES);
  const [filterMode, setFilterMode] = useState<FilterModeType>(() => {
    const savedFilterMode = getSearchCache(context + '_filterMode');
    return savedFilterMode ? JSON.parse(savedFilterMode) : CONSTANTS.ALL_FILTER_MODE_CONTENT_GAP[0];
  });
  const { loadingContentGap, fetchDomainContentGap, contentGapData } = useDomainsData('contentgap');

  const handleModeFilter = (value: FilterModeType) => {
    setFilterMode(value);
    updateSearchCache(context + '_filterMode', JSON.stringify(value));
  };


  /*
      Load initial Data
   */
  useEffect(() => {
    setDomains(getSearchCache(context).trim().split('\n'));
    const savedLocation = getLocation(context);
    setLocation(savedLocation);
  }, []);


  const handleSearchBtn = useCallback(async (e) => {

    const evt = e ? e : getSearchCache(context);
    const domainList = evt.trim().split('\n');
    setDomains(domainList);

    let data = {
      location,
      domain: domainList
    };

    domainList.forEach((domain, index) => {
      updateSearchCache(context + `_domain_${index}`, domain);
    });

    updateLocation(context, location);
    await fetchDomainContentGap(data);

  }, [location, contentGapData, domains, filterMode, fetchDomainContentGap, updateSearchCache, updateLocation]);


  const columnVisibilityModel = {
    ...domains.reduce((acc, _, index) => {
      if (filterMode === 'unique') {
        acc[`domain_${index + 1}`] = filterMode !== 'unique';
        return acc;
      } else if (filterMode === 'missing') {
        acc[`domain_0`] = false;
        return acc;
      }
    }, {})
  };

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="flex w-full gap-2 flex-column boxShadow" sx={{ p: 3 }}>
          <div className="flex-col w-full gap-2">
            <Search
              cacheKey={context}
              placeholder={t('search_placeholder_contentGap')}
              loading={loadingContentGap}
              onSearch={(e) => handleSearchBtn(e)}
              onCountry={setLocation}
              lines={4}
              maxLines={4}
            />
          </div>
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Typography className="mb-5">{t('routes.content-gap')}</Typography>
          <TableComponent
            rows={domains.every(item => item.trim() !== '')? contentGapData[filterMode]: undefined}
            loading={loadingContentGap}
            columns={COLUMNS_DOMAIN_CONTENT_GAP(t, {
              code: location,
              domains: domains
            })}
            columnVisibilityModel={columnVisibilityModel}
            paginationMode="client"
            sortingMode="client"
            slots={{
              toolbar: () => (
                <div className="py-2">
                  <ButtonGroup
                    current={filterMode}
                    onChange={handleModeFilter}
                    suggestions={CONSTANTS.ALL_FILTER_MODE_CONTENT_GAP}
                  />
                </div>
              )
            }}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};