import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import StraightIcon from '@mui/icons-material/Straight';
import Cost from '../Cost';

interface Props {
  low_top?: number;
  high_top?: number;
}

const KeywordPositionLevel = ({ low_top, high_top }: Props) => {
  const { t } = useTranslation();

  const tooltipHigh = t('all_keywords')
  return (
    <div className="level-position flex gap-1">
      <Tooltip arrow followCursor title={<div><Cost value={high_top}/>: {t('tooltip.keyword_info.cpc.top.high')}</div>}>
        <div className="flex items-center">
          <Typography><Cost value={high_top} /></Typography>
          <StraightIcon data-testid="up-icon" />
        </div>
      </Tooltip>

      <Tooltip arrow followCursor title={<div><Cost value={low_top}/>: {t('tooltip.keyword_info.cpc.top.low')}</div>}>
        <div className="flex items-center">
          <Typography><Cost value={low_top} /></Typography>
          <SouthIcon data-testid="down-icon" />
        </div>
      </Tooltip>


    </div>

/*
  <Tooltip
    arrow
      followCursor
      title={
        <div>
          <div>High top: {high_top}</div>
          <div>Low top: {low_top}</div>
        </div>
      }>
      <div className="level-position flex gap-1">
        <div className="flex items-center">
          <Typography><Cost value={high_top} /></Typography>
          <StraightIcon data-testid="up-icon" />
        </div>
        <div className="flex items-center">
          <Typography><Cost value={low_top} /></Typography>
          <SouthIcon data-testid="down-icon" />
        </div>
      </div>
    </Tooltip>

     */


  );
};

export default KeywordPositionLevel;
