import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputBase, Typography } from '@mui/material';
import { useSearchCache } from '../../context/SearchCacheContext';
import { KEY_ENTER_NAME } from '../../utils/Constants';

interface Props {
  pin?: string;
  type?: string;
  label?: string;
  pinText?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onSearch?: () => void;
  onChange?: (value: string) => void;
  style?: React.CSSProperties | undefined;
  pinStyle?: React.CSSProperties | undefined;
  cacheKey?: string;
}

const SearchSimple: React.FC<Props> = ({
  pin,
  type,
  label,
  style,
  pinText,
  onChange,
  onSearch,
  required,
  pinStyle,
  placeholder,
  defaultValue,
  cacheKey = 'default',
}) => {
  const { t } = useTranslation();
  const { getSearchCache, updateSearchCache } = useSearchCache();
  const context = 'searchSimple';
  const initialCacheValue = getSearchCache(`${context}_${cacheKey}`) || defaultValue || '';
  
  const [inputValue, setInputValue] = useState<string>(initialCacheValue);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!isInitialized) {
      const cachedValue = getSearchCache(`${context}_${cacheKey}`);
      if (cachedValue && cachedValue !== inputValue) {
        setInputValue(cachedValue);
        onChange?.(cachedValue);
      }
      setIsInitialized(true);
    }
  }, [isInitialized, context, cacheKey, getSearchCache, inputValue, onChange]);

  const handleOnChange = (value: string) => {
    const sanitizedValue = value.replace(/,/g, '');
    setInputValue(sanitizedValue);
    updateSearchCache(`${context}_${cacheKey}`, sanitizedValue);
    onChange?.(sanitizedValue);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEY_ENTER_NAME) {
      onSearch?.();
    }
  };

  return (
    <div className="flex flex-col flex-1">
      {label && <Typography>{label}</Typography>}
      <div className="search-simple" style={style}>
        {pin && (
          <span className="input-pin" style={{ backgroundColor: pin, ...pinStyle }}>
            {pinText}
          </span>
        )}
        <InputBase
          type={type ?? 'text'}
          required={required}
          value={inputValue}
          onKeyUp={handleKeyUp}
          sx={{ ml: 1, flex: 1, width: '100%' }}
          placeholder={placeholder ?? t('button.search')}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default React.memo(SearchSimple);