import React from 'react';
import { useTranslation } from 'react-i18next';

import { useKeywordsFiltersContext } from '../../../context/KeywordsFilterContext';
import {
  enhancedValuesSplitNumber,
  urlCountOptions,
  trafficCostOptions,
  trafficOptions,
  keywordsCountOptions
} from '../../../utils/Utils';

import CustomRange from '../../CustomRange';
import MenuSelector from '../../MenuSelector';

const DomainFilters = () => {
  const { t } = useTranslation();
  const {
    urlMin,
    urlMax,
    trafficCostMin,
    trafficCostMax,
    trafficMin,
    trafficMax,
    keywordsCountMin,
    keywordsCountMax,
    hasUrlMin,
    hasTrafficCostMin,
    hasTrafficMin,
    hasKeywordsCountMin,
    handleUrlMin,
    handleTrafficCostMin,
    handleTrafficMin,
    handleKeywordsCountMin
  } = useKeywordsFiltersContext();

  const getSelectedLabel = (min, max, options, defaultLabel) => {
    if (min !== 0 || max !== 0) {
      return `${min}-${max}`;
    }
    const option = options.find(option => {
      const { from, to } = enhancedValuesSplitNumber(option.label);
      return min >= from && max <= (to === Infinity ? max : to);
    });
    return option ? option.label : defaultLabel;
  };

  const defaultValueUrl = getSelectedLabel(urlMin, urlMax, urlCountOptions, t('URLS'));
  const defaultValueTrafficCost = getSelectedLabel(trafficCostMin, trafficCostMax, trafficCostOptions, t('TrafficCost'));
  const defaultValueTraffic = getSelectedLabel(trafficMin, trafficMax, trafficOptions, t('Traffic'));
  const defaultValueKeywordsCount = getSelectedLabel(keywordsCountMin, keywordsCountMax, keywordsCountOptions, t('KeywordsCount'));

  return (
    <React.Fragment>
      <MenuSelector
        value={{ from: urlMin || 0, to: urlMax || 0 }}
        isSelected={hasUrlMin}
        defaultValue={defaultValueUrl}
        suggestion={urlCountOptions}
        onChange={(values) => handleUrlMin(enhancedValuesSplitNumber(values.label))}
      >
        <CustomRange
          toValues={urlMax || 0}
          fromValues={urlMin || 0}
          onClick={(values) => handleUrlMin(values)}
        />
      </MenuSelector>

      <MenuSelector
        value={{ from: trafficCostMin || 0, to: trafficCostMax || 0 }}
        isSelected={hasTrafficCostMin}
        defaultValue={defaultValueTrafficCost}
        suggestion={trafficCostOptions}
        onChange={(values) => handleTrafficCostMin(enhancedValuesSplitNumber(values.label))}
      >
        <CustomRange
          toValues={trafficCostMax || 0}
          fromValues={trafficCostMin || 0}
          onClick={(values) => handleTrafficCostMin(values)}
        />
      </MenuSelector>

      <MenuSelector
        value={{ from: trafficMin || 0, to: trafficMax || 0 }}
        isSelected={hasTrafficMin}
        defaultValue={defaultValueTraffic}
        suggestion={trafficOptions}
        onChange={(values) => handleTrafficMin(enhancedValuesSplitNumber(values.label))}
      >
        <CustomRange
          toValues={trafficMax || 0}
          fromValues={trafficMin || 0}
          onClick={(values) => handleTrafficMin(values)}
        />
      </MenuSelector>

      <MenuSelector
        value={{ from: keywordsCountMin || 0, to: keywordsCountMax || 0 }}
        isSelected={hasKeywordsCountMin}
        defaultValue={defaultValueKeywordsCount}
        suggestion={keywordsCountOptions}
        onChange={(values) => handleKeywordsCountMin(enhancedValuesSplitNumber(values.label))}
      >
        <CustomRange
          toValues={keywordsCountMax || 0}
          fromValues={keywordsCountMin || 0}
          onClick={(values) => handleKeywordsCountMin(values)}
        />
      </MenuSelector>
    </React.Fragment>
  );
};

export default DomainFilters;