import React, { useState } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import * as CONSTANTS from '../utils/Constants';

export default function usePaginationModel(initialPagination?: GridPaginationModel) {

  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: CONSTANTS.PAGE_SIZE
  });

  const handlePaginationChange = (newPaginationModel: GridPaginationModel) =>
    setPaginationModel(newPaginationModel);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const paginatedMemoized = React.useMemo(() => {

    const data: any = {
      limit: paginationModel.pageSize,
      from: paginationModel.page * paginationModel.pageSize
    };

    if (sortModel.length > 0) {
      data.field = sortModel[0].field;
      data.sort = sortModel[0].sort;
    }

    return data;
  }, [sortModel, paginationModel]);

  return {
    sortModel,
    paginationModel,
    paginatedMemoized,
    handleSortModelChange,
    handlePaginationChange
  };
}