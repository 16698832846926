import './BubbleChart.scss';
import { useTranslation } from 'react-i18next';

import { Key } from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import { Skeleton } from '@mui/material';
import { formatCurrencyByLocation, formatNumber, generateUuid } from '../../utils/Utils';

const MIN_LENGTH = 12;

const BubbleChart = ({ data, columns, loading }) => {
  const { t } = useTranslation();

  if (data === undefined || data?.length === 0) {
    return (
      <div className="container-chart" style={{ minHeight: '381px' }}>
        <div className="skeleton" style={{ minHeight: '100%' }}>
          {Array.from({ length: MIN_LENGTH }).map(() => (
            <Skeleton
              key={generateUuid()}
              height={400}
              className="flex"
              variant="rectangular"
              width={`${100 / MIN_LENGTH}%`}
              animation={loading ? 'pulse' : false}
              style={{ alignSelf: 'flex-end', overflow: 'hidden' }}
            />
          ))}
        </div>
      </div>
    );
  }

  const groupedData = data.reduce((acc, curr) => {
    if (!acc[curr.domain]) {
      acc[curr.domain] = [];
    }
    acc[curr.domain].push(curr);
    return acc;
  }, {});

  const scatterData = Object.keys(groupedData).map((domain, index) => ({
    name: domain,
    data: groupedData[domain],
    fill: columns.colors[index % columns.colors.length]
  }));

  const getItemValue = (data, dataKey) => {

    if (dataKey === 'traffic' || dataKey === 'total_keywords') {
      return formatNumber(data[dataKey]);
    } else if (dataKey === 'traffic_cost') {
      return formatCurrencyByLocation(data[dataKey]);
    } else if (dataKey === 'domain') {
      return <span className={'domain'}>{data[dataKey]}</span>;
    } else {
      return data[dataKey];
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="tooltip container">
          {columns.tooltip.map((item: { title: Key | null | undefined; dataKey: number }) => (
            <div key={item.dataKey} className={'inner'}>
              <div className={'left'}>{t(item.dataKey.toString())}:</div>
              <div className={'right'}>{getItemValue(data, item.dataKey)}</div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={391}>
      <ScatterChart width={500} height={391}>
        <CartesianGrid />
        <XAxis type="number" dataKey={columns.columns.find((x) => (x.axe = 'x')).name} tickFormatter={(value) => {
          return formatNumber(value).toString();
        }} />
        <YAxis type="number" dataKey={columns.columns.find((y) => (y.axe = 'y')).name} tickFormatter={(value) => {
          return formatNumber(value).toString();
        }} />
        <ZAxis
          type="number"
          range={[1000, 10000]}
          dataKey={columns.columns.find((x) => (x.axe = 'x')).name}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomTooltip active={undefined} payload={undefined} />}
        />
        <Legend />
        {scatterData.map((group) => (
          <Scatter key={group.name} name={group.name} data={group.data} fill={group.fill} />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default BubbleChart;
