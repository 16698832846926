import { ROUTES } from './Constants';
import { generateUuid } from './Utils';
import {
  PLAN_BETA,
  PLAN_EXPERT,
  PLAN_ROOKIE,
  PLAN_ADVANCED,
  PLAN_LIFETIME,
  PLAN_ROOKIE_PLUS
} from './SubscriptionPlans';

type RoutesListsType = ReadonlyArray<{
  key: string;
  name: string;
  path: string;
  plans: ReadonlyArray<string>;
}>;

export const ROUTES_PROJECTS: RoutesListsType = [
  {
    key: generateUuid(),
    name: 'routes.manage',
    path: ROUTES.PROJECTS_MANAGE,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.performance',
    path: ROUTES.PROJECTS_PERFORMANCE,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.cannibalization',
    path: ROUTES.PROJECTS_CANNIBALIZATION,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.thin-content',
    path: ROUTES.PROJECTS_CONTENT,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.opportunity-keywords',
    path: ROUTES.PROJECTS_OPPOTUNITY,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  }
] as const;

export const ROUTES_KEYWORDS: RoutesListsType = [
  {
    key: generateUuid(),
    name: 'routes.explorer',
    path: ROUTES.EXPLORE,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.ideas',
    path: ROUTES.IDEAS,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.batch-analysis',
    path: ROUTES.BATCH_ANALYSIS,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
] as const;

export const ROUTES_DOMAINS: RoutesListsType = [
  {
    key: generateUuid(),
    name: 'routes.view',
    path: ROUTES.DOMAIN_VIEW,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.bulk-analysis',
    path: ROUTES.DOMAIN_BULK_ANALYSIS,
    plans: [PLAN_LIFETIME, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.positions',
    path: ROUTES.DOMAIN_KEYWORDS,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.competitors',
    path: ROUTES.DOMAIN_COMPETITORS,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.backlinks',
    path: ROUTES.DOMAIN_BACKLINKS,
    plans: [PLAN_LIFETIME, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.content-gap',
    path: ROUTES.DOMAIN_COMPARATOR,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  }
] as const;

export const ROUTES_NICHE_IDEAS: RoutesListsType = [
  {
    key: generateUuid(),
    name: 'routes.niche-keywords',
    path: ROUTES.NICHE_KEYWORDS,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  },
  {
    key: generateUuid(),
    name: 'routes.niche-domains',
    path: ROUTES.NICHE_DOMAINS,
    plans: [PLAN_LIFETIME, PLAN_BETA, PLAN_ROOKIE, PLAN_ROOKIE_PLUS, PLAN_ADVANCED, PLAN_EXPERT]
  }
] as const;

export const ROUTES_PLANS_GUARD: Record<
  string,
  Array<{ path: string; plans: ReadonlyArray<string> }>
> = {
  [ROUTES.PROJECTS]: ROUTES_PROJECTS.map((route) => ({
    path: route.path,
    plans: route.plans
  })).flat(),
  [ROUTES.KEYWORDS]: ROUTES_KEYWORDS.map((route) => ({
    path: route.path,
    plans: route.plans
  })).flat(),
  [ROUTES.DOMAINS]: ROUTES_DOMAINS.map((route) => ({
    path: route.path,
    plans: route.plans
  })).flat(),
  [ROUTES.NICHE_KEYWORDS]: ROUTES_NICHE_IDEAS.map((route) => ({
    path: route.path,
    plans: route.plans
  })).flat()
};