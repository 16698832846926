import './PageSpeedInsights.scss'

import { Paper, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CodeIcon from '@mui/icons-material/Code';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import CssIcon from '@mui/icons-material/Css';
import { useTranslation } from 'react-i18next';

const DiagnosticsCard = ({ model }) => {
  const { t } = useTranslation();

  let diagnostics
  let data = {
    numRequests: 0,
    numFonts: 0,
    numScripts: 0,
    numStylesheets: 0,
    serverResponse: '',
    maxServerLatency: ''
  }

  if (model && model.diagnostics) {
    diagnostics = model.diagnostics[0];
    data = {
      numRequests: diagnostics.numRequests,
      numFonts: diagnostics.numFonts,
      numScripts: diagnostics.numScripts,
      numStylesheets: diagnostics.numStylesheets,
      serverResponse: `${model.serverResponseTime} ms`,
      maxServerLatency: `${diagnostics.maxServerLatency} ms`
    };
  }

  const iconMap = {
    numRequests: <FileDownloadIcon color="primary" />,
    numFonts: <FontDownloadIcon color="primary" />,
    numScripts: <CodeIcon color="primary" />,
    numStylesheets: <CssIcon color="primary" />,
    maxServerLatency: <HourglassTopIcon color="primary" />,
    serverResponse: <AccessTimeIcon color="primary" />
  };

  return (
    <Paper sx={{ padding: 2, marginTop: 2 }} className={'diagnostics'}>
      <Typography className={'headerTitle'} align="center">{t('metrics')}</Typography>
      {Object.entries(data).map(([key, value]) => (
        <Grid container key={key} alignItems="center" style={{  padding: '4px 0' }}>
          {iconMap[key]}<Typography className={'title'} style={{ marginLeft: '8px', textAlign: 'right' }}>{t(key)}</Typography>
          {model.diagnostics?(
            <Typography style={{ textAlign: 'left', marginLeft: '10px' }}>{value}</Typography>
          ):(
            <Skeleton variant="rectangular" width={60} height={20} style={{ marginLeft: '10px' }} />
          )}
        </Grid>
      ))}
    </Paper>

  );
};

export default DiagnosticsCard;