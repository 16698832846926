import { formatNumber, isIpad, isIphone, isMobile } from '../../../../utils/Utils';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

const ChartController = (model) => {

  // Find the max value of each metric for normalization
  const getMinMaxValues = (rawData) => rawData.reduce(
    (acc, data) => ({
      clicks: {
        min: Math.min(acc.clicks.min, data.clicks),
        max: Math.max(acc.clicks.max, data.clicks)
      },
      ctr: {
        min: Math.min(acc.ctr.min, data.ctr),
        max: Math.max(acc.ctr.max, data.ctr)
      },
      impressions: {
        min: Math.min(acc.impressions.min, data.impressions),
        max: Math.max(acc.impressions.max, data.impressions)
      },
      position: {
        min: Math.min(acc.position.min, data.position),
        max: Math.max(acc.position.max, data.position)
      },
    }),
    {
      clicks: { min: Infinity, max: -Infinity },
      ctr: { min: Infinity, max: -Infinity },
      impressions: { min: Infinity, max: -Infinity },
      position: { min: Infinity, max: -Infinity }
    }
  );

  const normalizedData = (rawData, minMaxValues) => rawData.map(data => ({
    name: data.date,
    clicks: ((data.clicks - minMaxValues.clicks.min) / (minMaxValues.clicks.max - minMaxValues.clicks.min)) * 100,
    ctr: ((data.ctr - minMaxValues.ctr.min) / (minMaxValues.ctr.max - minMaxValues.ctr.min)) * 100,
    impressions: ((data.impressions - minMaxValues.impressions.min) / (minMaxValues.impressions.max - minMaxValues.impressions.min)) * 100,
    position: ((data.position - minMaxValues.position.min) / (minMaxValues.position.max - minMaxValues.position.min)) * 100,

  }));


  const handleLegendClickLocal = (event: any) => {
    const { dataKey } = event;
    model.setLocalHiddenLines(prevHiddenLines => ({
      ...prevHiddenLines,
      [dataKey]: !prevHiddenLines[dataKey]
    }));
  };

  const toggleFavoriteArea = () => {
    model.setShowFavoritesInGeneral(!model.showFavoritesInGeneral);
  };

  const customXAxisTick = ({ x, y, payload, index }) => {
    // Only render tick if it’s not the first one
    if (index === 0) return null;
    return (
      <text x={x} y={y} textAnchor="end" fill="#666">
        {formatNumber(payload.value)}
      </text>
    );
  };


  return {
    getMinMaxValues,
    handleLegendClickLocal,
    toggleFavoriteArea,
    customXAxisTick
  }
}

export default ChartController