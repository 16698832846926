import './HistoryTrendChart.scss';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

interface Props {
  data: any;
  height?: number | `${number}`;
}

const HistoryTrendChart: React.FC<Props> = ({ data, height }) => {
  if ([null, undefined].includes(data)) return '—';

  return (
    <div className={'historyTrendChart'}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={200} height={50} data={data} margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
          <Area type="monotone" dataKey="volume" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );

};

export default HistoryTrendChart;
