
import { useTranslation } from 'react-i18next';
import * as CONSTANTS from '../../utils/TableColumnsConstants';
import { DomainSearchIntents } from '../../types/domainSearchIntents.type';

import { Grid, Skeleton } from '@mui/material';
import TableComponent from '../TableComponent';
import ProgressBarMultiple from './ProgressBarMultiple';
import { getPercentage } from '../../utils/Utils';

interface Props {
  loading: boolean
  data?: DomainSearchIntents
}

const showSkeleton = () => {
  return (
    <div className="container-chart" style={{ minHeight: '349px' }}>
      <div className="skeleton flex-col" style={{ minHeight: '100%' }}>
        <Skeleton
          width="100%"
          variant="text"
          sx={{ fontSize: '1.4rem' }}
          animation={'pulse'}
        />
        <Skeleton
          width="100%"
          height={400}
          variant="rectangular"
          animation={'pulse'}
        />
      </div>
    </div>
  )
}

const KeywordByIntents = ({ loading, data }: Props) => {
  const { t } = useTranslation();

  if(!data){
    showSkeleton()
    return
  }

  const totalKeywords = Object.values(data).reduce((acc, curr) => acc + curr, 0);
  const rows = Object.entries(data)
    .map(([key, value], index) => ({
      key: index,
      name: key,
      keywords: value,
      value,
      distribution: getPercentage(value, totalKeywords),
      className: key,
      search_intent_info: {
        main_intent: key
      }
    }))
    .flat();

  return (
    <Grid container spacing={0} height="100%" >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ProgressBarMultiple data={rows} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableComponent
          hideFooter
          rows={rows}
          loading={loading}
          columns={CONSTANTS.COLUMNS_DOMAIN_KEYWORD_INTENT(t)}
          sortingMode="client"
        />
      </Grid>
    </Grid>
  )

};

export default KeywordByIntents;
