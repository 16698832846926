import React from 'react';
import { formatNumber } from '../../utils/Utils'

interface Props {
  data: any,
  param: string
}

const getValue = (res, param) => {
  const value = res[param];

  if (typeof value === 'number') {
    return formatNumber(value);
  }

  return value
}


const Cannibalization = ({ data, param }: Props) => {

  if(!data || !param){
    return
  }

  return (
    <div>
      {
        data.results.map((res, index) => {
            return (
              <div key={index} className={param}>{getValue(res, param)}</div>
            )
        })
      }
    </div>
  )
}

export default Cannibalization;
