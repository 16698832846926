
// ICONS
import * as Muicon from '@mui/icons-material';
import * as Constants from './Constants';
import { FiltersProps } from '../interfaces';
import { VITE_HOST } from '../lib/envVariables';
import { BreadcrumbsTypes } from '../types/common.type';
import { useMediaQuery } from '@mui/material';
import { GridFilterInputValue, GridFilterOperator } from '@mui/x-data-grid-premium';
import dayjs from "dayjs/dayjs.min"
import { FORMAT_DATE_DAYJS } from './Constants';

export const generateIcon = (variation: keyof typeof Muicon, props = {}) => {
  if (!variation) return null;

  const IconName = Muicon[variation];
  return <IconName {...props} />;
};


export const getNamePropertiesFromObjectArray = ({ data }: { data: any }) => {
  var objectPropertyNames = Object.create(null);
  data?.forEach(function(item: any) {
    Object.keys(item).forEach(function(keyItem) {
      objectPropertyNames[keyItem] = true;
    });
  });

  const propertyNames: any = Object.keys(objectPropertyNames);
  if (propertyNames[0] !== 'id') {
    propertyNames.unshift('id');
  }

  return propertyNames;
};


export const getBreadcrumbs = (): BreadcrumbsTypes => {
  const breadcrumbLinks: BreadcrumbsTypes = [
    {
      title: Constants.APP_NAME,
      href: '/',
      icon: 'Home'
    }
  ];

  let pathLevel: string = '';
  const pathLevels: Array<string> = window.location.pathname.split('/');
  pathLevels.shift();
  pathLevels.pop();

  const changeRoute: any = {
    niche: 'niche',
    keywords: 'ideas-nicho',
    domains: 'ideas-domain'
  };

  pathLevels.forEach((item, index) => {
    pathLevel += '/' + item;
    breadcrumbLinks.push({
      href: pathLevel,
      title: pathLevels[0] === 'niche' ? changeRoute[item] : item,
      path: index > 0 ? pathLevels[0] : undefined
    });
  });

  return breadcrumbLinks;
};

export const convertObjectDateVolumeToArrayList = (data: any) => {
  let list: Array<any> = [];
  const entries = Object.entries(data);
  for (let i = 0; i < entries.length; i++) {
    list.push({ date: Object.entries(data)[i][0], volume: Object.entries(data)[i][1] });
  }
  return list;
};

export const getBaseUrl = () => {
  const host = VITE_HOST;
  if (host !== undefined) {
    return host;
  }

  let url: string = 'https://dev.rankerfy.com';
  if (location.hostname === 'localhost') {
    url = 'https://localhost';
  }
  return url;
};

export const getRedirectUrl = () => {
  const host = VITE_HOST;
  if (host !== undefined) {
    return host;
  }

  let url: string = 'https://dev.rankerfy.com';
  if (location.hostname === 'localhost') {
    url = 'https://localhost';
  }
  return url;
};

export const changedTheme = (mode, theme1, theme2) =>
  mode === Constants.COLOR_MODE_DARK ? theme1 : theme2;

export const serpIcons = {
  people_also_ask: generateIcon('QuestionAnswer'),
  video: generateIcon('YouTube'),
  images: generateIcon('Photo'),
  twitter: generateIcon('X'),
  map: generateIcon('Place'),
  local_pack: generateIcon('Place'),
  google_hotels: generateIcon('Hotel'),
  hotels_pack: generateIcon('Hotel'),
  google_flights: generateIcon('FlightTakeoff'),
  recipes: generateIcon('Fastfood'),
  knowledge_graph: generateIcon('School'),
  top_stories: generateIcon('Newspaper'),
  related: generateIcon('FormatListBulleted'),
  related_searches: generateIcon('TrackChanges'),
  google_reviews: generateIcon('Star'),
  shopping: generateIcon('LocalOffer')
};

export const ModeSelectorTypeOptions = [
  {
    value: 'organic',
    label: 'organic'
  },
  {
    value: 'adv',
    label: 'adv'
  }
];

export const volumeOfSearchesOptions = [
  {
    value: 100000,
    label: '100.001+'
  },
  {
    value: 100000,
    label: '10.001-100.000'
  },
  {
    value: 10000,
    label: '1.001-10.000'
  },
  {
    value: 1000,
    label: '101-1.000'
  },
  {
    value: 100,
    label: '11-100'
  },
  {
    value: 10,
    label: '1-10'
  }
];

export const differenceOfSearchesOptions = [
  {
    value: 20,
    label: '0-20'
  },
  {
    value: 40,
    label: '21-40'
  },
  {
    value: 60,
    label: '41-60'
  },
  {
    value: 80,
    label: '61-80'
  },
  {
    value: 100,
    label: '81-100'
  }
];

export const intentOfSearchesOptions = [
  {
    value: 'informational',
    label: 'informational'
  },
  {
    value: 'commercial',
    label: 'commercial'
  },
  {
    value: 'navigational',
    label: 'navigational'
  },
  {
    value: 'transactional',
    label: 'transactional'
  }
];

// Nuevas opciones específicas para las pantallas de bulk y ideas de Dominios
export const keywordsCountOptions = [
  {
    value: 1000,
    label: '1.001+'
  },
  {
    value: 100,
    label: '101-1.000'
  },
  {
    value: 10,
    label: '11-100'
  },
  {
    value: 1,
    label: '1-10'
  }
];

export const urlCountOptions = [
  {
    value: 100,
    label: '100+'
  },
  {
    value: 50,
    label: '51-100'
  },
  {
    value: 10,
    label: '11-50'
  },
  {
    value: 1,
    label: '1-10'
  }
];

export const trafficCostOptions = [
  {
    value: 10000,
    label: '10.001+'
  },
  {
    value: 1000,
    label: '1.001-10.000'
  },
  {
    value: 100,
    label: '101-1.000'
  },
  {
    value: 10,
    label: '11-100'
  },
  {
    value: 1,
    label: '1-10'
  }
];

export const trafficOptions = [
  {
    value: 1000000,
    label: '1.000.000+'
  },
  {
    value: 100000,
    label: '100.001-1.000.000'
  },
  {
    value: 10000,
    label: '10.001-100.000'
  },
  {
    value: 1000,
    label: '1.001-10,000'
  },
  {
    value: 100,
    label: '101-1,000'
  },
  {
    value: 10,
    label: '1-100'
  }
];

export const enhancedValuesSplitNumber = (label) => {
  const cleanedLabel = label.replace(/\./g, '').replace(/,/g, '');
  const plusSignIndex = cleanedLabel.indexOf('+');
  const dashSignIndex = cleanedLabel.indexOf('-');

  if (plusSignIndex !== -1) {
    const from = parseInt(cleanedLabel.replace('+', ''), 10);
    return { from, to: Infinity };
  } else if (dashSignIndex !== -1) {
    const [from, to] = cleanedLabel.split('-').map((value) => parseInt(value, 10));
    return { from, to };
  }
  return { from: 0, to: 0 };
};

export const convertMonthNumberToText = (month: number) => {
  if (!isNaN(month) && month >= 1 && month <= 12) {
    return Constants.MONTHS[month - 1];
  }
};

export const isURL = (str: string) => {
  const pattern = /^(http(s?):\/\/)/;
  return pattern.test(str) ? str : `https://${str}`;
};

export const capitalizedFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const keywordDifficultyValues = (v: number, t) => {
  let htmlClass;
  let htmlTooltip: { title: string; description: string };

  if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_VERY_EASY) {
    htmlClass = Constants.KEYWORD_DIFFICULTY_CLASS_VERY_EASY;
    htmlTooltip = {
      title: t('difficultyLevel.veryEasy.title'),
      description: t('difficultyLevel.veryEasy.description')
    };
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_EASY) {
    htmlClass = Constants.KEYWORD_DIFFICULTY_CLASS_EASY;
    htmlTooltip = {
      title: t('difficultyLevel.easy.title'),
      description: t('difficultyLevel.easy.description')
    };
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_POSSIBLE) {
    htmlClass = Constants.KEYWORD_DIFFICULTY_CLASS_POSSIBLE;
    htmlTooltip = {
      title: t('difficultyLevel.possible.title'),
      description: t('difficultyLevel.possible.description')
    };
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_DIFFICULT) {
    htmlClass = Constants.KEYWORD_DIFFICULTY_CLASS_DIFFICULT;
    htmlTooltip = {
      title: t('difficultyLevel.difficult.title'),
      description: t('difficultyLevel.difficult.description')
    };
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_HARD) {
    htmlClass = Constants.KEYWORD_DIFFICULTY_CLASS_HARD;
    htmlTooltip = {
      title: t('difficultyLevel.hard.title'),
      description: t('difficultyLevel.hard.description')
    };
  } else if (v <= Constants.KEYWORD_DIFFICULTY_MAX_VALUE_VERY_HARD) {
    htmlClass = Constants.KEYWORD_DIFFICULTY_CLASS_VERY_HARD;
    htmlTooltip = {
      title: t('difficultyLevel.veryHard.title'),
      description: t('difficultyLevel.veryHard.description')
    };
  } else {
    htmlTooltip = {
      title: t('difficultyLevel.undefined.title'),
      description: t('difficultyLevel.undefined.description')
    };
  }
  return { htmlClass, htmlTooltip };
};

export const keywordDifficultyValuesProgress = (v: number, mode?: string) => {
  let htmlClass = '';

  if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_VERY_EASY) {
    htmlClass += Constants.KEYWORD_DIFFICULTY_CLASS_VERY_EASY;
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_EASY) {
    htmlClass += Constants.KEYWORD_DIFFICULTY_CLASS_EASY;
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_POSSIBLE) {
    htmlClass += Constants.KEYWORD_DIFFICULTY_CLASS_POSSIBLE;
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_DIFFICULT) {
    htmlClass += Constants.KEYWORD_DIFFICULTY_CLASS_DIFFICULT;
  } else if (v < Constants.KEYWORD_DIFFICULTY_MAX_VALUE_HARD) {
    htmlClass += Constants.KEYWORD_DIFFICULTY_CLASS_HARD;
  } else if (v <= Constants.KEYWORD_DIFFICULTY_MAX_VALUE_VERY_HARD) {
    htmlClass += Constants.KEYWORD_DIFFICULTY_CLASS_VERY_HARD;
  }
  return { htmlClass };
};

export const valuesSplitNumber = (label: string) => {
  const numberRegex = /(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)/g;

  if (label.search('-') > 0) {
    const from = Number(label.split('-')[0].replace('.', ''));
    const to = Number(label.split('-')[1].replace('.', ''));
    return { from, to };
  }
  const fromMatch = label.replace(',', '').replace('.', '').match(numberRegex);
  const from = fromMatch ? Number(fromMatch[0]) : 0;
  return { from, to: 0 };
};

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

export const generateUuid = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

export const validateHasDefaultValues = (value: FiltersProps) => value.from > 0 || value.to > 0;

export const validateDefaultValues = (value: FiltersProps) =>
  `${value.from !== 0 ? value.from : Constants.NUMBER_MIN}-${
    value.to !== 0 ? value.to : Constants.NUMBER_MAX
  }`;

export const urlCountryFlag = (iso: string) => `https://flagcdn.com/${iso?.toLowerCase()}.svg`;

export const checkIfUserHavePlan = ({ plans, plan }) => {
  const foundPlan = plans.find((item) => item === plan);
  return foundPlan !== undefined;
};

export const removeNumericValue = (value?: string): string[] => {
  if (!value) return [];
  const keywords = value.toLowerCase().split('\n').filter(Boolean) as string[];
  const removeNumeric = keywords.map((item, index) => {
    const remove = `${index + 1}. `;
    return item.replace(remove, '');
  });

  return removeNumeric;
};




export const filterContainsKeywordsOperators: GridFilterOperator<any, string>[] = [
  {
    value: 'above',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value || filterItem.value === 'all') {
        return null;
      }
      return (params) => {

        if (filterItem.value === 'included') {
          return typeof(params)==='object'
        } else if (filterItem.value === 'not_included') {
          return typeof(params)==='boolean' && params===false
        }
        return true;
      };
    },
    InputComponent: GridFilterInputValue,
  },
];


/**
 * returns if the device is mobile phone
 */
export const isIphone = () => {
  return useMediaQuery('(max-width: 767px)');
}

/**
 * returns if device is a tablet
 */
export const isIpad = () => {
  return useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
}

/**
 * return is mobile
 */
export const isMobile = () => {
  return isIphone() || isIpad()
}

/**
 * extract the clean domain
 * @param domain
 */
export const extractDomain = (domain:string) => {
  if(!domain){
    return ''
  }
  return new URL(domain).hostname || new URL(domain).pathname
}


/**
 * Returns the percentage value of the total
 * @param value
 * @param total
 */
export const getPercentage = (value:number, total: number) => {
  return formatNumber((value / total) * 100)
}

/**
 * returns the date in format YYYY-MM-DD
 * @param value
 */
export const formatDate = (value:string) => {
  return dayjs(value).format(FORMAT_DATE_DAYJS)
}

/**
 * Returns the value in formar mm/yy
 * @param date
 */
export const formatStringDate = (date: string) => {
  date = date.replace("-","").replace("/","")
  return date.length>=6?`${date.slice(4,6)}/${date.slice(2,4)}`:''
};


/**
 * Returns the value formatted including the currency of the country
 * @param params
 * @param op
 * @returns currency formatted
 */
export const formatCurrencyByLocation = (value: number, currency:string = 'USD') => {

  let suffix = ''
  let prefix = ''

  switch (currency) {

    case 'EUR': {
      suffix = '€'
      break
    }

    default: {
      prefix='$'
    }

  }

  return prefix+formatNumber(value)+suffix;
};


/**
 * Returns the number formateed in K,M,B,T units
 * @param number
 * @param decimals
 */
export const formatNumber = (number: number, decimals?: number) => {
  if (number===undefined) return 0
  else if (number < 1e3) return roundNumberWithDecimals(number, decimals)
  else if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + 'K'
  else if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + 'M'
  else if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + 'B'
  else if (number >= 1e12) return +(number / 1e12).toFixed(1) + 'T'
};

/**
 * Returns the number formatted round up with the quantity of decimals provided in parameter
 * @param number
 * @param decimals
 * @returns number
 */
export const roundNumberWithDecimals = (number:number, decimals:number = 2) => {
  return parseFloat((Math.ceil(number * (Math.pow(10, decimals))) / (Math.pow(10, decimals))).toFixed(decimals));
}