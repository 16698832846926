import React from 'react';
import { useTranslation } from 'react-i18next';

import { FiltersProps } from '../interfaces';
import { DEFAULT_VALUES_FILTER } from '../utils/Constants';
import { validateDefaultValues, validateHasDefaultValues } from '../utils/Utils';

export default function useProjectsFilters() {
  const [effectFilter, setEffectFilter] = React.useState<number>(0);
  const [ctrOfSearches, setCtrOfSearches] = React.useState<FiltersProps>(DEFAULT_VALUES_FILTER);
  const [clicksOfSearches, setClicksOfSearches] =
    React.useState<FiltersProps>(DEFAULT_VALUES_FILTER);
  const [positionOfSearches, setPositionOfSearches] =
    React.useState<FiltersProps>(DEFAULT_VALUES_FILTER);
  const [impressionsOfSearches, setImpressionsOfSearches] =
    React.useState<FiltersProps>(DEFAULT_VALUES_FILTER);

  const { t } = useTranslation();

  const handleCtrOfSearches = (value: FiltersProps) => {
    setEffectFilter((prev) => ++prev);
    setCtrOfSearches(value);
  };
  const handleClicksOfSearches = (value: FiltersProps) => {
    setEffectFilter((prev) => ++prev);
    setClicksOfSearches(value);
  };
  const handlePositionOfSearches = (value: FiltersProps) => {
    setEffectFilter((prev) => ++prev);
    setPositionOfSearches(value);
  };
  const handleImpressionsOfSearches = (value: FiltersProps) => {
    setEffectFilter((prev) => ++prev);
    setImpressionsOfSearches(value);
  };

  const handleFiltersApply = React.useCallback(() => {
    const buildData: any = {};

    if (ctrOfSearches.to > 0) {
      buildData.ctrMax = ctrOfSearches.to;
    }
    if (ctrOfSearches.from > 0) {
      buildData.ctrMin = ctrOfSearches.from;
    }
    if (clicksOfSearches.to > 0) {
      buildData.clicksMax = clicksOfSearches.to;
    }
    if (clicksOfSearches.from > 0) {
      buildData.clicksMin = clicksOfSearches.from;
    }
    if (positionOfSearches.to > 0) {
      buildData.positionMax = positionOfSearches.to;
    }
    if (positionOfSearches.from > 0) {
      buildData.positionMin = positionOfSearches.from;
    }
    if (impressionsOfSearches.to > 0) {
      buildData.impressionsMax = impressionsOfSearches.to;
    }
    if (impressionsOfSearches.from > 0) {
      buildData.impressionsMin = impressionsOfSearches.from;
    }

    return buildData;
  }, [ctrOfSearches, clicksOfSearches, positionOfSearches, impressionsOfSearches]);

  const validateNumberPositions = validateDefaultValues(positionOfSearches);
  const hasPositionsOfSearches = validateHasDefaultValues(positionOfSearches);
  const defaultValuePositions = hasPositionsOfSearches ? validateNumberPositions : t('position');

  const validateNumberImpressions = validateDefaultValues(impressionsOfSearches);
  const hasImpressionsOfSearches = validateHasDefaultValues(impressionsOfSearches);
  const defaultValueImpression = hasImpressionsOfSearches
    ? validateNumberImpressions
    : t('impressions');

  const validateNumberClicks = validateDefaultValues(clicksOfSearches);
  const hasClicksOfSearches = validateHasDefaultValues(clicksOfSearches);
  const defaultValueClicks = hasClicksOfSearches ? validateNumberClicks : t('clicks');

  const validateNumberCtr = validateDefaultValues(ctrOfSearches);
  const hasCtrOfSearches = validateHasDefaultValues(ctrOfSearches);
  const defaultValueCtr = hasCtrOfSearches ? validateNumberCtr : t('ctr');

  return {
    effectFilter,
    ctrOfSearches,
    clicksOfSearches,
    positionOfSearches,
    impressionsOfSearches,

    // DEFAULT VALUE
    defaultValueCtr,
    defaultValueClicks,
    defaultValuePositions,
    defaultValueImpression,

    // STATES
    hasCtrOfSearches,
    hasClicksOfSearches,
    hasPositionsOfSearches,
    hasImpressionsOfSearches,

    // CALLBACKS
    handleFiltersApply,
    handleCtrOfSearches,
    handleClicksOfSearches,
    handlePositionOfSearches,
    handleImpressionsOfSearches
  };
}
