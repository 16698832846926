import React from 'react';
import { useTranslation } from 'react-i18next';
import AddchartIcon from '@mui/icons-material/Addchart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Grow,
  Paper,
  Popper,
  Button,
  MenuItem,
  MenuList,
  ButtonGroup,
  ClickAwayListener,
  CircularProgress,
  Tooltip
} from '@mui/material';

interface Props {
  loading?: boolean;
  onClickAccount: () => void;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function SplitButton(props: Readonly<Props>) {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ButtonGroup
        ref={anchorRef}
        variant="contained"
        sx={{ border: 'none' }}
        aria-label="Button group with a nested menu">
        <Tooltip title={t('tooltip.addAccount')}>
          <Button disabled={props.loading} className="btn-manage" onClick={props.onClickAccount}>
            <PersonAddIcon />
            {props.loading ? (
              <CircularProgress size={15} sx={{ bgcolor: 'transparent', color: 'white' }} />
            ) : (
              t('account')
            )}
          </Button>
        </Tooltip>
        <Tooltip title={t('tooltip.addProject')}>
          <Button disabled={props.loading} className="btn-manage" onClick={props.onClick}>
            <AddchartIcon />
            {props.loading ? (
              <CircularProgress size={15} sx={{ bgcolor: 'transparent', color: 'white' }} />
            ) : (
              t('project')
            )}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </React.Fragment>
  );
}
