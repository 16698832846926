import React from 'react';
import './styles.scss';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

type Props = Array<{
  key: number;
  name: string;
  className: string;
  value: number;
  distribution: number;
}>;

const ProgressBarMultiple = (props: { data: Props }) => {
  const { t } = useTranslation();

  return (
    <div className="progress-bar">
      {props.data.map((item) =>
        item.value > 0 ? (
          <Tooltip arrow followCursor title={<div>{t(`intent.${item.name}`)}</div>}>
          <span
            key={item.key}
            className={`progress-item ${item.className}`}
            style={{ width: item.distribution*4.4}}
          />
          </Tooltip>
        ) : null
      )}
    </div>
  );
};

export default ProgressBarMultiple;
