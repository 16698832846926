import './KeywordRedirectLink.scss';
import { Link } from 'react-router-dom';
import { classNames } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

interface Props {
  link: string
  path: string
  code: number
  showIcon?:boolean
}

const KeywordRedirectLink = ({ link, path, code, showIcon }: Props) => {
  const { mode } = useColorModeContext();

  if (link === undefined) {
    return;
  }

  const href = `${path}?q=${link}&db=${code}`;

  const showIconRedirect = () => {
    if(showIcon===true){
      return (
        <a target="_blank" href={`//${link}`} rel="noopener noreferrer"
           className={classNames('btn-link keywordLinkBlank', mode)}>
          <div>
            <span data-testid={link}></span>
            <LaunchRoundedIcon className="noColor" fontSize="small" />
          </div>
        </a>
      )
    }
  }

  return (
    <div className={'KeywordRedirectLink'}>
      <Link to={href} relative="path" replace className={classNames(mode)}>{link}</Link>
      {showIconRedirect()}
    </div>
  );
};

export default KeywordRedirectLink;
