import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { ROUTES } from '../../utils/Constants';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: { returnTo: window.location.href },
    });
  };

  return (
    <Button variant="contained" onClick={handleLogin} sx={{ mt: 2, borderRadius: '20px', padding: '8px 50px'}}>
      Empezar
    </Button>
  );
};

export default LoginButton;