import { useState, useCallback, useEffect } from 'react';
import { useAccessToken } from './useAccessToken';
import { projectsService } from '../services/ProjectsService';
import { TOKEN_PROJECT } from '../utils/Constants';
import {
  ProjectsResponse,
  ProjectsPropertiesResponse,
  ProjectsOpportunityResponse,
  ProjectsThinContentResponse,
  ProjectsCannibalizationResponse,
  ProjectsPerformanceResponse,
  ProjectsInfoResponse
} from '../types';
import { ProjectInterface } from '../interfaces/ProjectInterface';

interface ProjectsDataParamsWithFilter {
  ctrMax: number;
  ctrMin: number;
  clicksMax: number;
  clicksMin: number;
  positionMax: number;
  positionMin: number;
  impressionsMax: number;
  impressionsMin: number;
}

interface ProjectsDataParams extends Partial<ProjectsDataParamsWithFilter> {
  id?: string;
  url?: string;
  project?: ProjectInterface;
  property?: string;
  accountId?: string;
  startDate?: string;
  endDate?: string;
}

export const useProjectsData = () => {
  const [dataProject, setDataProject] = useState<ProjectsResponse>([]);
  const [dataKeyword, setDataKeyword] = useState<ProjectsOpportunityResponse>([]);
  const [dataThinContent, setDataThinContent] = useState<ProjectsThinContentResponse>([]);
  const [dataCannibalization, setDataCannibalization] = useState<ProjectsCannibalizationResponse>([]);
  const [dataProperties, setDataProperties] = useState<ProjectsPropertiesResponse[]>([]);
  const [dataPerformance, setDataPerformance] = useState<ProjectsPerformanceResponse>({ items: [] });
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingKeywords, setLoadingKeywords] = useState(false);
  const [loadingProperties, setLoadingProperties] = useState(false);
  const [loadingThinContent, setLoadingThinContent] = useState(false);
  const [loadingDeleteProject, setLoadingDeleteProject] = useState(false);
  const [loadingCreateProject, setLoadingCreateProject] = useState(false);
  const [loadingCannibalization, setLoadingCannibalization] = useState(false);
  const [loadingPerformance, setLoadingPerformance] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [dataProjectInfo, setDataProjectInfo] = useState<ProjectsInfoResponse | null>(null);
  const [loadingProjectInfo, setLoadingProjectInfo] = useState(false);
  const token = useAccessToken();
  
  const fetchKeywords = useCallback(async (filterParams: ProjectsDataParams) => {
    setLoadingKeywords(true);
    try {
        const data = await projectsService.getKeywords({ url: filterParams.url, accessToken: token, ...filterParams });
        setDataKeyword(data);
        return data;
    } catch (e) {
        setError(e);
    } finally {
        setLoadingKeywords(false);
    }
}, [token]);

  const fetchThinContent = useCallback(async (filterParams: ProjectsDataParams) => {
    setLoadingThinContent(true);
    try {
        const data = await projectsService.getThinContent({ url: filterParams.url, accessToken: token, ...filterParams });
        setDataThinContent(data);
        return data;
    } catch (e) {
        setError(e);
    } finally {
        setLoadingThinContent(false);
    }
}, [token]);

const fetchCannibalization = useCallback(async (filterParams: ProjectsDataParams) => {
  setLoadingCannibalization(true);
  try {
      const data = await projectsService.getCannibalization({ url: filterParams.url, accessToken: token, ...filterParams });
      setDataCannibalization(data);
      return data;
  } catch (e) {
      setError(e);
  } finally {
      setLoadingCannibalization(false);
  }
}, [token]);

  const fetchProjects = useCallback(async () => {
    setLoadingProjects(true);
    try {
      const data = await projectsService.getProjects({ accessToken: token });
      setDataProject(data);
      sessionStorage.setItem(TOKEN_PROJECT, JSON.stringify(data));
    } catch (e) {
      setError(e);
      setDataProject([]);
      sessionStorage.removeItem(TOKEN_PROJECT);
    } finally {
      setLoadingProjects(false);
    }
  }, [token]);

  const fetchDeleteProjects = useCallback(async (id: string) => {
    setLoadingDeleteProject(true);
    try {
      const data = await projectsService.deleteProjects({ id, accessToken: token });
      return data === 'project_deleted_successfully';
    } catch (e) {
      setError(e);
    } finally {
      setLoadingDeleteProject(false);
    }
  }, [token]);

  const fetchCreateProject = useCallback(async (filterParams: ProjectsDataParams) => {
    setLoadingCreateProject(true);
    try {
      const data = await projectsService.createProject({ id: filterParams.id, property: filterParams.property, accessToken: token });
      return data === 'project_created_successfully';
    } catch (e) {
      setError(e);
    } finally {
      setLoadingCreateProject(false);
    }
  }, [token]);

  const fetchGetProperties = useCallback(async (filterParams: ProjectsDataParams) => {
    setLoadingProperties(true);
    try {
      const data = await projectsService.getProperties({ accessToken: token, id: filterParams.accountId });
      setDataProperties(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoadingProperties(false);
    }
  }, [token]);

  const fetchProjectsPerformance = useCallback(async (filterParams: ProjectsDataParams, updateState = true) => {
    setLoadingPerformance(true);
    try {
        const data = await projectsService.getProjectsPerformance({ url: filterParams.url, accessToken: token, ...filterParams });
        if (updateState) {
            setDataPerformance({ items: data.items });
        }
        return data;
    } catch (e) {
        if (updateState) {
            setError(e);
            setDataPerformance({ items: [] });
        }
        console.error(e);
        return null;
    } finally {
        setLoadingPerformance(false);
    }
  }, [token]);

  const fetchProjectInfo = useCallback(async (filterParams: ProjectsDataParams) => {
    setLoadingProjectInfo(true);
    try {
      const {project, startDate = '', endDate = '', ...restParams } = filterParams;
      const data = await projectsService.getProjectInfo({ "project": project.property, accessToken: token, startDate, endDate, ...restParams });
      setDataProjectInfo(data);
      return data;
    } catch (e) {
      setError(e);
    } finally {
      setLoadingProjectInfo(false);
    }
  }, [token]);

  useEffect(() => {
    const projects = sessionStorage.getItem(TOKEN_PROJECT);
    if (token !== '') {
      if (projects === null) fetchProjects();
      else setDataProject(JSON.parse(projects as string));
    }
  }, [token]);

  return {
    dataKeyword,
    dataProject,
    dataProperties,
    dataThinContent,
    dataCannibalization,
    dataPerformance,
    loadingProjects,
    loadingProperties,
    loadingKeywords,
    loadingCreateProject,
    loadingDeleteProject,
    loadingThinContent,
    loadingCannibalization,
    loadingPerformance,
    error,
    fetchKeywords,
    fetchProjects,
    fetchGetProperties,
    fetchCreateProject,
    fetchDeleteProjects,
    fetchThinContent,
    fetchCannibalization,
    fetchProjectsPerformance,
    dataProjectInfo,
    loadingProjectInfo,
    fetchProjectInfo
  };
};