import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Splash from '../Splash';

const CustomErrorBoundary = ({ error, resetErrorBoundary }) => {

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the /error page when an error occurs
    navigate('/error', { state: { error } });
  }, [error, navigate]);

  // While waiting show splash
  return (
    <Splash />
  )
}

export default CustomErrorBoundary;
