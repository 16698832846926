import { getBaseUrl, getRedirectUrl } from '../utils/Utils';
import { AccessToken } from '../types';
import BaseService from './BaseService';

import {
  SERVICE_USERS_GET_CONNECTED_ACCOUNTS,
  SERVICE_USERS_GOOGLE_REDIRECT_URI,
  SERVICE_USERS_DELETE_CONNECTED_ACCOUNT,
  SERVICE_USERS_GET_GOOGLE_TOKEN
} from './Endpoint';

class UserConnectionService extends BaseService {
  async getAll({ accessToken }: AccessToken): Promise<any> {
    const url = `${getBaseUrl()}${SERVICE_USERS_GET_CONNECTED_ACCOUNTS}`;
    const response = await this.post(url, {}, accessToken);
    return response.data as string;
  }

  async getAddAccountRedirectUrl(userId: string): Promise<string> {
    const redirectUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=575090919591-7snho08ckt9qbts5apffudk57t5bp895.apps.googleusercontent.com&redirect_uri=${getRedirectUrl()}${SERVICE_USERS_GOOGLE_REDIRECT_URI}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fwebmasters.readonly+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&state=${userId}&prompt=consent&access_type=offline&service=lso&o2v=1&theme=glif&flowName=GeneralOAuthFlow`;

    return redirectUrl;
  }

  async addAccountToDatabase({ accessToken }: AccessToken, code: string): Promise<any> {
    const url = `${getBaseUrl()}${SERVICE_USERS_GET_GOOGLE_TOKEN}`;
    const response = await this.post(url, { code: code }, accessToken);
    return response.data as string;
  }

  async deleteAccount({ accessToken }: AccessToken, account_id: string): Promise<any> {
    const url = `${getBaseUrl()}${SERVICE_USERS_DELETE_CONNECTED_ACCOUNT}`;
    const response = await this.post(url, { id: account_id }, accessToken);
    return response.data as string;
  }
}

export const userConnectionService = new UserConnectionService();
