import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import useExportData from '../../hooks/useExportData';
import { useDomainsData } from '../../hooks/useDomainsData';
import { useAccessToken } from '../../hooks/useAccessToken';
import * as CONSTANTS from '../../utils/Constants';
import * as TABLE_CONSTANTS from '../../utils/TableColumnsConstants';

import { Divider, Grid, Typography, Box } from '@mui/material';
import Search from '../../components/Search';
import PaperItem from '../../components/Essential/PaperItem';
import TableComponent from '../../components/TableComponent';
import ViewMoreButton from '../../components/ViewMoreButton';
import BubbleChart from '../../components/BubbleChart/BubbleChart';
import ButtonExportExcel from '../../components/Buttons/ExportExcel';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import DomainMetadata from '../../components/Domain/DomainMetadata/DomainMetadata';
import BarChartTop from '../../components/Recharts/BarChartTop/BarChartTop';
import KeywordByIntent from '../../components/KeywordByIntent/KeywordByIntents';
import { useSearchCache } from '../../context/SearchCacheContext';
import { formatNumber } from '../../utils/Utils';

const DOMAIN_VIEW_CACHE_KEY = 'domain_view_search';

export const DomainView = () => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const [searchParams] = useSearchParams();
  const { isLoading, fetchExportDomainOverview } = useExportData();
  const { getSearchCache, updateSearchCache, getLocation, updateLocation } = useSearchCache();
  const {
    keywords,
    domainsOverview,
    domainRankRanges,
    domainSearchIntents,
    competitors,
    loadingKeywords,
    loadingCompetitors,
    fetchDomainKeyword,
    fetchDomainOverview,
    loadingSearchIntents,
    fetchDomainRankRanges,
    fetchDomainCompetitors,
    fetchDomainWithQueries,
    fetchDomainSearchIntents
  } = useDomainsData(DOMAIN_VIEW_CACHE_KEY);

  const queryFromParams = searchParams.get('q') || getSearchCache(DOMAIN_VIEW_CACHE_KEY) || '';
  const locationFromParams = searchParams.get('db') || getLocation(DOMAIN_VIEW_CACHE_KEY) || '';

  const [query, setQuery] = useState(queryFromParams);
  const [location, setLocation] = useState(locationFromParams);

  const fetchData = async (query, location) => {
    const data = { location, domain: query };
    await Promise.allSettled([
      fetchDomainKeyword(data),
      fetchDomainOverview(data),
      fetchDomainRankRanges(data),
      fetchDomainCompetitors(data),
      fetchDomainSearchIntents(data)
    ]);
  };

  const handleSearchBtn = async (search) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('q', search);
    newSearchParams.set('db', location);
    window.history.pushState({}, '', `${window.location.pathname}?${newSearchParams.toString()}`);
    setQuery(search);
    updateSearchCache(DOMAIN_VIEW_CACHE_KEY, search);
    fetchData(search, location);
  };

  const handleLocationUpdate = (newLocation) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('q', query);
    newSearchParams.set('db', newLocation);
    window.history.pushState({}, '', `${window.location.pathname}?${newSearchParams.toString()}`);
    setLocation(newLocation);
    updateLocation(DOMAIN_VIEW_CACHE_KEY, newLocation);
    fetchData(query, newLocation);
  };

  const handleExportBtn = async () => {
    if (query.length === 0) return;

    const data = {
      domain: query,
      location,
      title: 'Vista General'
    };

    await fetchExportDomainOverview(data);
  };

  useEffect(() => {
    fetchDomainWithQueries();
  }, [token, searchParams, fetchDomainWithQueries]);

  const redirectToKeywords = `${CONSTANTS.ROUTES.DOMAIN_KEYWORDS}?q=${query}&db=${location}`;
  const redirectToCompetitor = `${CONSTANTS.ROUTES.DOMAIN_COMPETITORS}?q=${query}&db=${location}`;

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain')}
            loading={loadingKeywords}
            onSearch={handleSearchBtn}
            onCountry={handleLocationUpdate}
            cacheKey={DOMAIN_VIEW_CACHE_KEY}
          />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <DomainMetadata location={location} domain={query} data={domainsOverview} />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <PaperItem className="boxShadow" sx={{ p: 3, height: '100%' }}>
          <div className="flex-col gap-1">
            <CustomTooltip content={t('tooltip.main_competitors')}>
              <Typography fontWeight={700} variant="h5">
                {t('main_competitors')}
              </Typography>
            </CustomTooltip>
          </div>
          <div className={'spacer'}></div>
          <BubbleChart
            loading={loadingSearchIntents}
            data={competitors.slice(0, 10)}
            columns={CONSTANTS.DOMAIN_BUBLE_CHART_COLUMNS}
          />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <PaperItem className="boxShadow" sx={{ p: 3, height: '100%' }}>
          <div className="flex-col gap-1">
            <CustomTooltip content={t('tooltips.domains.overview_keywords_intent')}>
              <Typography fontWeight={700} variant="h5">
                {t('keyword_by_intent')}
              </Typography>
            </CustomTooltip>
            <div className={'spacer'}></div>
          </div>
          <KeywordByIntent data={domainSearchIntents} loading={loadingSearchIntents} />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <PaperItem className="boxShadow" sx={{ p: 3, height: '100%' }}>
          <div className="flex-col gap-1">
            <CustomTooltip content={t('tooltip.ranking_distribution')}>
              <Typography fontWeight={700} variant="h5">
                {t('ranking_distribution')}
              </Typography>
            </CustomTooltip>
            <div className={'spacer'}></div>
          </div>
          <BarChartTop loading={loadingSearchIntents} data={domainRankRanges} />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <div className="flex items-center justify-between">
            <CustomTooltip content={t('tooltip.organic_keywords')}>
              <Typography>{t('organic_keywords')}</Typography>
            </CustomTooltip>
            <Box
              sx={{
                width: 120,
                height: 36,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <ButtonExportExcel
                isLoading={isLoading}
                onClick={handleExportBtn}
                totalKeywords={keywords.totalKeywords}
                query={query}
              />
            </Box>
          </div>
          <TableComponent
            hideFooter
            rows={keywords.items}
            loading={loadingKeywords}
            getRowHeight={() => 'auto'}
            columns={TABLE_CONSTANTS.COLUMNS_DOMAIN_KEYWORDS(t, {
              code: location
            })}
            paginationMode="client"
            sortingMode="client"
            hideFooterPagination
          />
          {keywords.items.length > 0 && (
            <ViewMoreButton anchor={redirectToKeywords}>
              {`${t('view')} ${formatNumber(keywords?.totalKeywords)} ${t('more_keywords').toLocaleLowerCase()}`}
            </ViewMoreButton>
          )}
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <CustomTooltip content={t('tooltips.domains.overview_organic_competitor')}>
            <Typography className="mb-5">{t('organic_competitors')}</Typography>
          </CustomTooltip>
          <TableComponent
            hideFooter
            rows={competitors}
            getRowHeight={() => 'auto'}
            loading={loadingCompetitors}
            columns={TABLE_CONSTANTS.COLUMNS_DOMAIN_COMPETITORS_KEYWORDS(t, {
              code: location
            })}
            paginationMode="client"
            sortingMode="client"
            hideFooterPagination
          />
          {competitors.length > 0 && (
            <ViewMoreButton anchor={redirectToCompetitor}>{t('view_competitors')}</ViewMoreButton>
          )}
        </PaperItem>
      </Grid>
    </Grid>
  );
};