import React from 'react';
import { KEY_COMMAN_NAME, KEY_ENTER_NAME } from '../utils/Constants';

export default function useTextareaList(initialValue: string = "") {
  const [editorValue, setEditorValue] = React.useState<string>(initialValue);

  const handleEditableKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === KEY_ENTER_NAME || event.key === KEY_COMMAN_NAME) {
      event.preventDefault();
      let formatValues: string = '';
      const value = (event.target as HTMLInputElement).value;
      const listValues = value.split('\n');
      listValues.forEach((item, index) => {
        const lengthUp = `${index + 1}. `;
        formatValues += `${lengthUp}${item.replace(lengthUp, '')}\n`;
      });
      setEditorValue(formatValues);
    }
  };

  return [editorValue, setEditorValue, handleEditableKeyDown] as const;
}