import { PLAN_BILLING_MONTHLY, PLAN_BILLING_YEARLY, PLAN_DISCOUNT_ANUAL, SweetAlertIcon } from '../../utils/Constants';
import { useUsers } from '../../hooks/useUsers';
import { PlanInterface } from './PlansModel';
import Swal from 'sweetalert2';
import i18n from '../../i18n';

const PlansViewController = (model) => {

  const { error, loadingRedirectStripe, fetchRedirectToCheckoutStripe } = useUsers();

  const handleChangeBillingPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    model.setSelectedBilling(event.target.checked ? PLAN_BILLING_YEARLY : PLAN_BILLING_MONTHLY);
  };

  const calculatePrice = (planPrice: number) => {
    return model.selectedBilling === PLAN_BILLING_MONTHLY ? planPrice : planPrice - (planPrice * PLAN_DISCOUNT_ANUAL);
  };

  const redirectToStripeCheckout = (plan, billing, coupon) => {

   model.setSelectedPlan(plan);
    const planObject: PlanInterface = model.planList.find(item => item.name == plan);
    const data = {
      plan: plan,
      billing: billing,
      coupon: coupon,
      trial: planObject.isTrialEnabled
    };

    fetchRedirectToCheckoutStripe(data)
      .then((response)=>{
        if(response.status===404){
          Swal.fire({
            title: 'Oppss...',
            icon: SweetAlertIcon.ERROR,
            text: i18n.t('error.couponNorFound')
          });
        }
      })

  };

  return {
    loadingRedirectStripe,
    handleChangeBillingPeriod,
    calculatePrice,
    redirectToStripeCheckout
  };
};

export default PlansViewController;