import './OpportunityKeywords.scss'
import Grid from '@mui/material/Grid2';
import PaperItem from '../../../components/Essential/PaperItem';

import OpportunityKeywordsModel from './opportunityKeywordsModel';
import OpportunityKeywordsController from './opportunityKeywordsController';
import SelectorView from '../../../components/Projects/ProjectSelector/ProjectSelectorView';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Typography } from '@mui/material';
import MenuSelector from '../../../components/MenuSelector';
import * as CONSTANTS from '../../../utils/Constants';
import { valuesSplitNumber, volumeOfSearchesOptions } from '../../../utils/Utils';
import CustomRange from '../../../components/CustomRange';
import DateRangeSelectorView from '../../../components/Selectors/DateRangeSelector/DateRangeSelectorView';
import ButtonExportExcel from '../../../components/Buttons/ExportExcel';
import TableComponent from '../../../components/TableComponent';
import { COLUMNS_PROJECT_OPPORTUNITY_KEYWORD } from '../../../utils/TableColumnsConstants';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';

const OpportunityKeywordsView = () => {
  const { t } = useTranslation();

  const model = OpportunityKeywordsModel();
  const controller = OpportunityKeywordsController(model);

  return (
    <Grid container spacing={2} className="opportunityKeywords">
      <Grid size={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>

          <SelectorView label={t('project')} project={model.project || ''} setProject={model.setProject}
                        itemList={model.dataProject} />

          <TextField focused={true} value={model.url || ''} fullWidth
                     label="URL"
                     size="small"
                     disabled={model.url === undefined}
                     placeholder={t('the_url_must_have_the_domain')}
                     onChange={(e) => model.setUrl(e.target.value)}
                     className={'url'}
          />

          <div className="flex gap-2 w-full items-center py-3">
            <MenuSelector
              value={model.clicksOfSearches}
              isSelected={model.hasClicksOfSearches}
              defaultValue={model.defaultValueClicks}
              suggestion={CONSTANTS.clicksOfSearchesOptions}
              onChange={(values) => model.handleClicksOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.clicksOfSearches.to}
                fromValues={model.clicksOfSearches.from}
                onClick={(value) => model.handleClicksOfSearches(value)}
              />
            </MenuSelector>

            <MenuSelector
              value={model.impressionsOfSearches}
              defaultValue={model.defaultValueImpression}
              suggestion={volumeOfSearchesOptions}
              isSelected={model.hasImpressionsOfSearches}
              onChange={(values) => model.handleImpressionsOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.impressionsOfSearches.to}
                fromValues={model.impressionsOfSearches.from}
                onClick={(value) => model.handleImpressionsOfSearches(value)}
              />
            </MenuSelector>

            <MenuSelector
              value={model.positionOfSearches}
              isSelected={model.hasPositionsOfSearches}
              defaultValue={model.defaultValuePositions}
              suggestion={CONSTANTS.potitionOfSearchesOptions}
              onChange={(values) => model.handlePositionOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.positionOfSearches.to}
                fromValues={model.positionOfSearches.from}
                onClick={(value) => model.handlePositionOfSearches(value)}
              />
            </MenuSelector>

            <MenuSelector
              value={model.ctrOfSearches}
              isSelected={model.hasCtrOfSearches}
              defaultValue={model.defaultValueCtr}
              suggestion={CONSTANTS.CRTOfSearchesOptions}
              onChange={(values) => model.handleCtrOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.ctrOfSearches.to}
                fromValues={model.ctrOfSearches.from}
                onClick={(value) => model.handleCtrOfSearches(value)}
              />
            </MenuSelector>
            <div className="flex">
              <DateRangeSelectorView onChange={model.handleChangeRanges} />
            </div>
          </div>

          <div className="flex justify-end">
            <Button
              color="primary"
              variant="contained"
              onClick={controller.fetchGetKeywords}
              disabled={controller.validateProjectUrlMemoized}
            >
              {t('obtain')}
            </Button>
          </div>

        </PaperItem>
      </Grid>


      <Grid size={{ xs:12, sm:12, md:12, lg:12, xl:12 }}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
            <Typography className="mb-5">{t('organic_keywords')}</Typography>
            <ButtonExportExcel isLoading={model.isLoading} onClick={controller.handleExport} totalKeywords={model.dataKeyword.length} query={model.project || ""} />
          </Box>
          <TableComponent
            rows={model.dataKeyword}
            loading={model.loadingKeywords}
            getRowId={(row) => row.keyword}
            columns={COLUMNS_PROJECT_OPPORTUNITY_KEYWORD(t)}
            filterModel={{
              items: [
                {
                  id: 'shurify',
                  field: 'contains_keyword',
                  operator: 'above',
                  value: model.filterMode === 'all' ? undefined : model.filterMode
                }
              ]
            }}
            slots={{
              panel: () => (
                <div className="py-2">
                  <ButtonGroup
                    current={model.filterMode}
                    onChange={controller.handleModeFilter}
                    suggestions={CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY}
                  />
                </div>
              )
            }}
            paginationMode="client"
            sortingMode="client"
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </PaperItem>
      </Grid>

    </Grid>
  );
};

export default OpportunityKeywordsView;