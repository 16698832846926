import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { FiltersProps } from '../interfaces';
import useKeywordsFilters from '../hooks/useKeywordsFilters';
import { ALL_FILTER_MODE_IDEAS } from '../utils/Constants';

type FilterModeType = (typeof ALL_FILTER_MODE_IDEAS)[number];

interface Props {
  selected: string[];
  modeFilter: FilterModeType;
  cpcOfSearches: FiltersProps;
  volumeOfSearches: FiltersProps;
  numOfKeywords: number | undefined;
  differenceOfSearches: FiltersProps;

  // Nuevos filtros específicos
  urlMin: number;
  urlMax: number;
  trafficCostMin: number;
  trafficCostMax: number;
  trafficMin: number;
  trafficMax: number;
  keywordsCountMin: number;
  keywordsCountMax: number;

  setUrlMin: (value: number) => void;
  setUrlMax: (value: number) => void;
  setTrafficCostMin: (value: number) => void;
  setTrafficCostMax: (value: number) => void;
  setTrafficMin: (value: number) => void;
  setTrafficMax: (value: number) => void;
  setKeywordsCountMin: (value: number) => void;
  setKeywordsCountMax: (value: number) => void;

  // VALIDACIONES
  defaultValueCpc: string;
  defaultValueVolume: string;
  defaultValueDifference: string;
  hasCpcOfSearches: boolean;
  hasVolumeOfSearches: boolean;
  hasDifferenceOfSearches: boolean;

  defaultValueUrl: string;
  defaultValueTrafficCost: string;
  defaultValueTraffic: string;
  defaultValueKeywordsCount: string;
  hasUrlMin: boolean;
  hasTrafficCostMin: boolean;
  hasTrafficMin: boolean;
  hasKeywordsCountMin: boolean;

  // END-VALIDATIONS
  handleChangeIntent: (event: any) => void;
  handleNumOfKeywords: (value: number) => void;
  handleSearchKeywords: (keyword?: string) => any;
  handleModeFilter: (value: FilterModeType) => void;
  handleCpcOfSearches: (value: FiltersProps) => void;
  handleVolumeOfSearches: (value: FiltersProps) => void;
  handleDifferenceOfSearches: (value: FiltersProps) => void;

  handleSearchSpecificFilters: () => Record<string, any>;

  handleUrlMin: (value: FiltersProps) => void;
  handleTrafficCostMin: (value: FiltersProps) => void;
  handleTrafficMin: (value: FiltersProps) => void;
  handleKeywordsCountMin: (value: FiltersProps) => void;
}

const KeywordsFilterContext = createContext<Props>({} as Props);

export const KeywordsFiltersProvider = ({ storageKey, children }: PropsWithChildren<{ storageKey: string }>) => {
  const filters = useKeywordsFilters(storageKey);
  const values = useMemo(() => filters, [filters]);
  return <KeywordsFilterContext.Provider value={values}>{children}</KeywordsFilterContext.Provider>;
};

export const useKeywordsFiltersContext = () => {
  const context = useContext(KeywordsFilterContext);
  if (!context) {
    throw new Error("Component must be inside 'KeywordsFiltersProvider'");
  }
  return context;
};