import { useState } from 'react';
import { useUserContext } from '../../../../context/UserContext';
import { isMobile } from '../../../../utils/Utils';

const ChartModel = () => {

  const initialMetrics = { impressions: true, clicks: true, ctr: false, position: false };
  const [metrics, setMetrics] = useState(initialMetrics);
  const [localHiddenLines, setLocalHiddenLines] = useState<{ [key: string]: boolean }>({});
  const [activeDotId, setActiveDotId] = useState<string | null>(null);
  const { blur } = useUserContext();
  const [showFavoritesInGeneral, setShowFavoritesInGeneral] = useState(true);
  const [domainsGeneralChart, setDomainsGeneralChart] = useState<Array<string>>([])
  const colorArray = [
    "#ff6f61", "#ef6179", "#cf5b8f", "#a55b9a", "#715b96", "#a45787", "#bf5b6f", "#ca6b54", "#c2823e", "#aa9b38",
    "#8ead47", "#b1ad24", "#d4a600", "#f89b00", "#ff7b30", "#ff5c4f", "#fe436c", "#e73686", "#c6389b", "#9b42ab",
    "#ff6f61", "#ef6179", "#d05b8e", "#a25b9a", "#6e5b95", "#a45787", "#c05c6f", "#ca6c53", "#c37f40", "#b29537",
    "#95a941", "#baac1c", "#dfa300", "#ff930d", "#ff7932", "#ff604c", "#ff446a", "#e83685", "#c6389c", "#9842ab",
    "#ff6f61", "#ef6179", "#d15b8e", "#a45b9a", "#6f5b96", "#9d588b", "#bd5a73", "#c9665b", "#c67945", "#b79037",
    "#9ba63e", "#bcab1a", "#e0a300", "#ff940b", "#ff7833", "#ff5d4f", "#ff4568", "#ee3780", "#cd3798", "#a041a9",
    "#ff6f61", "#ef6179", "#d15b8e", "#a75b9a", "#735b97", "#a35787", "#c15c6e", "#ca6a55", "#c3803f", "#b29437",
    "#99a740", "#bbab1b", "#e1a300", "#ff940a", "#ff7734", "#ff5f4c", "#ff4667", "#ee3780", "#d13695", "#ac3ea6",
    "#ff6f61", "#ef6179", "#d25b8d", "#a85b99", "#725b97", "#a35888", "#be5b71", "#c96957", "#c47f40", "#b29437",
    "#97a841", "#baac1c", "#dea300", "#ff9509", "#ff7b30", "#ff614a", "#ff4766", "#ee3780", "#d03696", "#a93fa7"
  ]


  return {
    initialMetrics,
    metrics, setMetrics,
    localHiddenLines, setLocalHiddenLines,
    activeDotId, setActiveDotId,
    blur,
    showFavoritesInGeneral, setShowFavoritesInGeneral,
    domainsGeneralChart, setDomainsGeneralChart,
    colorArray
  }
}

export default ChartModel