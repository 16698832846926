import './SerpKeyword.scss'
import KeywordLinkBlank from '../KeywordLinkBlank';
import KeywordRedirectLink from '../KeywordRedirectLink';

interface Props {
  code: number
  url: string
  keyword: string
}

const SerpKeyword:  React.FC<Props> = ({ code, url, keyword }) => {
  return (
    <div className={'serpKeyword'}>
      <KeywordRedirectLink code={code} link={keyword} path={'/keywords/explorer/'} showIcon={true} />
      <KeywordLinkBlank href={url} />
    </div>
  );
};

export default SerpKeyword;
