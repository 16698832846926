import CannibalizationModel from './CannibalizationModel';
import CannibalizationController from './CannibalizationController';
import Grid from '@mui/material/Grid2';
import PaperItem from '../../../components/Essential/PaperItem';
import SelectorView from '../../../components/Projects/ProjectSelector/ProjectSelectorView';
import { useTranslation } from 'react-i18next';
import MenuSelector from '../../../components/MenuSelector';
import * as CONSTANTS from '../../../utils/Constants';
import { valuesSplitNumber, volumeOfSearchesOptions } from '../../../utils/Utils';
import CustomRange from '../../../components/CustomRange';
import DateRangeSelectorView from '../../../components/Selectors/DateRangeSelector/DateRangeSelectorView';
import { Box, Button, Typography } from '@mui/material';
import TableComponent from '../../../components/TableComponent';
import { COLUMNS_PROJECT_CANNIBALIZATION } from '../../../utils/TableColumnsConstants';
import ButtonExportExcel from '../../../components/Buttons/ExportExcel';

const CannibalizationView = () => {
  const { t } = useTranslation();

  const model = CannibalizationModel();
  const controller = CannibalizationController(model);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid size={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>

          <SelectorView label={t('project')} project={model.project || ''} setProject={model.setProject}
                        itemList={model.dataProject} />

          <div className="flex gap-2 w-full items-end py-3">
            <MenuSelector
              value={model.clicksOfSearches}
              isSelected={model.hasClicksOfSearches}
              defaultValue={model.defaultValueClicks}
              suggestion={CONSTANTS.clicksOfSearchesOptions}
              onChange={(values) => model.handleClicksOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.clicksOfSearches.to}
                fromValues={model.clicksOfSearches.from}
                onClick={(value) => model.handleClicksOfSearches(value)}
              />
            </MenuSelector>

            <MenuSelector
              value={model.impressionsOfSearches}
              defaultValue={model.defaultValueImpression}
              suggestion={volumeOfSearchesOptions}
              isSelected={model.hasImpressionsOfSearches}
              onChange={(values) => model.handleImpressionsOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.impressionsOfSearches.to}
                fromValues={model.impressionsOfSearches.from}
                onClick={(value) => model.handleImpressionsOfSearches(value)}
              />
            </MenuSelector>

            <MenuSelector
              value={model.positionOfSearches}
              isSelected={model.hasPositionsOfSearches}
              defaultValue={model.defaultValuePositions}
              suggestion={CONSTANTS.potitionOfSearchesOptions}
              onChange={(values) => model.handlePositionOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.positionOfSearches.to}
                fromValues={model.positionOfSearches.from}
                onClick={(value) => model.handlePositionOfSearches(value)}
              />
            </MenuSelector>

            <MenuSelector
              value={model.ctrOfSearches}
              isSelected={model.hasCtrOfSearches}
              defaultValue={model.defaultValueCtr}
              suggestion={CONSTANTS.CRTOfSearchesOptions}
              onChange={(values) => model.handleCtrOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={model.ctrOfSearches.to}
                fromValues={model.ctrOfSearches.from}
                onClick={(value) => model.handleCtrOfSearches(value)}
              />
            </MenuSelector>

            <div className="flex">
              <DateRangeSelectorView onChange={model.handleChangeRanges} />
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              color="primary"
              variant="contained"
              onClick={controller.getKeywords}
              disabled={!model.project.property || model.loadingCannibalization}>
              {t('obtain')}
            </Button>
          </div>
        </PaperItem>
      </Grid>

      <Grid size={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
            <Typography className="mb-5">{t('organic_keywords')}</Typography>
            <ButtonExportExcel isLoading={model.isLoading} onClick={controller.handleExport} totalKeywords={model.dataCannibalization.length} query={model.project?.property || ""} />
          </Box>
          <TableComponent
            autoHeight
            rowHeight={140}
            rows={model.dataCannibalization}
            getRowHeight={() => 'auto'}
            loading={model.loadingCannibalization}
            columns={COLUMNS_PROJECT_CANNIBALIZATION(t)}
            paginationMode="client"
            sortingMode="client"
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </PaperItem>
      </Grid>


    </Grid>
  );
};

export default CannibalizationView;