import { useTranslation } from 'react-i18next';

import PaperItem from '../../../components/Essential/PaperItem';
import { Box, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import SearchConsoleChart from '../../../components/Charts/SearchConsoleChart';
import { extractDomain } from '../../../utils/Utils';


const ProjectsFavoriteView = ({ model, controller }) => {
  const { t } = useTranslation();

  const favoriteUrls = Array.from(model.favoriteProjects);
  const nonFavoriteUrls = controller.dataPerformance?.items.map(item => item.url).filter(url => !model.favoriteProjects.has(url)) || [];
  const hasFavorites = favoriteUrls.length > 0;
  const favoriteProjectData = favoriteUrls.map(url => ({ cleanedUrl: extractDomain(url), fullUrl: url }));
  const nonFavoriteProjectData = nonFavoriteUrls.map(url => ({ cleanedUrl: extractDomain(url), fullUrl: url }));

  const renderLoadingSkeleton = (columns: number) => {
    return (
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid size={12}>
          <PaperItem sx={{ p: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center" gap={1}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="rectangular" width={240} height={20} />
                <Skeleton variant="circular" width={20} height={20} />
              </Box>
              <Box display="flex" gap={1}>
                {[...Array(5)].map((_, index) => (
                  <Skeleton key={index} variant="circular" width={20} height={20} />
                ))}
              </Box>
            </Box>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Skeleton variant="rectangular" width={'100%'} height={200} />
            </Grid>
          </PaperItem>
        </Grid>
      </Grid>
    );
  };

  const renderProjectChart = (projectUrl: string, fullUrl: string, numberOfColumns: number, isFavorite: boolean) => {
    const searchData = controller.dataPerformance?.items.filter(item => extractDomain(item.url) === projectUrl)
      .flatMap(item => item.data.map(data => ({ date: data.keys[0], ...data, originalUrl: item.url }))) || [];
    return (
      <>
        {controller.loadingPerformance ? (
          <>
            {renderLoadingSkeleton(model.columns)}
          </>
        ) : (
          <SearchConsoleChart
            key={projectUrl}
            project={{ id: fullUrl, url: projectUrl, property: fullUrl, originalUrl: fullUrl }}
            isGeneralView={model.columns > 1 && !model.isMobile}
            searchData={searchData}
            showDots={false}
            showLegend={false}
            blurTitle={model.blur}
            showChartHeader={true}
            showSummaryCards={model.columns === 1 && !model.isMobile}
            metrics={model.globalMetrics}
            numberOfColumns={numberOfColumns}
            isFavorite={isFavorite}
            onFavoriteToggle={() => controller.toggleFavorite(fullUrl)}
            fetchProjectInfo={controller.fetchProjectInfo}
            modelProject={model}
          />
        )
        }
      </>
    );
  };

  const renderSection = (title: string, urls: {
    cleanedUrl: string,
    fullUrl: string
  }[], show: boolean, setShow: (show: boolean) => void) => (
    <>
      {hasFavorites && (
        <PaperItem sx={{ mb: 0, p: 0, mt: 3 }}>
          <Box display="flex" alignItems="center" onClick={() => setShow(!show)}
               sx={{ cursor: 'pointer', mb: 0, p: 2 }}>
            <Typography variant="h6">{title}</Typography>
            <IconButton>{show ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Box>
        </PaperItem>
      )}
      <Collapse in={show}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {urls.map(({ cleanedUrl, fullUrl }) => (
            <Grid size={{ xs: 12, md: (12 / model.columns) }} key={cleanedUrl}>
              {renderProjectChart(cleanedUrl, fullUrl, model.columns, title === t('favoriteProjects'))}
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );

  return (
    <>
      {favoriteProjectData.length > 0 && renderSection(t('favoriteProjects'), favoriteProjectData, model.showFavorites, model.setShowFavorites)}
      {(hasFavorites || nonFavoriteProjectData.length > 0) && renderSection(t('otherProjects'), nonFavoriteProjectData, model.showNonFavorites, model.setShowNonFavorites)}
    </>
  );

};

export default ProjectsFavoriteView;