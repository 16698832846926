import { Avatar, Typography } from '@mui/material';

interface Props {
  options: any
  data: {
    profile_url: string
    name: string
    email: string
  },
}

const ProjectAccount = ({data, options}: Props) => {

  if (!data) {
    return;
  }

  return (
    <div className={'projectProperty'}>
      <Avatar src={data.profile_url} alt={data.name} />
      <div className="flex flex-col">
        <Typography>{data.name}</Typography>
        <Typography variant="caption" className={options.blur ? 'blurred-text' : ''}>
          {data.email}
        </Typography>
      </div>
    </div>
  );
};

export default ProjectAccount;