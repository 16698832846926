import { Link } from 'react-router-dom';
import { checkIfUserHavePlan } from '../../utils/Utils';
import { useUserContext } from '../../context/UserContext';
import { useColorModeContext } from '../../context/ColorModeContext';
import LaunchIcon from '@mui/icons-material/Launch';
import { Chip, ListItem, ListItemText, Tooltip, useMediaQuery, Box } from '@mui/material';

interface Props {
  path: string;
  selected: boolean;
  external?: boolean;
  subRouteName: string;
  tooltipContent: string;
  plans?: ReadonlyArray<string>;
}

export function SubRoute({
  path,
  plans,
  selected,
  external,
  subRouteName,
  tooltipContent,
}: Props) {
  const { plan } = useUserContext();
  const { onOpenSidebar } = useColorModeContext();
  const matchQuery = useMediaQuery('(max-width:600px)');
  const hasHavePlan = checkIfUserHavePlan({ plans, plan });

  return (
    <>
      {hasHavePlan &&
        <ListItem
          to={path}
          component={Link}
          selected={selected}
          onClick={() => matchQuery && onOpenSidebar(false)}
          sx={{ borderRadius: 1, paddingBlock: 0, pl: 3.5, position: 'relative' }}
        >
          <ListItemText
            primary={
              <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                {subRouteName}
                <Tooltip title={tooltipContent} arrow>
                  <Box
                    component="span"
                    sx={{
                      ml: 0.5,
                      fontSize: '8px',
                      lineHeight: 1,
                      verticalAlign: 'super',
                      color: 'gray',
                      cursor: 'pointer',
                      display: 'inline-block',
                    }}
                  >
                    i
                  </Box>
                </Tooltip>
              </Box>
            }
          />
          {external && <LaunchIcon fontSize="small" />}
        </ListItem>
      }
    </>
  );
}