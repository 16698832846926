import { useCallback, useEffect, useMemo, useState } from 'react';
import { extractDomain } from '../../../utils/Utils';
import * as CONSTANTS from '../../../utils/Constants';

const OpportunityKeywordsController = (model) => {

  useEffect(() => {
    if(model.project && model.project.property){
      model.setUrl(getDomain(model.project.property))
    }
  }, [model.project]);

  const handleModeFilter = (value) => model.setFilterMode(value);

  const convertOpportunityKeywordsResponse = (response) => {
    return response.items.map(item => ({
      keyword: item.keys[0],
      url: item.keys[1],
      ...item
    }));
  };

  const getKeywords = useCallback(async () => {
    if (!model.project || !model.url || !model.url?.includes(extractDomain(model.project.property))) return;
    const data = await model.fetchKeywords({ url: model.url, project: model.project.property, ...model.rangesDate, ...model.handleFiltersApply() });
    if (data) {
      const formattedData = convertOpportunityKeywordsResponse(data);
      model.setDataKeyword(formattedData);
    }
    handleModeFilter(CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY[0]);
  }, [model.project, model.url, model.rangesDate, model.fetchKeywords, model.handleFiltersApply]);

  const fetchGetKeywords = async () => await getKeywords();


  const getDomain = (domain) => {
    const extractedDomain:string = extractDomain(domain)
    return extractedDomain.indexOf('http')!==-1?extractedDomain:`https://${extractedDomain}/`
  }

  const validateProjectUrlMemoized = useMemo(() => {
    if (model.url && model.project.property) return !model.url.includes(extractDomain(model.project.property)) || model.loadingKeywords;
    return true;
  }, [model.url, model.project, model.loadingKeywords]);

  const handleExport = async () => {
    if (!model.url) return;
    const data = { url: model.url, project: model.project.property, ...model.rangesDate, ...model.handleFiltersApply() };
    await model.fetchExportProjectsKeywords(data);
  };


    return {
    fetchGetKeywords,
    handleModeFilter,
      validateProjectUrlMemoized,
    handleExport
  }
}

export default OpportunityKeywordsController