import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useUsers } from '../hooks/useUsers';
import { useUserContext } from '../context/UserContext';
import { Container } from '@mui/material';
import OnboardingStepsBar from '../components/OnboardingStepsBar/OnboardingStepsBar';
import PlanGuard from '../components/PlanGuard/PlanGuard';
import { useSelectedPlan } from '../hooks/useSelectedPlan';
import { ROUTES } from '../utils/Constants';
import { ALL_PLANS, AllPlansUnionType, PER_YEAR_OR_MONTH, PerYearOrMonthUnionType } from '../utils/SubscriptionPlans';
import { Header } from '../components/Essential/Header';

const BuyPlanPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isAuthenticated, loadingPlan } = useUserContext();
  const { error, loadingRedirectStripe, fetchRedirectToCheckoutStripe } = useUsers();
  const { setSelectedPlan, setSelectedBilling } = useSelectedPlan();
  const method = searchParams.get('method') as AllPlansUnionType;
  const billing = searchParams.get('billing') as PerYearOrMonthUnionType;
  const coupon = searchParams.get('coupon') || null;
  const authCode = searchParams.get('code');

  const [activeStep, setActiveStep] = useState(0);


  useEffect(() => {
    if ((isAuthenticated || authCode) && !loadingPlan) {
      setActiveStep((prevStep) => Math.max(prevStep, 1));
      if (authCode) {
        navigate(ROUTES.BUY_PLAN_USER, { replace: true });
      }
    }
  }, [isAuthenticated, authCode, loadingPlan, navigate]);

  return (
    <PlanGuard>
      <Header disableButtons />
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <OnboardingStepsBar 
          activeStep={activeStep} 
          setActiveStep={setActiveStep} 
          plan={method}
          billing={billing}
          coupon={coupon} 
        />
      </Container>
    </PlanGuard>
  );
};

export default BuyPlanPage;