import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersProps } from '../interfaces';
import { validateDefaultValues, validateHasDefaultValues } from '../utils/Utils';
import { DEFAULT_VALUES_FILTER, ALL_FILTER_MODE_IDEAS } from '../utils/Constants';

type FilterModeType = (typeof ALL_FILTER_MODE_IDEAS)[number];

export default function useKeywordsFilters(storageKey: string) {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [numOfKeywords, setNumOfKeywords] = React.useState<number>();
  const [modeFilter, setModeFilter] = React.useState<FilterModeType>(ALL_FILTER_MODE_IDEAS[0]);
  const [cpcOfSearches, setCpcOfSearches] = React.useState<FiltersProps>(DEFAULT_VALUES_FILTER);
  const [differenceOfSearches, setDifferenceOfSearches] = React.useState<FiltersProps>(DEFAULT_VALUES_FILTER);
  const [volumeOfSearches, setVolumeOfSearches] = React.useState<FiltersProps>(DEFAULT_VALUES_FILTER);

  const [urlMin, setUrlMin] = React.useState<number>(0);
  const [urlMax, setUrlMax] = React.useState<number>(0);
  const [trafficCostMin, setTrafficCostMin] = React.useState<number>(0);
  const [trafficCostMax, setTrafficCostMax] = React.useState<number>(0);
  const [trafficMin, setTrafficMin] = React.useState<number>(0);
  const [trafficMax, setTrafficMax] = React.useState<number>(0);
  const [keywordsCountMin, setKeywordsCountMin] = React.useState<number>(0);
  const [keywordsCountMax, setKeywordsCountMax] = React.useState<number>(0);

  const [hasUrlMin, setHasUrlMin] = React.useState<boolean>(false);
  const [hasTrafficCostMin, setHasTrafficCostMin] = React.useState<boolean>(false);
  const [hasTrafficMin, setHasTrafficMin] = React.useState<boolean>(false);
  const [hasKeywordsCountMin, setHasKeywordsCountMin] = React.useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const storedFilters = localStorage.getItem(storageKey);
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      setSelected(parsedFilters.selected || []);
      setNumOfKeywords(parsedFilters.numOfKeywords || undefined);
      setModeFilter(parsedFilters.modeFilter || ALL_FILTER_MODE_IDEAS[0]);
      setCpcOfSearches(parsedFilters.cpcOfSearches || DEFAULT_VALUES_FILTER);
      setDifferenceOfSearches(parsedFilters.differenceOfSearches || DEFAULT_VALUES_FILTER);
      setVolumeOfSearches(parsedFilters.volumeOfSearches || DEFAULT_VALUES_FILTER);
      setUrlMin(parsedFilters.urlMin || 0);
      setUrlMax(parsedFilters.urlMax || 0);
      setTrafficCostMin(parsedFilters.trafficCostMin || 0);
      setTrafficCostMax(parsedFilters.trafficCostMax || 0);
      setTrafficMin(parsedFilters.trafficMin || 0);
      setTrafficMax(parsedFilters.trafficMax || 0);
      setKeywordsCountMin(parsedFilters.keywordsCountMin || 0);
      setKeywordsCountMax(parsedFilters.keywordsCountMax || 0);

      setHasUrlMin(parsedFilters.hasUrlMin || false);
      setHasTrafficCostMin(parsedFilters.hasTrafficCostMin || false);
      setHasTrafficMin(parsedFilters.hasTrafficMin || false);
      setHasKeywordsCountMin(parsedFilters.hasKeywordsCountMin || false);
    }
  }, [storageKey]);

  useEffect(() => {
    const filtersState = {
      selected,
      numOfKeywords,
      modeFilter,
      cpcOfSearches,
      differenceOfSearches,
      volumeOfSearches,
      urlMin,
      urlMax,
      trafficCostMin,
      trafficCostMax,
      trafficMin,
      trafficMax,
      keywordsCountMin,
      keywordsCountMax,
      hasUrlMin,
      hasTrafficCostMin,
      hasTrafficMin,
      hasKeywordsCountMin,
    };
    localStorage.setItem(storageKey, JSON.stringify(filtersState));
  }, [
    storageKey,
    selected,
    numOfKeywords,
    modeFilter,
    cpcOfSearches,
    differenceOfSearches,
    volumeOfSearches,
    urlMin,
    urlMax,
    trafficCostMin,
    trafficCostMax,
    trafficMin,
    trafficMax,
    keywordsCountMin,
    keywordsCountMax,
    hasUrlMin,
    hasTrafficCostMin,
    hasTrafficMin,
    hasKeywordsCountMin,
  ]);

  const handleNumOfKeywords = (value: number) => setNumOfKeywords(value);
  const handleModeFilter = (value: FilterModeType) => setModeFilter(value);
  const handleCpcOfSearches = (value: FiltersProps) => setCpcOfSearches(value);
  const handleVolumeOfSearches = (value: FiltersProps) => setVolumeOfSearches(value);
  const handleDifferenceOfSearches = (value: FiltersProps) => setDifferenceOfSearches(value);

  const handleSearchKeywords = (keyword?: string) => {
    const buildData: any = {};

    if (keyword) buildData.keyword = keyword;
    if ((numOfKeywords as number) > 0) buildData.numOfKeywords = numOfKeywords;
    if (selected.length > 0) {
      buildData.intention = selected.map((item) => item.charAt(0).toUpperCase()).join(',');
    }
    if (cpcOfSearches.to > 0) {
      buildData.cpcMax = cpcOfSearches.to;
    }
    if (cpcOfSearches.from > 0) {
      buildData.cpcMin = cpcOfSearches.from;
    }
    if (differenceOfSearches.to > 0) {
      buildData.difficultyMax = differenceOfSearches.to;
    }
    if (differenceOfSearches.from > 0) {
      buildData.difficultyMin = differenceOfSearches.from;
    }
    if (volumeOfSearches.to > 0) {
      buildData.volumeOfSearchesMax = volumeOfSearches.to;
    }
    if (volumeOfSearches.from > 0) {
      buildData.volumeOfSearchesMin = volumeOfSearches.from;
    }
    return buildData;
  };

  const handleChangeIntent = (event) => {
    const value = event.target.value;
    setSelected(typeof value === 'string' ? value.split(',') : value);
  };

  const validateNumberVolume = validateDefaultValues(volumeOfSearches);
  const hasVolumeOfSearches = validateHasDefaultValues(volumeOfSearches);
  const defaultValueVolume = hasVolumeOfSearches ? validateNumberVolume : t('headerName.keyword_info.search_volume');

  const validateNumberDiffi = validateDefaultValues(differenceOfSearches);
  const hasDifferenceOfSearches = validateHasDefaultValues(differenceOfSearches);
  const defaultValueDifference = hasDifferenceOfSearches ? validateNumberDiffi : t('headerName.extra.keyword_difficulty');

  const validateNumberCpc = validateDefaultValues(cpcOfSearches);
  const hasCpcOfSearches = validateHasDefaultValues(cpcOfSearches);
  const defaultValueCpc = hasCpcOfSearches ? validateNumberCpc : t('headerName.keyword_info.cpc');

  const validateNumberUrl = validateDefaultValues({ from: urlMin, to: urlMax });
  const hasUrlMinDefined = validateHasDefaultValues({ from: urlMin, to: urlMax });
  const defaultValueUrl = hasUrlMinDefined ? validateNumberUrl : t('URLS');
  
  const validateNumberTrafficCost = validateDefaultValues({ from: trafficCostMin, to: trafficCostMax });
  const hasTrafficCostMinDefined = validateHasDefaultValues({ from: trafficCostMin, to: trafficCostMax });
  const defaultValueTrafficCost = hasTrafficCostMinDefined ? validateNumberTrafficCost : t('TrafficCost');
  
  const validateNumberTraffic = validateDefaultValues({ from: trafficMin, to: trafficMax });
  const hasTrafficMinDefined = validateHasDefaultValues({ from: trafficMin, to: trafficMax });
  const defaultValueTraffic = hasTrafficMinDefined ? validateNumberTraffic : t('Traffic');
  
  const validateNumberKeywordsCount = validateDefaultValues({ from: keywordsCountMin, to: keywordsCountMax });
  const hasKeywordsCountMinDefined = validateHasDefaultValues({ from: keywordsCountMin, to: keywordsCountMax });
  const defaultValueKeywordsCount = hasKeywordsCountMinDefined ? validateNumberKeywordsCount : t('KeywordsCount');

  const handleSearchSpecificFilters = () => {
    const buildData: any = {};

    if (urlMin) buildData.urlsMin = urlMin;
    if (urlMax) buildData.urlsMax = urlMax;
    if (trafficCostMin) buildData.trafficCostMin = trafficCostMin;
    if (trafficCostMax) buildData.trafficCostMax = trafficCostMax;
    if (trafficMin) buildData.trafficMin = trafficMin;
    if (trafficMax) buildData.trafficMax = trafficMax;
    if (keywordsCountMin) buildData.keywordsCountMin = keywordsCountMin; 
    if (keywordsCountMax) buildData.keywordsCountMax = keywordsCountMax;

    return buildData;
  };

  const handleUrlMin = (value: FiltersProps) => {
    setUrlMin(value.from);
    setUrlMax(value.to);
    setHasUrlMin(true);
  };

  const handleTrafficCostMin = (value: FiltersProps) => {
    setTrafficCostMin(value.from);
    setTrafficCostMax(value.to);
    setHasTrafficCostMin(true);
  };

  const handleTrafficMin = (value: FiltersProps) => {
    setTrafficMin(value.from);
    setTrafficMax(value.to);
    setHasTrafficMin(true);
  };

  const handleKeywordsCountMin = (value: FiltersProps) => {
    setKeywordsCountMin(value.from);
    setKeywordsCountMax(value.to);
    setHasKeywordsCountMin(true);
  };

  return {
    selected,
    modeFilter,
    cpcOfSearches,
    numOfKeywords,
    differenceOfSearches,
    volumeOfSearches,
    defaultValueCpc,
    defaultValueDifference,
    defaultValueVolume,
    hasCpcOfSearches,
    hasVolumeOfSearches,
    hasDifferenceOfSearches,

    urlMin,
    setUrlMin,
    urlMax,
    setUrlMax,
    trafficCostMin,
    setTrafficCostMin,
    trafficCostMax,
    setTrafficCostMax,
    trafficMin,
    setTrafficMin,
    trafficMax,
    setTrafficMax,
    keywordsCountMin,
    setKeywordsCountMin,
    keywordsCountMax,
    setKeywordsCountMax,
    defaultValueUrl,
    defaultValueTrafficCost,
    defaultValueTraffic,
    defaultValueKeywordsCount,
    hasUrlMin: hasUrlMinDefined,
    hasTrafficCostMin: hasTrafficCostMinDefined,
    hasTrafficMin: hasTrafficMinDefined,
    hasKeywordsCountMin: hasKeywordsCountMinDefined,

    // CALLBACKS
    handleModeFilter,
    handleChangeIntent,
    handleCpcOfSearches,
    handleNumOfKeywords,
    handleSearchKeywords,
    handleVolumeOfSearches,
    handleDifferenceOfSearches,

    handleSearchSpecificFilters,
    handleUrlMin,
    handleTrafficCostMin,
    handleTrafficMin,
    handleKeywordsCountMin,
  };
}