import { useEffect } from 'react';
import { formatRows } from '../../../utils/format';
import { useLocation } from 'react-router-dom';

const projectDetailController = (model) => {

  const location = useLocation();

  useEffect(() => {
    const project = location.state.project
    model.setProject(project)
  }, []);


  const handleGlobalMetricToggle = (metric: string) => model.setGlobalMetrics(prev => ({
    ...prev,
    [metric]: !prev[metric]
  }));

  const filteredColumns = (columns: any[]) => columns.filter((column) => column.field === 'keys' || model.globalMetrics[column.field]);
  const formattedRows = (rows: any[], key: string) => formatRows(rows, model.projectInfo?.currentData?.maxMinClicks?.[key] || {});


  return {
    handleGlobalMetricToggle,
    filteredColumns, formattedRows
  }

}

export default projectDetailController