import './ContainsKeyword.scss';
import { useTranslation } from 'react-i18next';

import { Paper, Skeleton, Typography } from '@mui/material';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

interface Props {
  value?: object;
}

const ContainsKeyword = ({ value }: Props) => {
  const { t } = useTranslation();

  if (value === undefined) return false;

  const footerContentHeader = Object.fromEntries(
    Object.entries(value).filter(([key, value]) => !(key.startsWith('h') && key.length === 2) && value > 0)
  );

  const h1ToH6 = Object.fromEntries(
    Object.entries(value).filter(([key, value]) => (key.startsWith('h') && key.length === 2) && value > 0)
  );

  return (
    <CustomTooltip content={t(`tooltip.contains_keyword_${value.toString()}`)}>
      <div className="contains-keyword">
          {typeof(value)==='boolean' &&  value==false?(
            <div className={`border ${value}`}>
                <Typography fontWeight={700} className={'false'}>{t('contains_keyword_false')}</Typography>
            </div>
          ): (
            <div className="container">
              <div className={'row'}>
                {Object.entries(footerContentHeader).map(([key, value]) => (
                  <p key={key}>{t(`opportunity.${key}`)}: {value}</p>
                ))}
              </div>

              <div className={'row'}>
                {Object.entries(h1ToH6).map(([key, value]) => (
                  <p key={key}>{t(`opportunity.${key}`)}: {value}</p>
                ))}
              </div>
            </div>


          )}


      </div>
    </CustomTooltip>
  );
};

export default ContainsKeyword;
