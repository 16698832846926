import { useCallback } from 'react';

const CannibalizationController = (model) => {

  const convertCannibalizationResponse = (response) => {
    if (response.items && typeof response.items === 'object' && Object.keys(response.items).length > 0) {
      return Object.entries(response.items).map(([key, value]) => {
        const results = value.url.map((url, index) => ({
          url: url,
          clicks: value.clicks[index],
          impressions: value.impressions[index],
          position: value.position[index],
        }));
        return {
          keyword: key,
          results: results
        };
      });
    } else {
      console.warn('No cannibalization data available');
      return [];
    }
  };

  const getKeywords = useCallback(async () => {
    if (!model.project) return;
    const data = await model.fetchCannibalization({ project: model.project.property, ...model.rangesDate, ...model.handleFiltersApply() });
    if (data) {
      const formattedData = convertCannibalizationResponse(data);
      model.setDataCannibalization(formattedData);
    }
  }, [model.project, model.rangesDate, model.fetchCannibalization, model.handleFiltersApply]);

  const fetchGetKeywords = async () => await getKeywords();

  const handleExport = async () => {
    if (!model.project) return;
    const data = { project: model.project.property, ...model.rangesDate, ...model.handleFiltersApply() };
    await model.fetchExportProjectsCannibalization(data);
  };

  return {
    getKeywords,
    handleExport
  }
}

export default CannibalizationController