import { Chip } from '@mui/material';
import { generateIcon } from '../../utils/Utils';
import KeywordRedirectLink from '../KeywordRedirectLink';

interface Props {
  data: {
    page_from_title: string
    url_from: string
    url_to_https: string
    page_from_language: string
    domain_from_platform_type: any
  };
  code: number;
  link?: string;
  path: string;
}

const BacklinkPageFromTitle = ({ data, code, link, path }: Props) => {

  return (
    <div className="flex flex-col py-2">
      <KeywordRedirectLink code={code} link={data.url_from} path={'/domain/view/'} showIcon={true} />
      <div className="flex gap-1 items-center">
        {data.page_from_language && <Chip label={data.page_from_language} />}
        {Array.isArray(data.domain_from_platform_type) &&
          data.domain_from_platform_type.length > 0 &&
          data.domain_from_platform_type.map(
            (element) => element !== 'unknown' && <Chip key={element} label={element} />
          )}
        {data.url_to_https && (
          <Chip label={generateIcon('LockOutlined', { sx: { mt: 1 }, fontSize: 'small' })} />
        )}
      </div>
    </div>
  );
};

export default BacklinkPageFromTitle;
