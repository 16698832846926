import './KeywordMetadata.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { KeywordsMetadata } from '../../types';
import { classNames, formatNumber } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import { Typography } from '@mui/material';
import WithSkeleton from '../WithSkeleton/WithSkeleton';
import Cost from '../Cost';

interface Props {
  metadata: Partial<KeywordsMetadata>;
}

const KeywordMetadata: React.FC<Props> = ({ metadata }) => {
  const { t } = useTranslation();
  const { mode } = useColorModeContext();

  return (
    <div className="flex items-center justify-between">
      <div className="metadata">
        <div className={classNames('metadata-item', mode)}>
          <WithSkeleton data={metadata?.totalKeywords} width={130}>
            <Typography
              className="metadata-text"
              data-testid={`all_keywords: ${metadata?.totalKeywords || 0}`}>
              {t('all_keywords')}: <strong>{formatNumber(metadata?.totalKeywords) || 0}</strong>
            </Typography>
          </WithSkeleton>
        </div>
        <div className={classNames('metadata-item', mode)}>
          <WithSkeleton data={metadata?.totalSearchVolume} width={130}>
            <Typography
              className="metadata-text"
              data-testid={`total_volume: ${metadata?.totalSearchVolume}`}>
              {t('total_volume')}: <strong>{formatNumber(metadata?.totalSearchVolume)}</strong>
            </Typography>
          </WithSkeleton>
        </div>
        <div className={classNames('metadata-item', mode)}>
          <WithSkeleton data={metadata?.avgKeywordDifficulty} width={130}>
            <Typography
              className="metadata-text"
              data-testid={`average_kw: ${metadata.avgKeywordDifficulty}%`}>
              {t('average_kw')}: <strong>{metadata.avgKeywordDifficulty}%</strong>
            </Typography>
          </WithSkeleton>
        </div>
        <div className={classNames('metadata-item', mode)}>
          <WithSkeleton data={metadata?.avgCpc} width={130}>
            <Typography className="metadata-text" data-testid={`average_cpc: ${metadata.avgCpc}`}>
              {t('average_cpc')}: <strong><Cost value={metadata.avgCpc}/></strong>
            </Typography>
          </WithSkeleton>
        </div>
      </div>
    </div>
  );
};

export default KeywordMetadata;
