import { useProjectsData } from '../../../hooks/useProjectsData';
import { useCallback, useEffect } from 'react';
import { extractDomain } from '../../../utils/Utils';
import { useMediaQuery } from '@mui/material';

const ProjectsPerformanceController = (model) => {
  const { fetchProjectsPerformance, dataPerformance, loadingPerformance, fetchProjectInfo } = useProjectsData();

  useEffect(() => {
    const storedFavorites = localStorage.getItem('favoriteProjects');
    const storedShowFavorites = localStorage.getItem('showFavorites');
    const storedShowNonFavorites = localStorage.getItem('showNonFavorites');
    if (storedFavorites) {
      model.setFavoriteProjects(new Set(JSON.parse(storedFavorites)));
    }
    if (storedShowFavorites !== null) {
      model.setShowFavorites(JSON.parse(storedShowFavorites));
    }
    if (storedShowNonFavorites !== null) {
      model.setShowNonFavorites(JSON.parse(storedShowNonFavorites));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('favoriteProjects', JSON.stringify(Array.from(model.favoriteProjects)));
  }, [model.favoriteProjects]);

  useEffect(() => {
    localStorage.setItem('showFavorites', JSON.stringify(model.showFavorites));
    localStorage.setItem('showNonFavorites', JSON.stringify(model.showNonFavorites));
  }, [model.showFavorites, model.showNonFavorites]);


  const handleGlobalMetricToggle = (metric: string) => model.setGlobalMetrics(prev => ({
    ...prev,
    [metric]: !prev[metric]
  }));


  const toggleFavorite = useCallback((projectUrl) => {
    model.setFavoriteProjects(prev =>
      new Set(prev.has(projectUrl) ? [...prev].filter(url => url !== projectUrl) : [...prev, projectUrl])
    );
  }, []);

  const fetchAndSetPerformanceData = useCallback(async () => {
    if (!model.dateRange.startDate || !model.dateRange.endDate) return;
    try {
      await fetchProjectsPerformance({ startDate: model.dateRange.startDate, endDate: model.dateRange.endDate });
    } catch (error) {
      console.error('Error fetching performance data:', error);
    }
  }, [fetchProjectsPerformance, model.dateRange]);

  const processPerformanceData = useCallback(() => {
    if (!dataPerformance) return;
    type AccumulatorType = {
      date: string;
      [key: string]: number | string;
    };
    const domainData = dataPerformance.items.flatMap(({ url, data }) =>
      data.map(({ keys, impressions }) => ({ url: extractDomain(url), date: keys[0], impressions }))
    ).reduce((acc: AccumulatorType[], { url, date, impressions }) => {
      const entry = acc.find((e) => e.date === date);
      entry ? (entry[url] = impressions) : acc.push({ date, [url]: impressions });
      return acc;
    }, []);
    model.setCustomDomainData(domainData);
  }, [dataPerformance]);

  useEffect(() => {
    fetchAndSetPerformanceData();
  }, [fetchAndSetPerformanceData]);

  useEffect(() => {
    if (!loadingPerformance) processPerformanceData();
  }, [loadingPerformance, processPerformanceData]);


  return {
    handleGlobalMetricToggle,
    dataPerformance,
    toggleFavorite,
    loadingPerformance
  };


};

export default ProjectsPerformanceController;