import './plan.scss'
import { FC } from 'react';
import PlansViewModel, { PropsInterface, PlanInterface } from './PlansModel';
import PlansViewController from './PlansController';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Tooltip
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { PLAN_BILLING_YEARLY, PLAN_DISCOUNT_ANUAL, PLAN_EXPERT } from '../../utils/Constants';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


const PlansView: FC<PropsInterface> = ({ plan, billing, coupon }) => {
  const { t } = useTranslation();

  const model = PlansViewModel()
  const controller = PlansViewController(model)

  if(plan){
    model.setSelectedPlan(plan)
  }

  return (
    <Box sx={{ flexGrow: 1 }} className={'plans'} >

      {!coupon &&
        <FormControl component="fieldset" sx={{ mb: 1 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={model.selectedBilling === PLAN_BILLING_YEARLY}
                  onChange={controller.handleChangeBillingPeriod}
                />
              }
              label={<Typography>{t('yearly')} ({PLAN_DISCOUNT_ANUAL*100}% dto)</Typography>}
            />
          </FormGroup>
        </FormControl>
      }

      <Grid container spacing={4} justifyContent="center" className={'container'}>
        {model.planList.map((plan, index) => (

          <Grid size={{ xs:12, sm:12, md:4 }} key={index} >

            <Card className={'item'} onClick={()=> model.setSelectedPlan(plan.name)}
                  sx={{
                    border: plan.name === model.selectedPlan ? '1px solid '+plan.color: 'none',
                    boxShadow: plan.name === model.selectedPlan? '0 2px 5px '+plan.color: 'none'
                  }}

                  // Hidden when coupon arrives but is not affiliate coupon
                  hidden={plan.name===PLAN_EXPERT && ( coupon && !coupon.startsWith('affiliate') )}
            >
              <CardContent className={model.selectedPlan && plan.name === model.selectedPlan ? 'active' : ''}
                           onClick={() => { model.setSelectedPlan(plan.name) }} >
                <Typography sx={{ color: plan.color }} className={'title'}>{plan.name.toUpperCase()} </Typography>

                {/* Price and IVA */}
                <Box display="inline-flex" alignItems="baseline" gap={0.5}>
                  <Typography className="price">${controller.calculatePrice(plan.price)}</Typography>
                  <Typography className="priceDescription">+ {t('tax').toUpperCase()} / {t('month')}</Typography>
                </Box>

                <Typography align='left' className={'includeDescription'}>{t('plan.include')}:</Typography>

                {plan.included && plan.included.map((item, index) => (
                  <Tooltip title={t(`tooltip.planItem.${item}`)} placement="top" arrow followCursor >
                    <Box display="flex" alignItems="center" gap={0.5} className={'included'} key={index} >
                        <CheckCircleIcon /><Typography>{t(`planItem.${item}`)}</Typography>
                    </Box>
                  </Tooltip>
                ))}

                {plan.notIncluded && plan.notIncluded.map((item, index) => (
                  <Tooltip title={t(`tooltip.planItem.${item}`)} placement="top" arrow followCursor >
                    <Box display="flex" alignItems="center" gap={0.5} className={'notIncluded'} key={index} >
                      <CloseIcon /><Typography>{t(`planItem.${item}`)}</Typography>
                    </Box>
                  </Tooltip>
                ))}

                <Box mt={2} className={`buttonTrial buttonTrial_${plan.isTrialEnabled}`} >
                  <button className={`button`} onClick={() => controller.redirectToStripeCheckout(plan.name, model.selectedBilling, coupon)} disabled={controller.loadingRedirectStripe} >
                    {plan.isTrialEnabled? t('trial') : t('buy_now')}
                  </button>
                </Box>

              </CardContent>
            </Card>
          </Grid>


        ))}
      </Grid>
      <Alert
        icon={<KeyboardDoubleArrowDownIcon fontSize="inherit" />}
        severity="info"
        sx={{ mt: 2 }}>{`${t('plan.moreInfo')} `}
        <a href="https://rankerfy.com#planes" target="_blank" rel="noreferrer">
          {t('plan.moreInfo2')}
        </a>
        .
      </Alert>

    </Box>
  )
}

export default PlansView