import { useSelectedPlan } from '../../context/PlanContext';
import {
  PLAN_ADVANCED,
  PLAN_ADVANCED_PRICE,
  PLAN_EXPERT,
  PLAN_EXPERT_PRICE,
  PLAN_ROOKIE,
  PLAN_ROOKIE_PRICE
} from '../../utils/Constants';
import { useState } from 'react';

export interface PropsInterface {
  plan: string | null;
  billing: string | null;
  coupon: string | null;
}

export interface PlanInterface {
  name: string | null;
  price: number;
  color: string;
  isTrialEnabled?: boolean;
  isVisibleWhenCoupon?: boolean;
  popular?: boolean;
  included?: Array<string>;
  notIncluded?: Array<string>;
}

const PlansViewModel = () => {

  const { selectedPlan, setSelectedPlan, selectedBilling, setSelectedBilling } = useSelectedPlan();

  const planList:Array<PlanInterface> = [
    { name: PLAN_ROOKIE, price: PLAN_ROOKIE_PRICE ,isTrialEnabled: true, isVisibleWhenCoupon: true, color: '#FE9F2F',
    included: ['projectAdmin','projectPerformance', 'cannibalization', 'thinContent', 'opportunityKeywords', 'keywords', 'keywordSugestion',
      , 'domain', 'domainPosition', 'domainCompetitors', 'contentGap', 'keywordIdeas', 'domainIdeas']
      , notIncluded: ['keywordsBatch', 'domainBatch', 'backlinks'] },
    { name: PLAN_ADVANCED, price: PLAN_ADVANCED_PRICE ,isTrialEnabled: false, isVisibleWhenCoupon: false, color: '#2B7FEB', popular: true,
      included: ['projectAdmin','projectPerformance', 'cannibalization', 'thinContent', 'opportunityKeywords', 'keywords', 'keywordSugestion', 'keywordsBatch'
        , 'domain', 'domainBatch', 'domainPosition', 'domainCompetitors', 'backlinks', 'contentGap', 'keywordIdeas', 'domainIdeas'] },
    { name: PLAN_EXPERT, price: PLAN_EXPERT_PRICE ,isTrialEnabled: false, isVisibleWhenCoupon: true, color: '#6154E8',
      included: ['projectAdmin','projectPerformance', 'cannibalization', 'thinContent', 'opportunityKeywords', 'keywords', 'keywordSugestion', 'keywordsBatch'
        , 'domain', 'domainBatch', 'domainPosition', 'domainCompetitors', 'backlinks', 'contentGap', 'keywordIdeas', 'domainIdeas']
    }
  ]

  return {
    planList,
    selectedPlan, setSelectedPlan, selectedBilling, setSelectedBilling
  }
}

export default PlansViewModel