import { useProjectsData } from '../../../hooks/useProjectsData';
import { useState } from 'react';
import { useUserContext } from '../../../context/UserContext';
import { DateRange } from '../../../components/Selectors/types';
import useProjectInfo from '../../../hooks/useProjectInfo';
import { getColumnDefinitions } from '../../../components/ColumnDefinitions/ColumnDefinitions';
import { ProjectInterface } from '../../../interfaces/ProjectInterface';

const projectDetailModel = () => {

  // filter
  const initialMetrics = { impressions: true, clicks: true, ctr: false, position: false };
  const [globalMetrics, setGlobalMetrics] = useState(initialMetrics);
  const [columns, setColumns] = useState(2);
  const [dateRange, setDateRange] = useState<DateRange>({ startDate: '', endDate: '' });

  const [project, setProject] = useState<ProjectInterface>();
  const [selectedProject, setSelectedProject] = useState<ProjectInterface>(null);

  const FILTER_KEYWORD = ['queryList', 'new', 'lost', 'recuperated'];
  type FilterModeType = (typeof FILTER_KEYWORD)[number];
  const [filterMode, setFilterMode] = useState<FilterModeType>(FILTER_KEYWORD[0])
  const { COLUMNS_QUERIES, COLUMNS_PAGES, COLUMNS_COUNTRIES, COLUMNS_DEVICES, COLUMNS_SEARCH_APPEARANCES } = getColumnDefinitions();

  // general
  const [loading, setLoading] = useState(false);
  const { dataProject, fetchProjectInfo: fetchProjectInfoCallback } = useProjectsData();
  const { blur, toggleBlur } = useUserContext();
  const [showCustomDots, setShowCustomDots] = useState<boolean>(false)

  const { projectInfo, loading: projectLoading } = useProjectInfo({
    project,
    dateRange,
    fetchProjectInfoCallback
  });

  const chartData =
    projectInfo?.currentData?.dates?.map((dateRecord) => ({
      date: dateRecord.keys[0],
      clicks: dateRecord.clicks,
      impressions: dateRecord.impressions,
      ctr: dateRecord.ctr,
      position: dateRecord.position,
    })) || [];


  return {
    globalMetrics, setGlobalMetrics,
    columns, setColumns,
    dateRange, setDateRange,
    project, setProject,
    selectedProject, setSelectedProject,

    dataProject,
    blur, toggleBlur,
    showCustomDots, setShowCustomDots,

    projectInfo, projectLoading, loading,
    chartData,
    filterMode, setFilterMode, FILTER_KEYWORD,

    COLUMNS_QUERIES, COLUMNS_PAGES, COLUMNS_COUNTRIES, COLUMNS_DEVICES, COLUMNS_SEARCH_APPEARANCES
  }
}

export default projectDetailModel