import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useRangesDate from '../../hooks/useRangesDate';
import { useProjectsData } from '../../hooks/useProjectsData';
import useProjectsFilters from '../../hooks/useProjectsFilters';
import useExportData from '../../hooks/useExportData';

import * as CONSTANTS from '../../utils/Constants';
import { COLUMNS_PROJECT_THIN_CONTENT } from '../../utils/TableColumnsConstants';
import { valuesSplitNumber, volumeOfSearchesOptions } from '../../utils/Utils';

import { Button, MenuItem, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ButtonExportExcel from '../../components/Buttons/ExportExcel';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import CustomRange from '../../components/CustomRange/CustomRange';
import MenuSelector from '../../components/MenuSelector/MenuSelector';
import { ProjectsThinContentResponse } from '../../types';
import DateRangeSelectorView from '../../components/Selectors/DateRangeSelector/DateRangeSelectorView';
import SelectorView from '../../components/Projects/ProjectSelector/ProjectSelectorView';
import { ProjectInterface } from '../../interfaces/ProjectInterface';

export const ProjectsThinContentPage = () => {
  const [project, setProject] = useState<ProjectInterface>({ property: null });
  const [dataThinContent, setDataThinContent] = useState<ProjectsThinContentResponse>([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rangesDate, handleChangeRanges } = useRangesDate();
  const { loadingThinContent, dataProject, fetchThinContent } = useProjectsData();
  const { fetchExportProjectsThinContent, isLoading: isExportLoading } = useExportData();

  const {
    effectFilter,
    ctrOfSearches,
    clicksOfSearches,
    positionOfSearches,
    impressionsOfSearches,

    // DEFAULT VALUE
    defaultValueCtr,
    defaultValueClicks,
    defaultValuePositions,
    defaultValueImpression,

    // STATES
    hasCtrOfSearches,
    hasClicksOfSearches,
    hasPositionsOfSearches,
    hasImpressionsOfSearches,

    // CALLBACKS
    handleFiltersApply,
    handleCtrOfSearches,
    handleClicksOfSearches,
    handlePositionOfSearches,
    handleImpressionsOfSearches
  } = useProjectsFilters();

  const convertThinContentResponse = (response) => {
    return Object.entries(response.items).map(([url, data]) => ({
      url,
      ...data
    }));
  };

  const getKeywords = useCallback(async () => {
    if (!project) return;
    const data = await fetchThinContent({
      project: project.property,
      ...rangesDate,
      ...handleFiltersApply()
    });
    if (data) {
      const formattedData = convertThinContentResponse(data);
      setDataThinContent(formattedData);
    }
  }, [project, rangesDate, fetchThinContent, handleFiltersApply]);

  const fetchGetKeywords = async () => await getKeywords();

  useEffect(() => {
    if ((project) && effectFilter > 0) fetchGetKeywords();
  }, [project, effectFilter]);

  const handleExport = async () => {
    if (!project) return;
    const data = { project: project.property, ...rangesDate, ...handleFiltersApply() };
    await fetchExportProjectsThinContent(data);
  };

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>

          <SelectorView label={t('project')} project={project || ''} setProject={setProject} itemList={dataProject} />

          <div className="flex gap-2 w-full items-center py-3">
            <MenuSelector
              value={clicksOfSearches}
              isSelected={hasClicksOfSearches}
              defaultValue={defaultValueClicks}
              suggestion={CONSTANTS.clicksOfSearchesOptions}
              onChange={(values) => handleClicksOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={clicksOfSearches.to}
                fromValues={clicksOfSearches.from}
                onClick={(value) => handleClicksOfSearches(value)}
              />
            </MenuSelector>
            <MenuSelector
              value={impressionsOfSearches}
              defaultValue={defaultValueImpression}
              suggestion={volumeOfSearchesOptions}
              isSelected={hasImpressionsOfSearches}
              onChange={(values) => handleImpressionsOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={impressionsOfSearches.to}
                fromValues={impressionsOfSearches.from}
                onClick={(value) => handleImpressionsOfSearches(value)}
              />
            </MenuSelector>
            <MenuSelector
              value={positionOfSearches}
              isSelected={hasPositionsOfSearches}
              defaultValue={defaultValuePositions}
              suggestion={CONSTANTS.potitionOfSearchesOptions}
              onChange={(values) => handlePositionOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={positionOfSearches.to}
                fromValues={positionOfSearches.from}
                onClick={(value) => handlePositionOfSearches(value)}
              />
            </MenuSelector>
            <MenuSelector
              value={ctrOfSearches}
              isSelected={hasCtrOfSearches}
              defaultValue={defaultValueCtr}
              suggestion={CONSTANTS.CRTOfSearchesOptions}
              onChange={(values) => handleCtrOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={ctrOfSearches.to}
                fromValues={ctrOfSearches.from}
                onClick={(value) => handleCtrOfSearches(value)}
              />
            </MenuSelector>
            <div className="flex">
              <DateRangeSelectorView onChange={handleChangeRanges} />
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              color="primary"
              variant="contained"
              onClick={getKeywords}
              disabled={!project.property || loadingThinContent}>
              {t('obtain')}
            </Button>
          </div>
        </PaperItem>
      </Grid>

      <Grid size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
            <Typography className="mb-5">{t('organic_keywords')}</Typography>
            <ButtonExportExcel
              isLoading={isExportLoading}
              onClick={handleExport}
              totalKeywords={dataThinContent.length}
              query={project?.property || ''}
            />
          </Box>
          <TableComponent
            rows={dataThinContent}
            getRowHeight={() => 'auto'}
            loading={loadingThinContent}
            columns={COLUMNS_PROJECT_THIN_CONTENT(t)}
            paginationMode="client"
            sortingMode="client"
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};
