import React from 'react';
import { useAccessToken } from './useAccessToken';
import { getBaseUrl } from '../utils/Utils';
import {
  SERVICE_DOMAINS_EXPORT_KEYWORDS,
  SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS,
  SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS,
  SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS,
  SERVICE_PROJECTS_EXPORT_CANNIBALIZATION,
  SERVICE_PROJECTS_EXPORT_THIN_CONTENT,
  SERVICE_PROJECTS_EXPORT_KEYWORDS,
} from '../services/Endpoint';

const HEADERS = (token: string) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

const createBlob = async (response: Response, fileName: string) => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const contentDisposition = response.headers.get('content-disposition');

  if (contentDisposition) {
    const match = contentDisposition.match(/filename\*?=([^;]+)/);
    if (match) {
      fileName = match[1].trim().replace(/"/g, '');
    }
  }

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

const ERROR_CONSOLE = 'Error exportando los datos:';
const ERROR = 'Error en la solicitud de exportación';

export default function useExportData() {
  const [isLoading, setIsLoading] = React.useState(false);
  const token = useAccessToken();

  const fetchExportDomainKeywords = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_DOMAINS_EXPORT_KEYWORDS}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);

        await createBlob(response, 'Rankerfy_Posiciones.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  const fetchExportDomainOverview = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_DOMAINS_EXPORT_KEYWORDS}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);

        await createBlob(response, 'Rankerfy_Overview.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  const fetchExportKeywordsBulkAnalysis = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);
        await createBlob(response, 'Rankerfy_AnalisisMasivo.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  const fetchExportKeywordsNicheIdeas = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);
        await createBlob(response, 'Rankerfy_NicheIdeas.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  const fetchExportKeywordsIdeasOverview = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);
        await createBlob(response, 'Rankerfy_Ideas.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  const fetchExportProjectsCannibalization = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_PROJECTS_EXPORT_CANNIBALIZATION}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);
        await createBlob(response, 'Rankerfy_Cannibalization.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  const fetchExportProjectsThinContent = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_PROJECTS_EXPORT_THIN_CONTENT}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);
        await createBlob(response, 'Rankerfy_ThinContent.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  const fetchExportProjectsKeywords = React.useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const url = `${getBaseUrl()}${SERVICE_PROJECTS_EXPORT_KEYWORDS}`;
        const response = await fetch(url, {
          ...HEADERS(token),
          body: JSON.stringify(data),
        });

        if (!response.ok) throw new Error(ERROR);
        await createBlob(response, 'Rankerfy_Keywords.xlsx');
      } catch (error) {
        console.error(ERROR_CONSOLE, error);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  return {
    isLoading,
    // DOMAINS
    fetchExportDomainOverview,
    fetchExportDomainKeywords,
    // KEYWORDS
    fetchExportKeywordsNicheIdeas,
    fetchExportKeywordsBulkAnalysis,
    fetchExportKeywordsIdeasOverview,
    // PROJECTS
    fetchExportProjectsCannibalization,
    fetchExportProjectsThinContent,
    fetchExportProjectsKeywords,
  };
}