import { createContext, useContext, useState, ReactNode } from 'react';
import { PLAN_BILLING_MONTHLY, PLAN_ROOKIE } from '../utils/Constants';

interface PlanContextType {
  selectedPlan: string;
  selectedBilling: string | null;
  setSelectedPlan: (plan: string | null) => void;
  setSelectedBilling: (billing: string | null) => void;
}

const PlanContext = createContext<PlanContextType | undefined>(undefined);

export const PlanProvider = ({ children }: { children: ReactNode }) => {
  const [selectedPlan, setSelectedPlan] = useState<string>(PLAN_ROOKIE);
  const [selectedBilling, setSelectedBilling] = useState<string>(PLAN_BILLING_MONTHLY);

  return (
    <PlanContext.Provider value={{ selectedPlan, setSelectedPlan, selectedBilling, setSelectedBilling }}>
      {children}
    </PlanContext.Provider>
  );
};

export const useSelectedPlan = (): PlanContextType => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('useSelectedPlan must be used within a PlanProvider');
  }
  return context;
};