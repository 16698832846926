import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Tooltip, Paper } from '@mui/material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import BlockIcon from '@mui/icons-material/Block';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from './GlobalMetricToggle.module.scss';

interface GlobalMetricToggleProps {
  metrics: { [key: string]: boolean };
  onToggle: (metric: string) => void;
}

const GlobalMetricToggle: React.FC<GlobalMetricToggleProps> = ({ metrics, onToggle }) => {
  const { t } = useTranslation();

  if(!metrics){
    return
  }

  const handleToggle = (metric: string) => {
    const activeMetrics = Object.values(metrics).filter(Boolean).length;
    if (activeMetrics === 1 && metrics[metric]) {
      return;
    }
    onToggle(metric);
  };

  return (
    <Paper elevation={3} className={styles.globalMetricToggle}>
      <Box className={styles.box}>
        <Grid container spacing={0} justifyContent="center" alignItems="center" wrap="nowrap">
          <Grid item>
            <Tooltip title={t('toggleClicks')}>
              <IconButton
                className={`${styles.iconButton} ${styles.clicks} ${
                  metrics.clicks ? styles['active-clicks'] : styles['inactive-clicks']
                }`}
                onClick={() => handleToggle('clicks')}>
                {metrics.clicks ? <AdsClickIcon style={{ fontSize: '1.5rem' }} /> : <BlockIcon style={{ fontSize: '1.5rem' }} />}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t('toggleImpressions')}>
              <IconButton
                className={`${styles.iconButton} ${styles.impressions} ${
                  metrics.impressions ? styles['active-impressions'] : styles['inactive-impressions']
                }`}
                onClick={() => handleToggle('impressions')}>
                {metrics.impressions ? (
                  <RemoveRedEyeIcon style={{ fontSize: '1.5rem' }} />
                ) : (
                  <VisibilityOffIcon style={{ fontSize: '1.5rem' }} />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t('toggleCTR')}>
              <IconButton
                className={`${styles.iconButton} ${styles.ctr} ${
                  metrics.ctr ? styles['active-ctr'] : styles['inactive-ctr']
                }`}
                onClick={() => handleToggle('ctr')}>
                {metrics.ctr ? <PercentIcon style={{ fontSize: '1.5rem' }} /> : <RemoveCircleOutlineIcon style={{ fontSize: '1.5rem' }} />}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t('togglePosition')}>
              <IconButton
                className={`${styles.iconButton} ${styles.position} ${
                  metrics.position ? styles['active-position'] : styles['inactive-position']
                }`}
                onClick={() => handleToggle('position')}>
                {metrics.position ? <TrendingUpIcon style={{ fontSize: '1.5rem' }} /> : <TrendingDownIcon style={{ fontSize: '1.5rem' }} />}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default GlobalMetricToggle;